/**
 * Highlights specified words in a sentence with bold tags.
 *
 * @param sentence The sentence to be processed. Must be a string.
 * @param words An array of words to be highlighted. Must be an array of strings.
 * @returns The processed sentence with highlighted words enclosed in bold tags.
 */
export const highlightWords = (
  sentence: string,
  words: Array<string>
): string => {
  // Flatten the words array by splitting phrases into individual words
  const flattenedWords = words.flatMap((word) => word.split(/\s+/));

  // Escape special characters in the words to match
  const escapedWords = flattenedWords.map((word) =>
    word.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  );

  // Create a regular expression to match whole words from the escapedWords list
  const regex = new RegExp(`\\b(${escapedWords.join('|')})\\b`, 'gi');

  // Replace the matched words with bold tags
  const highlighted = sentence.replace(regex, '<b>$&</b>');

  // Return the processed sentence
  return highlighted;
};

/**
 * Validate the sentence with the given regex expression.
 *
 * @param text string to validate.
 * @param regex regular expression for testing the string.
 * @param minLength number that sets the minimum length the string must be in order to pass validation.
 * @returns "true" if the regular expression test is successful and the string length is greater than or equal to the minimum length. If either of these tests fails, the function will return "false".
 */
export const validateString = (
  text: string,
  regex: RegExp,
  minLength: number
): boolean => {
  if (regex && !regex.test(text)) return false;
  if (minLength && !(text.length >= minLength)) return false;
  return true;
};

/**
 * Replaces all urls found in a given text with <a> elements.
 *
 * @param text text to be processed.
 * @returns The processed text with <a> elements instead of plain urls.
 */
export const replaceUrlsWithLinks = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
};

/**
 * Replaces all \n's found in a given text with <br> elements.
 *
 * @param text text to be processed.
 * @returns The processed text with <br> elements instead of \n's.
 */
export const replaceNewLinesWithBreaks = (text: string): string =>
  text.replaceAll('\n', '<br>');

/**
 * Returns true if string has one of the keywords.
 *
 * @param string The text to be processed. Must be a string.
 * @param keywords List of keywords. Can include phrases separated by spaces.
 * @returns Boolean representing if string has one of the keywords.
 */
export const stringHasKeyword = (
  string: string,
  keywords: string[]
): boolean => {
  const lowerCaseString = string.toLowerCase();

  if (keywords.length === 0) return false;

  // Flatten the keywords array by splitting phrases into individual keywords
  const flattenedKeywords = keywords.flatMap((keyword) => keyword.split(/\s+/));

  // Use a regular expression to match whole words from the flattenedKeywords list
  const regex = new RegExp(
    `\\b(${flattenedKeywords
      .map((keyword) => keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
      .join('|')})\\b`,
    'gi'
  );

  // Check if any of the matched whole words are present in the lowerCaseString
  return regex.test(lowerCaseString);
};
