import { Grid, LinearProgress } from '@mui/material';

const Loading = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <div style={{ width: 400 }}>
      <LinearProgress color="secondary" />
    </div>
  </Grid>
);

export default Loading;
