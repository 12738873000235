import { FC } from 'react';

import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  CheckboxProps as MaterialCheckboxProps
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

interface CheckboxProps extends MaterialCheckboxProps {
  label?: string | JSX.Element;
}

const useStyle = makeStyles()({
  formControlLabel: {
    margin: 0,
    height: '25px'
  }
});

const Checkbox: FC<CheckboxProps> = ({ label, ...props }) => {
  const { classes } = useStyle();

  return (
    <FormControlLabel
      classes={{ root: classes.formControlLabel }}
      control={<MaterialCheckbox {...props} />}
      label={label}
    />
  );
};

export default Checkbox;
