import { useEffect } from 'react';

import {
  List as ListMUI,
  ListItem,
  Stack,
  Typography,
  Chip
} from '@mui/material';

import { format } from 'date-fns';

import Page from '@/components/Page';
import Card from '@/components/card/Card';
import Header from '@/components/header/Header';
import usePrismic from '@/hooks/usePrismic';
import LocalStorageService from '@/services/LocalStorageService';

type ReleaseNoteItem = {
  text: string;
};

type ListProps = {
  title: string;
  items: ReleaseNoteItem[];
};

type ReleaseCardProps = {
  newfeatures: ReleaseNoteItem[];
  enhancements: ReleaseNoteItem[];
  date: string;
  dropped: ReleaseNoteItem[];
  bugfixes: ReleaseNoteItem[];
  miscellaneous: ReleaseNoteItem[];
  version: string;
  notes: string;
  showNewBadge: boolean;
};

const List = ({ title, items = [] }: ListProps) => {
  if (!items.length) return <></>;

  return (
    <>
      <Typography marginTop={1}>{title}</Typography>
      <ListMUI sx={{ listStyleType: 'disc', pl: 4 }}>
        {items.map((item, index) => (
          <ListItem
            key={`${title}-item-${index}`}
            sx={{ display: 'list-item', padding: 0.5 }}
          >
            <Typography variant="body2">{item.text}</Typography>
          </ListItem>
        ))}
      </ListMUI>
    </>
  );
};

const ReleaseCard = ({
  newfeatures,
  enhancements,
  date,
  dropped,
  miscellaneous,
  bugfixes,
  version,
  notes,
  showNewBadge
}: ReleaseCardProps) => {
  const formattedDate = format(new Date(date), 'dd/MM/yyyy');

  return (
    <Card
      shadow
      content={
        <>
          <Stack mb={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h6">Version {version}</Typography>
              {showNewBadge && (
                <Chip label="NEW" size="small" color="primary" />
              )}
            </Stack>
            <Typography variant="caption">
              Released in {formattedDate}
            </Typography>
          </Stack>
          <List title="New Features" items={newfeatures} />
          <List title="Enhancements" items={enhancements} />
          <List title="Bug Fixes" items={bugfixes} />
          <List title="Miscellaneous" items={miscellaneous} />
          <List title="Dropped" items={dropped} />
          <Typography variant="caption" fontStyle="italic">
            {notes}
          </Typography>
        </>
      }
    />
  );
};

const ReleaseNotes = () => {
  const { releases, totalNewReleases } = usePrismic();

  useEffect(() => {
    if (releases === undefined) return;

    const [latestRelease] = releases;
    const latestReleaseVersion = latestRelease.data.version[0].text;
    LocalStorageService.setItem('release-notes', latestReleaseVersion);
  }, [releases]);

  return (
    <Page>
      <>
        <Header mb sx={{ pt: 3 }} title="Release notes" />
        <Stack gap={2}>
          {releases?.map((release, index) => (
            <ReleaseCard
              key={release.id}
              version={release.data.version[0].text}
              date={release.data.date}
              newfeatures={release.data.newfeatures}
              enhancements={release.data.enhancements}
              bugfixes={release.data.bugfixes}
              miscellaneous={release.data.miscellaneous}
              dropped={release.data.dropped}
              notes={release.data?.notes[0]?.text}
              showNewBadge={totalNewReleases > index}
            />
          ))}
        </Stack>
      </>
    </Page>
  );
};

export default ReleaseNotes;
