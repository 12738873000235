import { useQuery } from '@tanstack/react-query';

import getWorkspaces from '@/api/misc';
import { Workspace } from '@/models/workspace';

type WorkspacesProps = {
  onSuccess?: (_data: Workspace[]) => void;
  onError?: (_error: unknown) => void;
};

export const useFetchWorkspaces = ({ onSuccess, onError }: WorkspacesProps) =>
  useQuery({
    queryKey: ['workspaces'],
    queryFn: async () => {
      const result = await getWorkspaces();
      return result.data;
    },
    onSuccess,
    onError
  });
