import { ReactElement } from 'react';

import { showAlert } from '@/store/alertSlice';

import { useAppDispatch } from './redux';

const useAlert = () => {
  const dispatch = useAppDispatch();

  return {
    success: (content: string, action: ReactElement | null = null) =>
      dispatch(
        showAlert({
          content,
          action,
          severity: 'success'
        })
      ),
    error: (
      content = 'There has been an error',
      action: ReactElement | null = null
    ) =>
      dispatch(
        showAlert({
          content,
          action,
          severity: 'error'
        })
      ),
    info: (content: string, action: ReactElement | null = null) =>
      dispatch(
        showAlert({
          content,
          action,
          severity: 'info'
        })
      ),
    warning: (content: string, action: ReactElement | null = null) =>
      dispatch(
        showAlert({
          content,
          action,
          severity: 'warning'
        })
      )
  };
};

export default useAlert;
