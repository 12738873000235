import { ChangeEvent, MouseEvent, useContext, useState } from 'react';

import { DeviceUnknown } from '@mui/icons-material';
import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import {
  AvatarGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

import parse from 'html-react-parser';

import Avatar from '@/components/avatar/Avatar';
import Button from '@/components/button';
import Card from '@/components/card';
import Dialog from '@/components/dialog';
import InstagramIcon from '@/components/icons/InstagramIcon';
import TikTokIcon from '@/components/icons/TikTokIcon';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Posts } from '@/models/posts';
import { getPrettyDateWithTime } from '@/utils/datesAndTime/datesAndTime';
import getRoute from '@/utils/getRoute';

type Platform = {
  type:
    | 'IG: Post'
    | 'IG: Reel'
    | 'IG: Story'
    | 'YT: Short'
    | 'YT: Video'
    | 'TT: Video'
    | 'Unknown';
  icon: JSX.Element;
};

const disclosureMapper = (
  disclosure: Posts.PlatformDisclosureType,
  platform: Posts.Details['platform']
) => {
  switch (disclosure) {
    case 'paidPartnership':
      if (platform !== 'tiktok') return 'Sponsored';
      return 'Paid Partnership';
    case 'promotionalContent':
      return 'Promotional Content';
    case 'paidPromotion':
      return 'Paid Promotion';
    default:
      return '';
  }
};

const platformMapper = (platform: Posts.Details['platform']): Platform => {
  switch (platform) {
    case 'instagram-story':
      return { type: 'IG: Story', icon: <InstagramIcon fontSize="large" /> };
    case 'instagram-post':
      return { type: 'IG: Post', icon: <InstagramIcon fontSize="large" /> };
    case 'instagram-reel':
      return { type: 'IG: Reel', icon: <InstagramIcon fontSize="large" /> };
    case 'youtube-video':
      return { type: 'YT: Video', icon: <YoutubeIcon fontSize="large" /> };
    case 'youtube-short':
      return { type: 'YT: Short', icon: <YoutubeIcon fontSize="large" /> };
    case 'tiktok':
      return { type: 'TT: Video', icon: <TikTokIcon fontSize="large" /> };
    default:
      return { type: 'Unknown', icon: <DeviceUnknown fontSize="large" /> };
  }
};

const Loading = () => (
  <Card
    shadow
    content={
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Skeleton variant="circular" sx={{ width: 50, height: 50 }} />
          <Stack gap={1}>
            <Skeleton variant="rectangular" sx={{ width: 80, height: 16 }} />
            <Skeleton variant="rectangular" sx={{ width: 100, height: 16 }} />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack gap={1}>
            <Skeleton variant="rectangular" sx={{ width: 80, height: 16 }} />
            <Skeleton variant="rectangular" sx={{ width: 100, height: 16 }} />
          </Stack>
          <Skeleton variant="circular" sx={{ width: 44, height: 44 }} />
        </Stack>
      </Stack>
    }
  />
);

const PostInfo = ({ data, theme }: { data: Posts.Details; theme: Theme }) => (
  <Stack
    style={{
      maxWidth: '50%',
      cursor: data.url ? 'pointer' : 'default'
    }}
    direction="row"
    alignItems="center"
    gap={1}
    onClick={() => data.url && window.open(data.url, '_blank')}
  >
    <Stack>
      <Typography
        sx={{
          fontWeight: 'bold',
          textAlign: 'right'
        }}
        noWrap
      >
        {platformMapper(data.platform).type}
      </Typography>
      {data?.platformDisclosure?.length > 0 ? (
        <Stack
          direction="row"
          gap={0.2}
          justifyContent="center"
          alignItems="center"
        >
          <PaidIcon
            color="action"
            sx={{ height: data.platformDisclosure.length > 1 ? 25 : 20 }}
          />
          <Stack>
            {data.platformDisclosure.map(
              (disclosure: Posts.PlatformDisclosureType, index) =>
                data.platform === 'tiktok' ? (
                  <Tooltip
                    key={index}
                    arrow
                    title={parse(
                      'This shows whether the creator has used TikTok’s platform features to disclose advertising: <b>‘promotional content’</b> indicates self-promotion, while <b>‘paid partnership’</b> signifies advertising on behalf of a third party. How this disclosure is shown depends on whether you view the post in a browser or in the TikTok app. It might also depend on your location and other factors that we have not yet identified.'
                    )}
                  >
                    <Typography
                      key={index + disclosure}
                      variant="body2"
                      fontSize={12}
                      sx={{ direction: 'row' }}
                    >
                      {disclosureMapper(disclosure, data.platform)}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    key={index}
                    variant="body2"
                    fontSize={12}
                    sx={{ direction: 'row' }}
                  >
                    {disclosureMapper(disclosure, data.platform)}
                  </Typography>
                )
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row">
          <PaidOutlinedIcon
            sx={{
              height: 20,
              color: theme.palette.text.disabled
            }}
          />
          <Typography variant="body2" noWrap>
            not sponsored
          </Typography>
        </Stack>
      )}
    </Stack>
    {platformMapper(data.platform).icon}
  </Stack>
);

const getCollaboratorText = (
  creator: Posts.Creator,
  collaborators: Array<Posts.Collaborator>
) => {
  if (collaborators.length > 1) {
    return `${creator.name}, ${collaborators[0].name} and ${
      collaborators.length - 1
    } Creators`;
  }
  if (collaborators.length === 1) {
    return `${creator.name} and ${collaborators[0].name}`;
  }
  return creator.name;
};

const CreatorInfo = ({
  data,
  navigate
}: {
  data: Posts.Details;
  navigate: any;
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isCollabsDialogOpen, setCollabsDialogOpen] = useState(false);
  const collaborators = data.collaborators || [];

  const rows = collaborators.map((collaborator, index) => ({
    id: index,
    avatarUrl: collaborator.avatarUrl,
    name: collaborator.name
  }));

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Stack
        style={{
          cursor: data.creator.id ? 'pointer' : 'default'
        }}
        direction="row"
        alignItems="center"
        gap={1}
      >
        <AvatarGroup
          max={3}
          color="primary"
          total={collaborators.length + 1}
          renderSurplus={(surplus) => (
            <Typography>+{surplus.toString()[0]}</Typography>
          )}
          onClick={
            collaborators.length > 1
              ? () => setCollabsDialogOpen(true)
              : undefined
          }
          sx={{
            '& .MuiAvatarGroup-avatar': {
              backgroundColor: theme.palette.primary.main
            }
          }}
        >
          <Avatar
            src={data.creator.avatarUrl}
            name={data.creator.name}
            width="40px"
            onClick={(e) =>
              navigate(getRoute.creators.DETAIL(data.creator.id), e)
            }
          />
          {collaborators.map((collaborator) => (
            <Avatar
              key={collaborator.id}
              src={collaborator.avatarUrl}
              name={collaborator.name}
              width="40px"
            />
          ))}
        </AvatarGroup>
        <Stack style={{ minWidth: 0 }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
            onClick={
              collaborators.length > 1
                ? () => setCollabsDialogOpen(true)
                : undefined
            }
          >
            {getCollaboratorText(data.creator, collaborators)}
          </Typography>
          <Typography variant="body2" noWrap>
            {getPrettyDateWithTime(data.createTime)}
          </Typography>
        </Stack>
      </Stack>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => setCollabsDialogOpen(false)}
        open={isCollabsDialogOpen}
        dialogContent={
          <TableContainer component={Stack}>
            <Table>
              <TableBody>
                {paginatedRows.map((row) => (
                  <TableRow hover key={row.name}>
                    <TableCell width={50} align="left">
                      <Avatar
                        src={row.avatarUrl}
                        name={row.name}
                        width="40px"
                      />
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={rows.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        }
        title="Creators"
        actions={
          <Button onClick={() => setCollabsDialogOpen(false)} text="close" />
        }
      />
    </>
  );
};

const PostCreator = () => {
  const { data, isLoading } = useContext(PostDetailsContext);
  const theme = useTheme();
  const navigate = useWorkspaceNavigate();

  if (isLoading) return <Loading />;

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  return (
    <Card
      shadow
      content={
        <Stack direction="row" justifyContent="space-between">
          {data.creator.id ? (
            <CreatorInfo data={data} navigate={navigate} />
          ) : (
            <Tooltip title="This creator has been deleted" arrow>
              <Stack>
                <CreatorInfo data={data} navigate={navigate} />
              </Stack>
            </Tooltip>
          )}
          {data.url ? (
            <PostInfo data={data} theme={theme} />
          ) : (
            <Tooltip title="Link unavailable" arrow>
              <Stack>
                <PostInfo data={data} theme={theme} />
              </Stack>
            </Tooltip>
          )}
        </Stack>
      }
    />
  );
};

export default PostCreator;
