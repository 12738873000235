import { SvgIcon, SvgIconProps } from '@mui/material';

const YoutubeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M13.95 12L10.8 10.2V13.8L13.95 12Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.75C0 1.67893 1.67893 0 3.75 0H20.25C22.3211 0 24 1.67893 24 3.75V20.25C24 22.3211 22.3211 24 20.25 24H3.75C1.67893 24 0 22.3211 0 20.25V3.75ZM16.65 8.025C17.175 8.175 17.55 8.55 17.7 9.075C18 10.05 18 12 18 12C18 12 18 13.95 17.775 14.925C17.625 15.45 17.25 15.825 16.725 15.975C15.75 16.2 12 16.2 12 16.2C12 16.2 8.175 16.2 7.275 15.975C6.75 15.825 6.375 15.45 6.225 14.925C6 13.95 6 12 6 12C6 12 6 10.05 6.15 9.075C6.3 8.55 6.675 8.175 7.2 8.025C8.175 7.8 11.925 7.8 11.925 7.8C11.925 7.8 15.75 7.8 16.65 8.025Z"
    />
  </SvgIcon>
);

export default YoutubeIcon;
