export const CHARS = {
  tiktok: {
    MUST_APPEAR: 32,
    MIGHT_APPEAR: 25
  },
  'youtube-short': {
    MUST_APPEAR: 50,
    MIGHT_APPEAR: 6
  },
  'instagram-reel': {
    MUST_APPEAR: 37,
    MIGHT_APPEAR: 7
  },
  'instagram-post': {
    MUST_APPEAR: 125,
    MIGHT_APPEAR: 25
  }
};

// export const TIKTOK_CHARS = {
//   MUST_APPEAR: 32,
//   MIGHT_APPEAR: 25
// }; // Tiktok

// export const YOUTUBE_SHORTS_CHARS = {
//   MUST_APPEAR: 32,
//   MIGHT_APPEAR: 25
// };

// OLD
// MUST_APPEAR: 56,
// MIGHT_APPEAR: 14
