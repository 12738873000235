import { Evaluation } from '@/models/evaluations';

export const convertFormToParameters = (
  form: Evaluation.Form
): Evaluation.ParametersToPost => {
  const {
    choosenCreators,
    dates,
    followersRange,
    mightKeywordsAndKeywordsGroup,
    mustKeywordsAndKeywordsGroup,
    noneKeywordsAndKeywordsGroup,
    platforms,
    someKeywordsAndKeywordsGroup,
    platformDisclosure,
    requestedServices
  } = form;

  return {
    someKeywords: someKeywordsAndKeywordsGroup?.keywords?.map(
      (keyword) => keyword.title
    ),
    mustKeywords: mustKeywordsAndKeywordsGroup?.keywords?.map(
      (keyword) => keyword.title
    ),
    noneKeywords: noneKeywordsAndKeywordsGroup?.keywords?.map(
      (keyword) => keyword.title
    ),
    mightKeywords: mightKeywordsAndKeywordsGroup?.keywords?.map(
      (keyword) => keyword.title
    ),
    someKeywordsGroupIds: someKeywordsAndKeywordsGroup?.groups?.map(
      (group) => group.id
    ),
    mustKeywordsGroupIds: mustKeywordsAndKeywordsGroup?.groups?.map(
      (group) => group.id
    ),
    noneKeywordsGroupIds: noneKeywordsAndKeywordsGroup?.groups?.map(
      (group) => group.id
    ),
    mightKeywordsGroupIds: mightKeywordsAndKeywordsGroup?.groups?.map(
      (group) => group.id
    ),
    creators: choosenCreators
      .filter((creator) => creator.type === 'Creators')
      .flatMap((creator) => creator.id),
    creatorGroups: choosenCreators
      .filter((creator) => creator.type === 'Groups')
      .flatMap((creator) => creator.id),
    platforms,
    followersRange,
    platformDisclosure,
    startCreationDate: String(dates[0]),
    endCreationDate: String(dates[1]),
    requestedServices
  };
};

export const convertParametersToForm = (
  parameters: Evaluation.Parameters
): Evaluation.Form => {
  const {
    platformDisclosure,
    startCreationDate,
    endCreationDate,
    platforms,
    followersRange,
    mightKeywords,
    mustKeywords,
    noneKeywords,
    someKeywords,
    mightKeywordGroups,
    mustKeywordGroups,
    noneKeywordGroups,
    someKeywordGroups,
    creators,
    creatorGroups,
    requestedServices
  } = parameters;

  const form = {
    choosenCreators: [...creators, ...creatorGroups],
    mightKeywordsAndKeywordsGroup: {
      groups: mightKeywordGroups.map(
        (group: { id: number; title: string; keywords: string[] }) => ({
          ...group,
          type: 'Keyword group'
        })
      ),
      keywords: mightKeywords.map((keyword) => ({
        title: keyword,
        type: 'Keyword'
      }))
    },
    mustKeywordsAndKeywordsGroup: {
      groups: mustKeywordGroups.map(
        (group: { id: number; title: string; keywords: string[] }) => ({
          ...group,
          type: 'Keyword group'
        })
      ),
      keywords: mustKeywords.map((keyword) => ({
        title: keyword,
        type: 'Keyword'
      }))
    },
    noneKeywordsAndKeywordsGroup: {
      groups: noneKeywordGroups.map(
        (group: { id: number; title: string; keywords: string[] }) => ({
          ...group,
          type: 'Keyword group'
        })
      ),
      keywords: noneKeywords.map((keyword) => ({
        title: keyword,
        type: 'Keyword'
      }))
    },
    someKeywordsAndKeywordsGroup: {
      groups: someKeywordGroups.map(
        (group: { id: number; title: string; keywords: string[] }) => ({
          ...group,
          type: 'Keyword group'
        })
      ),
      keywords: someKeywords.map((keyword) => ({
        title: keyword,
        type: 'Keyword'
      }))
    },
    dates: [new Date(startCreationDate), new Date(endCreationDate)],
    platforms,
    followersRange,
    platformDisclosure,
    requestedServices
  };

  return form;
};
