import { useRef } from 'react';

import { ArrowDropDown, DateRange, Group } from '@mui/icons-material';
import {
  Box,
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Stack
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import AutocompleteCreators from '@/components/autocompleteCreators/AutocompleteCreators';
import RadioGroup from '@/components/radioGroup';
import { useFetchCreatorsAutocomplete } from '@/hooks/queries/creators';
import useMenuAnchor from '@/hooks/useMenuAnchor';

const useStyles = makeStyles()(() => ({
  platformDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16
  },
  textField: {
    display: 'flex',
    flexShrink: 1,
    minWidth: '100px'
  },
  menuLabel: {
    width: '100%',
    padding: '0 16px',
    margin: 0
  },
  menuItem: {
    padding: '6px 0'
  },
  platformFormLabel: {
    margin: 0
  }
}));

const Step2 = ({
  errors,
  onChange,
  showSubtitle = true,
  title = 'Set type of content',
  values,
  isDisabled = false
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const instagramBoxAnchor = useRef();
  const youtubeBoxAnchor = useRef();

  const {
    anchorEl: anchorElInstagram,
    handleOpen: handleOpenInstagramMenu,
    handleClose: handleCloseInstagramMenu,
    isOpen: oepnInstagramMenu
  } = useMenuAnchor(instagramBoxAnchor);

  const {
    anchorEl: anchorElYoutube,
    handleOpen: handleOpenYoutubeMenu,
    handleClose: handleCloseYoutubeMenu,
    isOpen: oepnYoutubeMenu
  } = useMenuAnchor(youtubeBoxAnchor);

  const { platforms, choosenCreators, followersRange, dates, platformDisclosure } =
    values;

  const { data: availableCreators } = useFetchCreatorsAutocomplete({
    params: { groups: true, creators: true }
  });

  const handleRadioChange = ({ target: { value } }) => {
    onChange('platformDisclosure', Number(value));
  };

  const handleChangeFollowers = ({ target: { value, name } }) => {
    onChange('followersRange', { ...followersRange, [name]: value });
  };

  const handlePlatformChecked = ({ target: { checked, name } }) => {
    let platformOptions = {};

    switch (name) {
      case 'instagram':
        platformOptions = {
          reels: checked,
          stories: checked,
          posts: checked
        };
        break;
      case 'youtube':
        platformOptions = {
          shorts: checked,
          videos: checked
        };
        break;
      case 'tiktok':
        platformOptions = checked;
        break;
      default:
        break;
    }

    onChange('platforms', {
      ...platforms,
      [name]: platformOptions
    });
  };

  const handleCheckedOptions = (platform, { target: { checked, name } }) => {
    const newOptionsChecked = { ...platforms[platform], [name]: checked };
    onChange('platforms', { ...platforms, [platform]: newOptionsChecked });
  };

  // Child Components
  const PlatformInstagramMenu = (
    <Menu
      anchorEl={anchorElInstagram}
      open={oepnInstagramMenu}
      onClose={handleCloseInstagramMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuItem
        classes={{
          root: classes.menuItem
        }}
      >
        <FormControlLabel
          classes={{
            root: classes.menuLabel
          }}
          label="Reels"
          control={
            <Checkbox
              checked={platforms.instagram.reels}
              color="primary"
              name="reels"
              onChange={(event) => handleCheckedOptions('instagram', event)}
              disabled={isDisabled}
            />
          }
        />
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.menuItem
        }}
      >
        <FormControlLabel
          classes={{
            root: classes.menuLabel
          }}
          label="Stories"
          control={
            <Checkbox
              checked={platforms.instagram.stories}
              color="primary"
              name="stories"
              onChange={(event) => handleCheckedOptions('instagram', event)}
              disabled={isDisabled}
            />
          }
        />
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.menuItem
        }}
      >
        <FormControlLabel
          classes={{
            root: classes.menuLabel
          }}
          label="Posts"
          control={
            <Checkbox
              checked={platforms.instagram.posts}
              color="primary"
              name="posts"
              onChange={(event) => handleCheckedOptions('instagram', event)}
              disabled={isDisabled}
            />
          }
        />
      </MenuItem>
    </Menu>
  );

  const PlatformYoutubeMenu = (
    <Menu
      anchorEl={anchorElYoutube}
      open={oepnYoutubeMenu}
      onClose={handleCloseYoutubeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuItem
        classes={{
          root: classes.menuItem
        }}
      >
        <FormControlLabel
          classes={{
            root: classes.menuLabel
          }}
          label="Shorts"
          control={
            <Checkbox
              checked={platforms.youtube.shorts}
              color="primary"
              name="shorts"
              onChange={(event) => handleCheckedOptions('youtube', event)}
              disabled={isDisabled}
            />
          }
        />
      </MenuItem>
      <MenuItem
        classes={{
          root: classes.menuItem
        }}
      >
        <FormControlLabel
          classes={{
            root: classes.menuLabel
          }}
          label="Videos"
          control={
            <Checkbox
              checked={platforms.youtube.videos}
              color="primary"
              name="videos"
              onChange={(event) => handleCheckedOptions('youtube', event)}
              disabled={isDisabled}
            />
          }
        />
      </MenuItem>
    </Menu>
  );

  return (
    <Box style={{ padding: '8px 16px' }}>
      <Stack marginBottom={showSubtitle ? '32px' : '16px'}>
        <Typography variant="h6">{title}</Typography>
        {showSubtitle && (
          <Typography variant="body2" mt={1}>
            Specify what kind of content you want to analyse.
          </Typography>
        )}
      </Stack>
      <Box>
        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <Typography color="textSecondary">Sponsorship:</Typography>
          <RadioGroup
            labelPlacement="end"
            onChange={handleRadioChange}
            options={[
              {
                value: -1,
                label: 'All',
                disabled: isDisabled
              },
              {
                value: 1,
                label: 'Sponsored',
                disabled: isDisabled
              },
              {
                value: 0,
                label: 'Not sponsored',
                disabled: isDisabled
              }
            ]}
            value={platformDisclosure}
          />
        </Stack>
        {/* First row */}
        <Stack direction="row" alignItems="center" gap={2} width="100%" mb={2}>
          <Stack>
            <Stack flexGrow={1} direction="row" alignItems="center" gap={1}>
              <Typography color="textSecondary">Platform:</Typography>
              <FormGroup row>
                <div ref={youtubeBoxAnchor} className={classes.platformDiv}>
                  <FormControlLabel
                    classes={{ root: classes.platformFormLabel }}
                    control={
                      <Checkbox
                        checked={Object.values(platforms.youtube).every(
                          (item) => item
                        )}
                        color="primary"
                        indeterminate={
                          Object.values(platforms.youtube).some(
                            (item) => item
                          ) &&
                          !Object.values(platforms.youtube).every(
                            (item) => item
                          )
                        }
                        name="youtube"
                        onChange={handlePlatformChecked}
                        disabled={isDisabled}
                      />
                    }
                    label="Youtube"
                  />
                  <ArrowDropDown
                    style={{
                      marginLeft: 8,
                      cursor: 'pointer',
                      color: theme.palette.text.secondary
                    }}
                    onClick={handleOpenYoutubeMenu}
                  />
                  {PlatformYoutubeMenu}
                </div>
                <div ref={instagramBoxAnchor} className={classes.platformDiv}>
                  <FormControlLabel
                    classes={{ root: classes.platformFormLabel }}
                    control={
                      <Checkbox
                        checked={Object.values(platforms.instagram).every(
                          (item) => item
                        )}
                        color="primary"
                        indeterminate={
                          Object.values(platforms.instagram).some(
                            (item) => item
                          ) &&
                          !Object.values(platforms.instagram).every(
                            (item) => item
                          )
                        }
                        name="instagram"
                        onChange={handlePlatformChecked}
                        disabled={isDisabled}
                      />
                    }
                    label="Instagram"
                  />
                  <ArrowDropDown
                    style={{
                      marginLeft: 8,
                      cursor: 'pointer',
                      color: theme.palette.text.secondary
                    }}
                    onClick={handleOpenInstagramMenu}
                  />
                  {PlatformInstagramMenu}
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={platforms.tiktok}
                      color="primary"
                      name="tiktok"
                      onChange={handlePlatformChecked}
                      disabled={isDisabled}
                    />
                  }
                  label="TikTok"
                />
              </FormGroup>
            </Stack>
            {errors.platforms && (
              <FormHelperText style={{ margin: 0, padding: 0 }} error>
                {errors.platforms}
              </FormHelperText>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
            flexGrow={1}
          >
            <Typography color="textSecondary">Date range:</Typography>
            <DateRange color="action" />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                calendars={1}
                value={dates}
                onChange={(newValue) => {
                  onChange('dates', newValue);
                }}
                onClose={() => {
                  const [startDate, endDate] = dates;
                  if (endDate === null)
                    onChange('dates', [startDate, startDate]);
                }}
                slots={{
                  textField: (props) => (
                    <TextField
                      size="small"
                      style={{ width: 165 }}
                      id="date"
                      variant="outlined"
                      className={classes.textField}
                      label="End"
                      {...props}
                    />
                  )
                }}
                format="dd/MM/yyyy"
                disabled={isDisabled}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
        {/* Second row */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          width="100%"
        >
          <AutocompleteCreators
            disabled={isDisabled}
            style={{ width: '40%' }}
            value={choosenCreators}
            onChange={(event, value) => onChange('choosenCreators', value)}
            creatorGroups={availableCreators?.creatorGroups}
            creators={availableCreators?.creators}
            label="Creators"
          />
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography color="textSecondary">Followers range:</Typography>
              <Group color="action" />
              <Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                  <FormControl
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="minValue">Min</InputLabel>
                    <OutlinedInput
                      id="minValue"
                      inputProps={{ min: 0, max: followersRange.max }}
                      label="Min"
                      name="min"
                      onChange={handleChangeFollowers}
                      style={{ width: 165 }}
                      type="number"
                      value={followersRange.min}
                      disabled={isDisabled}
                    />
                  </FormControl>
                  <Typography> – </Typography>
                  <FormControl
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="maxValue">Max</InputLabel>
                    <OutlinedInput
                      id="maxValue"
                      inputProps={{ min: followersRange.min }}
                      label="Max"
                      name="max"
                      onChange={handleChangeFollowers}
                      style={{ width: 165 }}
                      type="number"
                      value={followersRange.max}
                      disabled={isDisabled}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            {errors.followersRange && (
              <FormHelperText style={{ margin: 0, padding: 0 }} error>
                {errors.followersRange}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

Step2.propTypes = {
  errors: PropTypes.shape({
    followersRange: PropTypes.string,
    platforms: PropTypes.string
  }),
  onChange: PropTypes.func,
  showSubtitle: PropTypes.bool,
  title: PropTypes.string,
  values: PropTypes.shape({
    platforms: PropTypes.shape({
      youtube: PropTypes.shape({
        shorts: PropTypes.bool,
        videos: PropTypes.bool
      }),
      tiktok: PropTypes.bool,
      instagram: PropTypes.shape({
        reels: PropTypes.bool,
        posts: PropTypes.bool,
        stories: PropTypes.bool
      })
    }),
    choosenCreators: PropTypes.array,
    followersRange: PropTypes.shape({
      min: PropTypes.any,
      max: PropTypes.any
    }),
    dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    platformDisclosure: PropTypes.oneOf([-1, 0, 1])
  }),
  isDisabled: PropTypes.bool
};

export default Step2;
