import { useMutation, useQuery } from '@tanstack/react-query';

import {
  addCreatorsToGroup,
  createCreatorGroup,
  deleteCreatorGroup,
  deleteCreatorsFromGroup,
  getCreatorGroup,
  getCreatorGroups
} from '@/api/creatorGroups';
import { CreatorGroups } from '@/models/creator';
import { UnknownObject } from '@/models/misc';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

type CreatorGroupsProps = {
  params: UnknownObject;
  onSuccess?: (_data: CreatorGroups.ListItem) => void;
  onError?: onErrorType;
  enabled?: boolean;
};

export const useFetchCreatorGroups = ({
  params,
  onSuccess,
  onError,
  enabled
}: CreatorGroupsProps) =>
  useQuery({
    queryKey: ['creatorGroups', params],
    queryFn: async () => {
      const result = await getCreatorGroups(params);
      return result.data;
    },
    enabled,
    onSuccess,
    onError
  });

type CreatorGroupProps = {
  id: number;
  params: UnknownObject;
  onSuccess?: (_data: CreatorGroups.Details) => void;
  onError?: onErrorType;
};

export const useFetchCreatorGroup = ({
  id,
  params,
  onSuccess,
  onError
}: CreatorGroupProps) =>
  useQuery({
    queryKey: ['creatorGroup', id, params],
    queryFn: async () => {
      const result = await getCreatorGroup(id, params);
      return result.data;
    },
    onSuccess,
    onError
  });

type CreateCreatorGroupProps = {
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useCreateCreatorGroup = ({
  onSuccess,
  onError
}: CreateCreatorGroupProps) =>
  useMutation({
    mutationFn: createCreatorGroup,
    onSuccess,
    onError
  });

type AddCreatorsToGroupProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useAddCreatorsToGroup = ({
  id,
  onSuccess,
  onError
}: AddCreatorsToGroupProps) =>
  useMutation({
    mutationFn: (payload: CreatorGroups.AddCreators) =>
      addCreatorsToGroup(id, payload),
    onSuccess,
    onError
  });

type DeleteCreatorGroupProps = {
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useDeleteCreatorGroup = ({
  onSuccess,
  onError
}: DeleteCreatorGroupProps) =>
  useMutation({
    mutationFn: deleteCreatorGroup,
    onSuccess,
    onError
  });

type DeleteCreatorsFromGroupProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useDeleteCreatorsFromGroup = ({
  id,
  onSuccess,
  onError
}: DeleteCreatorsFromGroupProps) =>
  useMutation({
    mutationFn: (payload: CreatorGroups.DeleteCreators) =>
      deleteCreatorsFromGroup(id, payload),
    onSuccess,
    onError
  });
