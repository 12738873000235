import * as prismic from '@prismicio/client';

export const repositoryName = 'influencer-monitor-tool';

export const client = prismic.createClient(repositoryName, {
  accessToken: process.env.REACT_APP_PRISMIC_TOKEN,
  routes: [
    // {
    //   type: 'release_notes',
    //   path: '/'
    // }
  ]
});
