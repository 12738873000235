import { ActionCreator, createSlice } from '@reduxjs/toolkit';

import { getUserSelf, updateUserSelf } from '@/api/users';
import fire from '@/fire';
import { User } from '@/models/user';
import LocalStorageService from '@/services/LocalStorageService';

type State = {
  user: User;
};

const trimUser = (user: User) => ({
  id: user.id,
  name: user.name,
  email: user.email,
  role: user.role,
  workspaceId: user.workspaceId,
  workspaceName: user.workspaceName
});

const initialState: State = {
  user: JSON.parse(LocalStorageService.getItem('user'))
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    }
  }
});

export const { setUser } = userSlice.actions;

export const getUser = (): ActionCreator<any> => async (dispatch) => {
  const { data } = await getUserSelf();
  if (!data) throw new Error('User not found');

  const user = trimUser(data);
  LocalStorageService.setItem('user', JSON.stringify(user));
  dispatch(setUser(user));
  return data;
};

export const changeWorkspace =
  (workspaceId: number): ActionCreator<any> =>
  async () => {
    await updateUserSelf({ workspaceId });

    const user = JSON.parse(LocalStorageService.getItem('user') || '');
    const updatedUser = { ...user, workspaceId };
    LocalStorageService.setItem('user', JSON.stringify(updatedUser));
    window.location.href = '/';
  };

export const login =
  ({
    email,
    password
  }: {
    email: string;
    password: string;
  }): ActionCreator<any> =>
  async (dispatch) => {
    const fireBase: any = await fire
      .auth()
      .signInWithEmailAndPassword(email, password);
    LocalStorageService.setItem('token', fireBase.user?.Aa);
    const data = await dispatch(getUser());
    return data;
  };

export const logout = (): ActionCreator<any> => async (dispatch) => {
  fire.auth().signOut();
  dispatch(setUser(null));
  LocalStorageService.removeItem('token');
  LocalStorageService.removeItem('user');
};

export default userSlice.reducer;
