import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  action?: React.ReactNode;
  content: string;
  open?: boolean;
  severity?: 'error' | 'warning' | 'info' | 'success';
};

const initialState: State = {
  action: null,
  content: '',
  open: false,
  severity: 'success'
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setState: (_, { payload }: PayloadAction<State>) => payload,
    closeAlert: (state) => ({
      ...state,
      open: false
    })
  }
});

export default alertSlice.reducer;

export const { setState, closeAlert } = alertSlice.actions;

export const handleCloseAlert =
  (_?: any, reason?: string) => async (dispatch: any) => {
    if (reason === 'clickaway') return;
    dispatch(closeAlert());
  };

export const showAlert = (data: State) => async (dispatch: any) =>
  dispatch(setState({ ...data, open: true }));
