import { useState, Fragment, useMemo } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Divider, Typography } from '@mui/material';

import DotIcon from '@/components/icons/compliance/DotIcon';

interface Platform {
  label: string;
  value: number;
  color: string;
}

interface Type {
  label: string;
  value: number;
  color: string;
  key: string; // This will link the type to its platform
}

interface ExpandableLegendProps {
  platforms: Platform[];
  types: Type[];
}

const ExpandableLegend = ({ platforms, types }: ExpandableLegendProps) => {
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const handleExpandClick = (index: number) => {
    setExpandedIndices(
      (prevExpandedIndices) =>
        prevExpandedIndices.includes(index)
          ? prevExpandedIndices.filter((i) => i !== index) // Remove index if already expanded
          : [...prevExpandedIndices, index] // Add index to expanded list if not already present
    );
  };

  const groupedTypes = useMemo(
    () =>
      platforms
        .filter((platform) => platform.value > 0) // Filter out platforms with a value of 0
        .map((platform) => ({
          ...platform,
          types: types.filter(
            (type) => type.key === platform.label && type.value > 0
          ) // Group types by their platform and value
        })),
    [platforms, types]
  );

  return (
    <Stack width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={500}>Platform</Typography>
        <Typography fontWeight={500}>Posts</Typography>
      </Stack>
      {groupedTypes.map((platform, index) => (
        <Fragment key={index}>
          <Stack
            sx={{
              cursor: 'pointer',
              transition: 'transform 0.2s ease', // Smooth transition
              '&:hover': {
                transform: 'scale(1.02)' // Slightly scale up on hover
              }
            }}
            pt="9.5px"
            pb="9.5px"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => handleExpandClick(index)}
          >
            <Stack direction="row" alignItems="center" gap="5px">
              {expandedIndices.includes(index) ? (
                <ExpandLessIcon
                  sx={{ fontSize: 16, transform: 'scale(1, 0.8)' }}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ fontSize: 16, transform: 'scale(1, 0.8)' }}
                />
              )}
              <DotIcon fontSize="inherit" sx={{ color: platform.color }} />
              <Typography>{platform.label}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap="10px">
              <Typography color="var(--Light-Surfaces-Text-Surface-Subtle, #757575)">
                {platform.value}
              </Typography>
            </Stack>
          </Stack>

          {/* Expanded content - display the types if the platform is expanded */}
          {expandedIndices.includes(index) && platform.types.length > 0 && (
            <Stack pl="10px">
              {platform.types.map((type, typeIndex) => (
                <Fragment key={typeIndex}>
                  <Stack
                    sx={{
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease', // Smooth transition
                      '&:hover': {
                        transform: 'scale(1.01)' // Slightly scale up on hover
                      }
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    pb="9.5px"
                  >
                    <Stack direction="row" alignItems="center" gap="5px">
                      <DotIcon fontSize="inherit" sx={{ color: type.color }} />
                      <Typography>{type.label}</Typography>
                    </Stack>
                    <Typography color="var(--Light-Surfaces-Text-Surface-Subtle, #757575)">
                      {type.value}
                    </Typography>
                  </Stack>
                </Fragment>
              ))}
            </Stack>
          )}

          {index < groupedTypes.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Stack>
  );
};

export default ExpandableLegend;
