export const LOCAL_STORAGE_KEY = 'easa';

// TODO: move to utils folder
const LocalStorageService = {
  setItem<T>(key: string, data: T) {
    localStorage.setItem(`${LOCAL_STORAGE_KEY}-${key}`, JSON.stringify(data));
  },
  getItem(key: string) {
    const item = localStorage.getItem(`${LOCAL_STORAGE_KEY}-${key}`);
    return item ? JSON.parse(item) : null;
  },
  removeItem(key: string) {
    localStorage.removeItem(`${LOCAL_STORAGE_KEY}-${key}`);
  }
};

export default LocalStorageService;
