import { SvgIcon, SvgIconProps } from '@mui/material';

const DotIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect x="2" y="2" width="4" height="4" rx="3.2" />
  </SvgIcon>
);

export default DotIcon;
