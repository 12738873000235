import { SvgIcon, SvgIconProps } from '@mui/material';

const ExpandMoreIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M17 10L12 15L7 10L17 10Z" fill="black" />
    </svg>
  </SvgIcon>
);

export default ExpandMoreIcon;
