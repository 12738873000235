/**
 * Returns an object with the unique keywords existing on a given string and the errors associated
 * @param textInput string with keywords separated by commas
 * @param currentKeywords current array of existing keywords
 * @param minLength min number of characters that a keyword must have
 * @returns an object with the array of unique keywords and a string error message
 */
const getUniqueKeywordsAndError = (
  textInput: string,
  currentKeywords: string[],
  minLength = 2
) => {
  const duplicatedKeywords: string[] = [];
  // Remove duplicates, empty keywords
  const newKeywords = new Set(
    textInput
      .trim()
      .split(',')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword !== '' && keyword.length >= minLength)
  );

  // Check if each keyword already exists and remove it from uniqueKeywords if necessary
  newKeywords.forEach((keyword) => {
    if (currentKeywords.includes(keyword)) {
      duplicatedKeywords.push(`"${keyword}"`);
      newKeywords.delete(keyword);
    }
  });

  return {
    keywordsArray: [...currentKeywords, ...newKeywords],
    keywordsError:
      duplicatedKeywords.length > 0
        ? `${duplicatedKeywords.join(', ')} already exists`
        : null
  };
};

export default getUniqueKeywordsAndError;
