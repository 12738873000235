import { useMemo } from 'react';

import { useAllPrismicDocumentsByType } from '@prismicio/react';

import LocalStorageService from '@/services/LocalStorageService';

const usePrismic = () => {
  const [releases] = useAllPrismicDocumentsByType('release_notes', {
    orderings: {
      field: 'document.first_publication_date',
      direction: 'desc'
    }
  });

  const sortedReleases = useMemo(
    () =>
      releases?.sort((a, b) => {
        const dateA = new Date(a.data.date);
        const dateB = new Date(b.data.date);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      }),
    [releases]
  );

  const lastSeenVersion = LocalStorageService.getItem('release-notes');
  const totalNewReleases = useMemo(() => {
    const index = sortedReleases?.findIndex(
      (release) => release.data.version[0].text === lastSeenVersion
    );

    if (lastSeenVersion === null) return 1;
    return index === undefined || index < 0 ? 0 : index;
  }, [lastSeenVersion, sortedReleases]);

  return {
    releases: sortedReleases,
    totalNewReleases
  };
};

export default usePrismic;
