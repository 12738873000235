import { Fragment } from 'react';

import { Box, LinearProgress, Typography, Divider, Stack } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

interface ResultCardItem {
  icon?: React.ReactNode;
  label: string;
  loading?: boolean;
  title: string;
}

interface ResultCardProps {
  dataTestId?: string;
  disabled?: boolean;
  row?: boolean;
  items: ResultCardItem[];
}

const useStyles = makeStyles()((theme) => ({
  resultBoxColumn: {
    minHeight: '76px',
    borderRadius: '4px',
    background: theme.palette.action.disabledBackground,
    padding: 16
  }
}));

const ResultCard = ({
  dataTestId = 'result-card',
  disabled = false,
  row = false,
  items
}: ResultCardProps) => {
  const { classes } = useStyles();

  return (
    <Box data-testid={dataTestId}>
      <Stack
        className={classes.resultBoxColumn}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
        gap={1}
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            <Stack direction="row" gap={1} alignItems="center">
              <Stack>{item.icon}</Stack>
              <Stack justifyContent="center" flexWrap="nowrap">
                {!row && (
                  <Typography color="textSecondary" variant="caption">
                    {item.title}
                  </Typography>
                )}
                {item.loading ? (
                  <LinearProgress
                    data-testid="result-card-loading-bar"
                    color="primary"
                    style={{ minWidth: '100px' }}
                  />
                ) : (
                  <Typography color={disabled ? 'textSecondary' : ''}>
                    {item.label}
                  </Typography>
                )}
              </Stack>
            </Stack>
            {index !== items.length - 1 && (
              <Divider orientation="vertical" flexItem />
            )}
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default ResultCard;
