import { Divider, Stack } from '@mui/material';

import Card from '@/components/card';

interface ChartCardProps {
  title: string;
  actions?: JSX.Element;
  chart?: JSX.Element;
  legend?: JSX.Element;
  showDivider?: boolean;
  sx?: any;
}

const ChartCard = ({
  actions,
  title,
  chart,
  legend,
  showDivider = true,
  sx
}: ChartCardProps) => (
  <Card
    sx={{ ...sx, boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15)' }}
    title={title}
    actions={actions}
    content={
      <Stack alignItems="center">
        {chart}
        {legend && showDivider && (
          <Divider
            variant="middle"
            sx={{
              width: '70%',
              mt: '50px',
              mb: '20px',
              borderColor: '#F0F2F5'
            }}
          />
        )}
        {legend}
      </Stack>
    }
  />
);

export default ChartCard;
