import { format as formatDateFNS } from 'date-fns';

// Store for language settings
const languageStore = { language: 'en-gb' };

// Get the user's current timezone
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

/**
 * Converts milliseconds to seconds.
 * @param timestamp - The time in milliseconds.
 * @returns The time in seconds.
 * @example
 * msToSeconds(10000); // returns 10
 */
export const msToSeconds = (timestamp: number) => Math.floor(timestamp / 1000);

/**
 * Converts a date string to a time string in the format "HH:MM".
 * @param date - The date string to convert.
 * @returns The formatted time string.
 * @example
 * getTime('2024-01-18T15:30:00'); // returns "15:30"
 */
export const getTime = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  });
};

/**
 * Formats a date string into a more readable format with month name.
 * @param date - The date string to format.
 * @returns The formatted date string.
 * @example
 * getPrettyDate('2024-01-18'); // returns "Jan 18, 2024"
 */
export const getPrettyDate = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString(languageStore.language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone
  });
};

/**
 * Converts a date string to a simple date format "YYYY-MM-DD".
 * @param date - The date string to format.
 * @returns The formatted date string.
 * @example
 * getSimpleDate('2024-01-18'); // returns "2024-01-18"
 */
export const getSimpleDate = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString(languageStore.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone
  });
};

/**
 * Formats a date string to include time.
 * @param date - The date string to format.
 * @returns The formatted date and time string.
 * @example
 * getDateWithTime('2024-01-18T15:30:00'); // returns "January 18, 15:30"
 */
export const getDateWithTime = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString(languageStore.language, {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone
  });
};

/**
 * Formats a date string to the format "DD MMM YYYY, HH:MM".
 * @param date - The date string to format.
 * @returns The formatted date string.
 * @example
 * getPrettyDateWithTime('2023-12-19T20:58:00'); // returns "19 Dec 2023, 20:58"
 */
export const getPrettyDateWithTime = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString(languageStore.language, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  });
};

/**
 * Gets the day of the week from a date string.
 * @param date - The date string.
 * @returns The name of the day.
 * @example
 * getDay('2024-01-18'); // returns "Friday"
 */
export const getDay = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString(languageStore.language, {
    weekday: 'long',
    timeZone
  });
};

/**
 * Adds a specified number of days to a date.
 * @param date - The original date.
 * @param days - The number of days to add.
 * @returns The new date.
 * @example
 * addDays(new Date('2024-01-18'), 5); // returns new Date('2024-01-23')
 */
export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * Converts milliseconds to a timestamp in the format "HH:MM:SS" or "MM:SS".
 * @param milliseconds - The time in milliseconds.
 * @returns The formatted timestamp.
 * @example
 * msToPrettyTimestamp(3661000); // returns "1:01:01"
 */
export const msToPrettyTimestamp = (milliseconds: number) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');

  if (hours > 0) return `${hours}:${paddedMinutes}:${paddedSeconds}`;

  return `${paddedMinutes}:${paddedSeconds}`;
};

/**
 * Checks if the given object is a valid date.
 * @param date - The object to check.
 * @returns `true` if the object is a valid date, otherwise `false`.
 * @example
 * isValidDate(new Date('2024-01-18')); // returns true
 * isValidDate(new Date('invalid')); // returns false
 */
export const isValidDate = (date: Date) =>
  date instanceof Date && !Number.isNaN(Number(date));

/**
 * Formats a date according to the specified format.
 * @param date - The date to format.
 * @param format - The format string.
 * @returns The formatted date string or undefined if the date is invalid.
 * @example
 * formatDate(new Date('2024-01-18'), 'yyyy-MM-dd'); // returns "2024-01-18"
 */
export const formatDate = (date: Date | null, format: string) => {
  if (!date || !isValidDate(date)) return undefined;
  return formatDateFNS(date, format);
};

/**
 * Pads a number with leading zeros to a specified size.
 *
 * @param number - The number to pad.
 * @param size - The desired size of the padded number (default is 2).
 * @returns The padded number as a string.
 */
export const padNumber = (number: number, size = 2) => {
  let s = String(number);

  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
};

/**
 * Converts seconds to a timestamp in the format "MM:SS".
 * @param second - The time in seconds.
 * @returns The formatted timestamp.
 * @example
 * getMinuteTimestamp(65); // returns "01:05"
 */
export const getMinuteTimestamp = (second: number) => {
  const currentMinute = Math.floor(second / 60);
  const currentSecond = second - currentMinute * 60;
  return `${padNumber(currentMinute)}:${padNumber(currentSecond)}`;
};
