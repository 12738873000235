export default {
  text: {
    primary: '#000000',
    secondary: '#4B4B4B',
    disabled: '#A6A6A6'
  },
  background: {
    paper: '#FFFFFF',
    default: '#F3F3F3'
  }
};
