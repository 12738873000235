import { useEffect, useState } from 'react';

import {
  Card as MuiCard,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  SxProps,
  CardMediaProps,
  CardHeaderProps,
  Collapse,
  Stack,
  IconButton,
  IconButtonProps,
  Badge
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import shadows from '@/themes/theme/shadows';

import ExpandMoreIcon from '../icons/ExpandMoreIcon';

type Props = {
  actions?: JSX.Element;
  className?: string;
  content?: string | JSX.Element;
  dataTestId?: string;
  elevation?: number;
  hasBorder?: boolean;
  header?: CardHeaderProps;
  image?: string;
  media?: CardMediaProps;
  shadow?: boolean;
  sidePadding?: boolean;
  style?: object;
  sx?: SxProps;
  title?: string | JSX.Element;
  titleEllipsis?: boolean;
  isCollapsible?: boolean;
  isExpanded?: boolean;
  hasContent?: boolean; // has content inside the card
};

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand === 'false' ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  sidePadding: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main
  },
  header: {
    padding: '0px'
  },
  headerContent: {
    width: '100%'
  },
  headerTitleEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  content: {
    padding: '0px',
    width: '100%',
    height: '100%'
  },
  actions: {
    padding: '0px',
    marginTop: '16px',
    justifyContent: 'flex-end'
  },
  shadow: {
    boxShadow: shadows.cardDefault
  }
}));

const Card = ({
  actions,
  className,
  content,
  dataTestId,
  elevation = 0,
  hasBorder,
  header,
  image,
  media,
  shadow,
  sidePadding = true,
  style,
  sx,
  title,
  isCollapsible = false,
  isExpanded = true,
  titleEllipsis = false,
  hasContent = false
}: Props) => {
  const { classes, cx } = useStyles();

  const [expanded, setExpanded] = useState(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (isExpanded !== expanded) setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <MuiCard
      data-testid={dataTestId}
      elevation={elevation}
      className={cx(
        classes.card,
        hasBorder && classes.border,
        sidePadding && classes.sidePadding,
        shadow && classes.shadow,
        className
      )}
      sx={sx}
      style={style}
    >
      {title && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="16px"
        >
          <CardHeader
            className={classes.header}
            classes={{
              content: classes.headerContent,
              title: cx(titleEllipsis && classes.headerTitleEllipsis)
            }}
            title={
              hasContent && !expanded ? (
                <Badge color="secondary" variant="dot">
                  {title}
                </Badge>
              ) : (
                title
              )
            }
            titleTypographyProps={{
              variant: 'h6'
            }}
          />
          {isCollapsible && (
            <ExpandMore
              expand={String(expanded)}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
        </Stack>
      )}
      {header && <CardHeader {...header} />}
      {isCollapsible ? (
        <Collapse in={expanded} unmountOnExit>
          {media && <CardMedia {...media} />}
          {image && <CardMedia component="img" image={image} />}
          {content && (
            <CardContent className={classes.content}>{content}</CardContent>
          )}
          {actions && (
            <CardActions className={classes.actions}>{actions}</CardActions>
          )}
        </Collapse>
      ) : (
        <>
          {media && <CardMedia {...media} />}
          {image && <CardMedia component="img" image={image} />}
          {content && (
            <CardContent className={classes.content}>{content}</CardContent>
          )}
          {actions && (
            <CardActions className={classes.actions}>{actions}</CardActions>
          )}
        </>
      )}
    </MuiCard>
  );
};

export default Card;
