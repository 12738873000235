import { AxiosPromise } from 'axios';

import { Creator, CreatorGroups, CreatorsAutocomplete } from '@/models/creator';
import { UnknownObject } from '@/models/misc';

import { decomposeParams, api } from '.';

export const getCreators = (
  params: Creator.ListParams
): AxiosPromise<Creator.List> =>
  api.get(`/v2/creators/${decomposeParams(params)}`);

export const getCreator = (id: number): AxiosPromise<Creator.Details> =>
  api.get(`/v2/creators/${id}`);

export const getCreatorGroups = (id: number): AxiosPromise<Creator.Groups> =>
  api.get(`/v2/creators/${id}/groups`);

export const getCreatorsAutocomplete = (
  params?: CreatorsAutocomplete.Params
): AxiosPromise<{
  creators: CreatorsAutocomplete.Creator[];
  creatorGroups: CreatorGroups.Group[];
}> => api.get(`/v2/creators/autocomplete${decomposeParams(params)}`);

export const getCreatorAvatars = (
  id: number
): AxiosPromise<Creator.AvatarOptions> => api.get(`/v2/creators/${id}/avatars`);

export const getCreatorsPosts = (
  id: number,
  params: Creator.PostsParams
): AxiosPromise<Creator.PostList> =>
  api.get(`/v2/creators/${id}/posts${decomposeParams(params)}`);

export const createCreator = (
  payload: Creator.Create
): AxiosPromise<Creator.Details> => api.post(`/v2/creators/`, payload);

export const mergeCreators = (
  id: number,
  payload: Creator.Merge
): AxiosPromise<UnknownObject> => api.post(`/v2/creators/merge/${id}`, payload);

export const updateCreator = (
  id: number,
  payload: Creator.Update
): AxiosPromise<Creator.Details> => api.put(`/v2/creators/${id}`, payload);

export const updateCreatorGroups = (
  id: number,
  payload: Creator.UpdateGroupsPayload
): AxiosPromise<Creator.UpdateGroupsResponse> =>
  api.put(`/v2/creators/${id}/groups`, payload);

export const deleteCreator = (payload: Creator.Delete) =>
  api.delete(`/v2/creators`, { data: payload });
