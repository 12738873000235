import { NavigateOptions, To, useNavigate, useParams } from 'react-router-dom';

const useWorkspaceNavigate = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const navigateWithWorkspace = (
    path: To,
    event?: React.MouseEvent,
    options?: NavigateOptions
  ) => {
    if (!workspaceId) {
      throw new Error('Workspace ID is missing');
    }

    let fullPath: To;

    if (typeof path === 'string') {
      fullPath = `/workspace/${workspaceId}${
        path.startsWith('/') ? '' : '/'
      }${path}`;
    } else {
      fullPath = {
        ...path,
        pathname: `/workspace/${workspaceId}${
          path.pathname?.startsWith('/') ? '' : '/'
        }${path.pathname || ''}`
      };
    }

    if (event?.ctrlKey || event?.metaKey) {
      window.open(fullPath.toString(), '_blank');
    } else {
      navigate(fullPath, options);
    }
  };

  return navigateWithWorkspace;
};

export default useWorkspaceNavigate;
