import React, { ReactNode } from 'react';

import { useDrawingArea } from '@mui/x-charts';

interface PieCenterLabelProps {
  children: ReactNode | ReactNode[]; // Allow single child or an array of children
  spacing?: number;
}

const PieCenterLabel = ({ children, spacing = 20 }: PieCenterLabelProps) => {
  const { width, height, left, top } = useDrawingArea();

  // Normalize children to always be an array, even if it's a single child
  const normalizedChildren = React.Children.toArray(children);
  const totalChildren = normalizedChildren.length;
  const centerY = top + height / 2;
  const centerX = left + width / 2;

  return (
    <g textAnchor="middle" dominantBaseline="central">
      {normalizedChildren.map((child, index) => (
        <g
          key={index}
          transform={`translate(${centerX}, ${
            centerY + (index - (totalChildren - 1) / 2) * spacing
          })`}
        >
          {child}
        </g>
      ))}
    </g>
  );
};

export default PieCenterLabel;
