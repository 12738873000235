import { Scraper } from '@/models/scraper';

export const statusChecker = (
  scraperStatus: Scraper.Status
): {
  text: string;
  severity: 'error' | 'warning' | 'success' | undefined;
} => {
  if (!scraperStatus) {
    return {
      text: '',
      severity: undefined
    };
  }

  let delayedPlatforms = 0;
  let disabledPlatforms = 0;
  const platforms = Object.keys(scraperStatus);
  const totalPlatforms = platforms.length;

  platforms.forEach((platform: Scraper.Platform) => {
    const { status } = scraperStatus[platform];
    if (status === 'disabled') disabledPlatforms += 1;
    else if (status === 'delayed') delayedPlatforms += 1;
  });

  const totalProblems = delayedPlatforms + disabledPlatforms;
  const totalProblemsMessage =
    totalProblems === 1
      ? `${totalProblems} Platform Is Having Problems`
      : `${totalProblems} Platforms Are Having Problems`;

  if (totalProblems === totalPlatforms) {
    return {
      text:
        disabledPlatforms === totalPlatforms
          ? 'All Platforms Are Inactive'
          : 'All Platforms Are Having Problems',
      severity: 'error'
    };
  }

  if (totalProblems > 0) {
    return {
      text: totalProblemsMessage,
      severity: disabledPlatforms > 0 ? 'error' : 'warning'
    };
  }

  return {
    text: 'All Platforms Operational',
    severity: undefined
  };
};
