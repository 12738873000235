import { DeleteOutlined, Search } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Stack
} from '@mui/material';

import PropTypes from 'prop-types';

import Card from '@/components/card';

const Toolbar = ({
  openDeleteDialog,
  onChangeSearch,
  keywordsList,
  keywordsLength,
  selectedKeywords,
  searchValue
}) => (
  <Card
    sidePadding={false}
    content={
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          {keywordsList?.length === keywordsLength
            ? `Total: ${keywordsList?.length}`
            : `Total: ${keywordsList?.length} of ${keywordsLength}`}
        </Typography>
        <Stack direction="row" alignItems="center">
          <IconButton
            disabled={selectedKeywords?.length <= 0}
            onClick={openDeleteDialog}
            size="large"
            style={{
              margin: '0px 5px'
            }}
          >
            <DeleteOutlined
              color={selectedKeywords?.length <= 0 ? 'disabled' : 'action'}
            />
          </IconButton>
          <TextField
            id="input-with-icon-textfield"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              )
            }}
            label="Search"
            onChange={(e) => onChangeSearch(e)}
            value={searchValue}
            size="small"
            variant="outlined"
          />
        </Stack>
      </Stack>
    }
  />
);

Toolbar.propTypes = {
  openDeleteDialog: PropTypes.func,
  onChangeSearch: PropTypes.func,
  keywordsList: PropTypes.array,
  keywordsLength: PropTypes.number,
  selectedKeywords: PropTypes.array,
  searchValue: PropTypes.string
};

export default Toolbar;
