import { SvgIcon, SvgIconProps } from '@mui/material';

const TikTokIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 0C1.67894 0 0 1.67893 0 3.75V20.25C0 22.3211 1.67894 24 3.75 24H20.25C22.3211 24 24 22.3211 24 20.25V3.75C24 1.67893 22.3211 0 20.25 0H3.75ZM14.3387 6C14.3387 6.17832 14.3553 6.35393 14.386 6.52388C14.5336 7.30955 14.9985 7.98377 15.6444 8.40444C16.0954 8.69979 16.6299 8.86975 17.2061 8.86975L17.206 9.32945V10.9315C16.137 10.9315 15.146 10.5888 14.3386 10.0093V14.2024C14.3386 16.2949 12.635 18 10.5443 18C9.73702 18 8.98535 17.7436 8.37012 17.3118C7.39025 16.6236 6.75 15.4868 6.75 14.2024C6.75 12.1072 8.45091 10.4049 10.5415 10.4077C10.7169 10.4077 10.8867 10.4216 11.0538 10.4439V10.9315L11.0477 10.9344L11.0538 10.9343V12.5502C10.8923 12.5001 10.7197 12.4695 10.5415 12.4695C9.58669 12.4695 8.81001 13.2468 8.81001 14.2024C8.81001 14.8684 9.1886 15.4451 9.73979 15.7376C9.74796 15.7488 9.75625 15.7599 9.76465 15.7708C9.75831 15.7586 9.75098 15.7466 9.74261 15.7348C9.98479 15.863 10.2576 15.9354 10.5471 15.9354C11.4797 15.9354 12.2425 15.1916 12.2758 14.2665L12.2786 6H14.3387Z"
    />
  </SvgIcon>
);

export default TikTokIcon;
