import { RefObject, useState } from 'react';

const useMenuAnchor = (ref?: RefObject<HTMLElement>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event?: React.MouseEvent<HTMLElement>) => {
    if (ref && ref.current) {
      setAnchorEl(ref.current);
    } else if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return { anchorEl, handleOpen, handleClose, isOpen };
};

export default useMenuAnchor;
