import { useEffect, useMemo, useState } from 'react';

import { Stepper, Step, StepLabel, Typography, Stack } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import {
  useCreateEvaluation,
  useEvaluationTitleExists,
  usePreviewEvaluation
} from '@/hooks/queries/evaluations';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { addDays } from '@/utils/datesAndTime';
import {
  convertFormToParameters,
  convertParametersToForm
} from '@/utils/evaluations/evaluation';
import getRoute from '@/utils/getRoute';
import { validateString } from '@/utils/text';

import Step1 from './_partials/Step1';
import Step2 from './_partials/Step2';
import Step3 from './_partials/Step3';
import Step4 from './_partials/Step4';

const useStyles = makeStyles()(() => ({
  stepper: {
    height: '72px'
  }
}));

const CreateEvaluationDialog = ({ onClose, evaluationCopy }) => {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const navigate = useWorkspaceNavigate();
  const alert = useAlert();

  const [activeStep, setActiveStep] = useState(0);
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [errorPreview, setErrorPreview] = useState(false);
  const [previewResults, setPreviewResults] = useState({
    items: 0,
    creators: 0,
    keywords: 0,
    keywordGroups: 0
  });

  const creators = [];

  const [form, setForm] = useState(
    evaluationCopy !== undefined
      ? {
          ...convertParametersToForm(evaluationCopy.parameters),
          title: evaluationCopy.title
        }
      : {
          choosenCreators: [],
          requestedServices: [],
          dates: [addDays(new Date(), -7), new Date()],
          followersRange: { min: '', max: '' },
          mightKeywordsAndKeywordsGroup: { groups: [], keywords: [] },
          mustKeywordsAndKeywordsGroup: { groups: [], keywords: [] },
          noneKeywordsAndKeywordsGroup: { groups: [], keywords: [] },
          platforms: {
            youtube: { shorts: true, videos: true },
            instagram: { reels: true, posts: true, stories: true },
            tiktok: true
          },
          someKeywordsAndKeywordsGroup: { groups: [], keywords: [] },
          platformDisclosure: -1,
          title: ''
        }
  );

  const [errors, setErrors] = useState({
    followersRange: '',
    platforms: '',
    title: ''
  });

  const hasVisualRecognitionSelected =
    form.requestedServices?.includes('minors');

  const { mutate: createEvaluation, isLoading: isSubmitting } =
    useCreateEvaluation(
      ({ data }) => {
        const { title } = form;
        const { id } = data;

        queryClient.invalidateQueries({ queryKey: ['evaluations'] });
        const message = hasVisualRecognitionSelected
          ? `Evaluation "${title}" has been processed. Visual Recognition service requested, we will get in contact with you soon.`
          : `Evaluation "${title}" has been processed.`;

        alert.success(
          message,
          <Button
            variant="text"
            size="small"
            onClick={() => navigate(getRoute.evaluations.DETAIL(id))}
            text="open"
          />
        );

        onClose();
      },
      () => {
        const { title } = form;
        alert.error(
          <>
            <Typography>Oops, something went wrong.</Typography>
            <Typography>
              Evaluation &quot;{title}&quot; could not be processed.
            </Typography>
          </>
        );
      }
    );

  const { mutate: previewEvaluation, isLoading: isLoadingPreviewResults } =
    usePreviewEvaluation(
      ({ data }) =>
        setPreviewResults({
          creators: data.totalCreators,
          items: data.totalPosts,
          keywordGroups: data.totalKeywordGroups,
          keywords: data.totalKeywords
        }),
      () => {
        setErrorPreview(true);
      }
    );

  const { mutate: evaluationTitleExists } = useEvaluationTitleExists(
    ({ data }) => {
      const titleAlreadyExists = Object.keys(data).length > 0;
      if (titleAlreadyExists)
        setErrors({ ...errors, title: 'This title already exits' });
      else setActiveStep(1);
    }
  );

  useEffect(() => {
    if (evaluationCopy !== undefined && creators.length > 0) {
      const choosenCreators = [];
      const selectedCreatorsIds = Object.values(
        evaluationCopy.parameters.creators
      ).flat();

      selectedCreatorsIds.forEach((creatorId) => {
        const selectedCreator = creators.find(
          (creator) => creator.id === creatorId
        );
        choosenCreators.push(selectedCreator);
      });

      setForm({ ...form, choosenCreators });
    }
  }, [creators]);

  const handleClickPrevious = () => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
        setActiveStep(activeStep - 1);
        break;
      case 3:
        setActiveStep(activeStep - 1);
        setPreviewResults({
          creators: 0,
          items: 0,
          keywordGroups: 0,
          keywords: 0
        });
        break;
      default:
        break;
    }
  };

  const handleClickContinue = () => {
    switch (activeStep) {
      case 0:
        {
          const { title } = form;

          if (!validateString(title, undefined, 3)) {
            setErrors({
              ...errors,
              title: 'Please create a title of 3 characters or more'
            });
            return;
          }

          evaluationTitleExists(title);
        }
        break;
      case 1:
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setActiveStep(activeStep + 1);
        previewEvaluation(convertFormToParameters(form));
        break;
      case 3:
        {
          const data = {
            title: form.title,
            parameters: convertFormToParameters(form)
          };

          createEvaluation(data);
        }
        break;
      default:
        break;
    }
  };

  const steps = [
    {
      title: 'Add Title',
      content: (
        <Step1
          errors={{ title: errors.title }}
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            setForm(newForm);
            if (errors.title) setErrors({ ...errors, title: '' });
          }}
          values={{ title: form.title }}
          onClickContinue={handleClickContinue}
        />
      )
    },
    {
      title: 'Set Type of content',
      content: (
        <Step2
          errors={{
            followersRange: errors.followersRange,
            platforms: errors.platforms
          }}
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            const newErrors = { ...errors };

            const {
              platforms: {
                instagram: { posts, reels, stories },
                tiktok,
                youtube: { shorts, videos }
              },
              followersRange: { min, max }
            } = newForm;

            if (max !== '' && Number(min) > Number(max))
              newErrors.followersRange =
                'Minimum value cannot be lower than maximum';
            else if (errors.followersRange) newErrors.followersRange = '';
            if (!shorts && !videos && !tiktok && !reels && !posts && !stories)
              newErrors.platforms = 'At least one platform should be selected';
            else if (newErrors.platforms) newErrors.platforms = '';

            setForm(newForm);
            setErrors(newErrors);
          }}
          values={{
            choosenCreators: form.choosenCreators,
            dates: form.dates,
            followersRange: form.followersRange,
            platforms: form.platforms,
            platformDisclosure: form.platformDisclosure
          }}
        />
      )
    },
    {
      title: 'Set Keywords',
      content: (
        <Step3
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            setForm(newForm);
          }}
          values={{
            mightKeywordsAndKeywordsGroup: form.mightKeywordsAndKeywordsGroup,
            mustKeywordsAndKeywordsGroup: form.mustKeywordsAndKeywordsGroup,
            noneKeywordsAndKeywordsGroup: form.noneKeywordsAndKeywordsGroup,
            someKeywordsAndKeywordsGroup: form.someKeywordsAndKeywordsGroup
          }}
        />
      )
    },
    {
      title: 'Review Evaluation',
      content: (
        <Step4
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            setForm(newForm);
          }}
          results={previewResults}
          isLoading={isLoadingPreviewResults}
          error={errorPreview}
        />
      )
    }
  ];

  const isContinueDisabled = useMemo(() => {
    if (activeStep === 0) return !!errors.title;
    if (activeStep === 1) return !!errors.followersRange || !!errors.platforms;
    if (activeStep === 3) return isLoadingPreviewResults;
    return false;
  }, [activeStep, errors, isLoadingPreviewResults]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => setExitDialogOpen(true)}
        showCloseIcon
        open={true}
        title="Create new evaluation"
        showHeaderDivider
        dialogContent={
          <Stack justifyContent="center">
            <Stepper className={classes.stepper} activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.title}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>{steps[activeStep].content}</>
          </Stack>
        }
        actions={
          <>
            {activeStep > 0 && (
              <Button
                variant="text"
                text="previous"
                onClick={handleClickPrevious}
                disabled={isSubmitting}
              />
            )}
            <Button
              disabled={isContinueDisabled}
              onClick={handleClickContinue}
              text={activeStep === 3 ? 'save evaluation' : 'continue'}
              variant={activeStep === 3 ? 'contained' : 'text'}
              loading={isSubmitting}
            />
          </>
        }
      />
      <Dialog
        open={isExitDialogOpen}
        title="Leaving this page"
        dialogContent={
          <Typography>
            Are you sure you want to leave this page? Your changes will not be
            saved.
          </Typography>
        }
        actions={
          <>
            <Button
              onClick={() => setExitDialogOpen(false)}
              variant="text"
              text="cancel"
            />
            <Button onClick={() => onClose()} text="leave" />
          </>
        }
      />
    </>
  );
};

CreateEvaluationDialog.propTypes = {
  onClose: PropTypes.func,
  evaluationCopy: PropTypes.object
};

export default CreateEvaluationDialog;
