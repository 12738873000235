import { useCallback } from 'react';

import { List } from '@mui/icons-material';
import { Typography } from '@mui/material';

import PropTypes from 'prop-types';

import DataTable from '@/components/dataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useUrlParams } from '@/hooks/urlParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';
import nFormatter from '@/utils/nFormatter';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  search: undefined
};

const KeywordGroupsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<List color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Keyword groups"
    text="The keyword group feature enables you to effortessly establish your keyword criteria for evaluations."
  />
);

const Results = ({
  keywordGroupsList,
  selectedGroups,
  onSelectGroup,
  isLoading
}) => {
  const navigate = useWorkspaceNavigate();
  const urlParams = useUrlParams(getRoute.keywords.LIST(), defaultParams);

  const onChangePageSize = useCallback(
    (newSize) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const onChangePage = useCallback(
    (newPage) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const onSortModelChange = useCallback(
    (params) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({ sort: newSort, order: newOrder });
    },
    [urlParams]
  );

  const { sort, order, page, size } = urlParams.params;

  const headers = [
    {
      field: 'title',
      headerName: 'Title',
      width: 160,
      flex: 1,
      renderCell: (params) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.title}
        </Typography>
      )
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 140,
      flex: 1,
      renderCell: (params) => getPrettyDate(params.row.createdAt)
    },
    {
      field: 'updatedAt',
      headerName: 'Last edited',
      width: 140,
      flex: 1,
      renderCell: (params) => getPrettyDate(params.row.updatedAt)
    },
    {
      field: 'totalItems',
      headerName: 'Items',
      width: 100,
      renderCell: (data) => (
        <Typography>{nFormatter(data.row.keywords.length)}</Typography>
      )
    }
  ];

  let emptyStateKeywordGroups = KeywordGroupsOnBoardingOverlay;

  if (urlParams.params.search !== undefined) {
    emptyStateKeywordGroups = () => <NoRowsOverlay />;
  }

  return (
    <DataTable
      rowSelectionModel={selectedGroups}
      onRowSelectionModelChange={onSelectGroup}
      rowHeight={80}
      rows={keywordGroupsList || []}
      checkboxSelection={true}
      columns={headers}
      loading={isLoading}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onSortModelChange={onSortModelChange}
      onRowClick={(row) => navigate(getRoute.keywords.DETAIL(row.id))}
      sort={sort}
      order={order}
      page={page}
      size={size}
      noRowsOverlay={emptyStateKeywordGroups}
    />
  );
};

Results.propTypes = {
  keywordGroupsList: PropTypes.array.isRequired,
  selectedGroups: PropTypes.array,
  onSelectGroup: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
