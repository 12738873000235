import { EditOutlined, MergeOutlined } from '@mui/icons-material';
import {
  IconButton,
  Skeleton,
  Stack,
  Typography,
  Tooltip
} from '@mui/material';

import Avatar from '@/components/avatar/Avatar';
import Card from '@/components/card';
import ResultCard from '@/components/resultCard';
import { getPrettyDate } from '@/utils/datesAndTime';

interface Props {
  name: string;
  avatarUrl: string;
  creationDate: string;
  showSkeleton: boolean;
  onClickEdit: () => void;
  onClickMerge: () => void;
}

const Profile = ({
  name,
  avatarUrl,
  creationDate,
  showSkeleton,
  onClickEdit,
  onClickMerge
}: Props) => (
  <Card
    sx={{ marginBottom: '16px', boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)' }}
    content={
      showSkeleton ? (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={4}>
            <Skeleton variant="circular" width={170} height={170} />
            <Stack justifyContent="space-between">
              <Stack>
                <Skeleton
                  variant="text"
                  width="200px"
                  sx={{ fontSize: '3rem' }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" justifyContent="space-between">
            <Stack alignItems="center" direction="row">
              <IconButton disabled>
                <MergeOutlined />
              </IconButton>
              <IconButton disabled>
                <EditOutlined />
              </IconButton>
            </Stack>
            <ResultCard
              items={[
                {
                  loading: true,
                  title: 'Creator created at:',
                  label: ''
                }
              ]}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={4}>
            <Avatar src={avatarUrl} name={name} width="170px" fontSize={68} />
            <Stack justifyContent="space-between">
              <Stack>
                <Typography variant="h4">{name}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" justifyContent="space-between">
            <Stack alignItems="center" direction="row">
              <Tooltip title="Merge creator">
                <IconButton onClick={onClickMerge}>
                  <MergeOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit creator">
                <IconButton onClick={onClickEdit}>
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
            <ResultCard
              items={[
                {
                  label: getPrettyDate(creationDate),
                  title: 'Creator created at:'
                }
              ]}
            />
          </Stack>
        </Stack>
      )
    }
  />
);

export default Profile;
