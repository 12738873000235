import { api } from '.';

export const getKeywordGroups = () => api.get(`keywords/categories`);

export const getKeywordsAutocomplete = (val: string) =>
  api.get(`keywords/autocomplete?s=${val}`);

export const createKeywordGroup = (payload: { title: string }) =>
  api.post(`keywords/categories`, payload);

export const createKeywords = (payload: {
  keywords: Array<string>;
  categoryId: string;
}) => api.post(`keywords`, payload);

export const deleteKeywordGroups = (payload: { categoryIds: Array<string> }) =>
  api.delete(`keywords/categories`, { data: payload });

export const deleteKeywords = (payload: {
  values: Array<string>;
  categoryId: string;
}) => api.delete(`keywords`, { data: payload });
