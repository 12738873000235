import { Theme } from '@mui/material';

// Use this file to override all themes
export default (baseTheme: Theme) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: 0
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        borderRadius: 0
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0
        }
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      indeterminate: {
        color: baseTheme.palette.primary.main
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingBottom: '0px'
        }
      }
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 'none',
        background: '#FFFFFF',
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden'
        }
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 0
      }
    }
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: baseTheme.palette.background.default
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        ...(ownerState.color === 'error' && {
          backgroundColor: '#F8E8E8',
          color: baseTheme.palette.error.main
        }),
        ...(ownerState.color === 'warning' && {
          backgroundColor: '#FAF8EB',
          color: baseTheme.palette.warning.main
        })
      })
    }
  }
});
