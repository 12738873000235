import {
  Box,
  FormControl,
  FormControlLabel,
  ListSubheader,
  Menu,
  Radio,
  RadioGroup
} from '@mui/material';

import { useFetchWorkspaces } from '@/hooks/queries/workspaces';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { changeWorkspace } from '@/store/userSlice';

interface WorkspaceMenuProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const WorkspaceMenu = ({
  isOpen,
  anchorEl,
  handleClose
}: WorkspaceMenuProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const { data: workspaces = [] } = useFetchWorkspaces({});

  const handleChangeWorkspace = (selectedWorkspaceId: number) => () => {
    if (selectedWorkspaceId === user.workspaceId) return;
    dispatch(changeWorkspace(selectedWorkspaceId));
  };

  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
      <ListSubheader>Workspace</ListSubheader>
      <FormControl>
        <RadioGroup value={user.workspaceId}>
          {workspaces.map((workspace) => (
            <Box
              key={`menu-item__${workspace.id}`}
              style={{ paddingLeft: '16px' }}
            >
              <FormControlLabel
                value={workspace.id}
                control={<Radio />}
                label={workspace.name}
                onClick={handleChangeWorkspace(workspace.id)}
              />
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </Menu>
  );
};

export default WorkspaceMenu;
