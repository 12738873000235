import { useCallback } from 'react';

import PropTypes from 'prop-types';

import DataTable from '@/components/dataTable';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useUrlParams } from '@/hooks/urlParams';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  search: ''
};

const Results = ({
  keywordsList,
  selectedKeywords,
  onSelectKeyword,
  isLoading
}) => {
  const urlParams = useUrlParams(getRoute.keywords.DETAIL(), defaultParams);

  const onChangePageSize = useCallback(
    (newSize) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const onChangePage = useCallback(
    (newPage) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const onSortModelChange = useCallback(
    (params) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({ sort: newSort, order: newOrder });
    },
    [urlParams]
  );

  const headers = [
    {
      field: 'value',
      headerName: 'Keyword',
      flex: 1,
      width: 160,
      renderCell: (params) => params.row.value
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      width: 140,
      renderCell: (params) => getPrettyDate(params.row.createdAt)
    }
  ];

  const { sort, order, page, size } = urlParams.params;

  return (
    <DataTable
      rowSelectionModel={selectedKeywords}
      onRowSelectionModelChange={(selectedIds) => {
        onSelectKeyword(selectedIds);
      }}
      rowHeight={80}
      rows={keywordsList || []}
      columns={headers}
      loading={isLoading}
      checkboxSelection={true}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onSortModelChange={onSortModelChange}
      sort={sort}
      order={order}
      page={page}
      size={size}
    />
  );
};

Results.propTypes = {
  keywordsList: PropTypes.array,
  selectedKeywords: PropTypes.array,
  onSelectKeyword: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
