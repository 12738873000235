import { createTheme } from '@mui/material/styles';

import overrides from './theme/overrides';
import standard from './theme/standard';
import typography from './theme/typography';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#A28253',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#006666',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#CFB53B',
      contrastText: '#000000'
    },
    error: {
      main: '#B71D18',
      contrastText: '#FFFFFF'
    },
    // The colors used to style the action elements.
    action: {
      // The color of an active action like an icon button. 54%
      active: '#000000',

      // The color of an hovered action. 4%
      hover: '#0000000a',

      // The color of a selected action. 18%
      selected: '#0000002E',

      // The color of a disabled action. 26%
      disabled: '#00000042',

      // The background color of a disabled action. 12%
      disabledBackground: '#0000001f',
      focus: '#0000001f'
    },
    opacity75: {
      main: '#000000BF',
      contrastText: '#FFFFFF'
    },
    opacity25: {
      main: '#00000040',
      contrastText: '#FFFFFF'
    },
    text: standard.text,
    // The background colors used to style the surfaces.
    // Consistency between these values is important.
    background: standard.background
  },
  typography
});

const themeDefault = createTheme({
  ...baseTheme,
  components: {
    ...overrides(baseTheme)
  }
});

export default themeDefault;
