import { DeleteOutlined, Search } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Typography,
  SxProps
} from '@mui/material';

import Card from '@/components/card';

interface Props {
  isDeleteEnabled?: boolean;
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickDelete?: () => void;
  showDeleteButton?: boolean;
  showSearchInput?: boolean;
  totalItems?: number;
  searchValue?: string;
  sx?: SxProps;
}

const Toolbar = ({
  isDeleteEnabled,
  onChangeSearch,
  onClickDelete,
  showDeleteButton = false,
  showSearchInput = false,
  totalItems,
  searchValue,
  sx
}: Props) => (
  <Card
    dataTestId="toolbar"
    sidePadding={false}
    sx={{ ...sx }}
    content={
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        {!!totalItems && (
          <Typography marginRight="auto">Total: {totalItems}</Typography>
        )}
        {(showDeleteButton || showSearchInput) && (
          <Stack direction="row" alignItems="center" ml="auto">
            {showDeleteButton && (
              <IconButton
                disabled={!isDeleteEnabled}
                onClick={onClickDelete}
                size="small"
                style={{ margin: '0px 5px' }}
                data-testid="toolbar-delete-button"
              >
                <DeleteOutlined
                  color={isDeleteEnabled ? 'action' : 'disabled'}
                />
              </IconButton>
            )}
            {showSearchInput && (
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  )
                }}
                label="Search"
                onChange={onChangeSearch}
                value={searchValue}
                size="small"
                variant="outlined"
                data-testid="toolbar-search-input"
              />
            )}
          </Stack>
        )}
      </Stack>
    }
  />
);

export default Toolbar;
