import { useParams } from 'react-router';

import { RemoveRedEye } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Dialog from '@/components/dialog';
import { convertParametersToForm } from '@/utils/evaluations/evaluation';

import Step2 from '../create/_partials/Step2';
import Step3 from '../create/_partials/Step3';

function EvaluationSummary({ isOpen, onClose }) {
  const queryClient = useQueryClient();
  const evaluationId = Number(useParams().evaluationId);

  const { parameters, requestedServices } = queryClient.getQueryData([
    'evaluation',
    evaluationId
  ]);

  const form = convertParametersToForm(parameters);

  return (
    <Dialog
      title="Evaluation Criteria"
      showHeaderDivider
      open={isOpen}
      onClose={() => onClose()}
      fullWidth
      maxWidth="lg"
      dialogContent={
        <Stack justifyContent="center">
          <Step2
            title="Type of content"
            isDisabled={true}
            showSubtitle={false}
            onChange={() => {}}
            values={{
              choosenCreators: form.choosenCreators,
              dates: form.dates,
              followersRange: form.followersRange,
              platforms: form.platforms,
              platformDisclosure: form.platformDisclosure
            }}
            errors={{
              followersRange: '',
              platforms: ''
            }}
          />
          <Step3
            title="Keywords"
            isDisabled={true}
            showSubtitle={false}
            values={{
              mightKeywordsAndKeywordsGroup: form.mightKeywordsAndKeywordsGroup,
              mustKeywordsAndKeywordsGroup: form.mustKeywordsAndKeywordsGroup,
              noneKeywordsAndKeywordsGroup: form.noneKeywordsAndKeywordsGroup,
              someKeywordsAndKeywordsGroup: form.someKeywordsAndKeywordsGroup
            }}
          />
          {requestedServices?.length > 0 && (
            <Stack padding={2} direction="row" gap={1}>
              <Typography color="textSecondary">
                This evaluation has requested Visual Recognition
              </Typography>
              <RemoveRedEye color="disabled" />
            </Stack>
          )}
        </Stack>
      }
    />
  );
}

EvaluationSummary.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EvaluationSummary;
