import { Paper, Tooltip } from '@mui/material';

import { Posts } from '@/models/posts';

interface PostIdentificationMarkersProps {
  items?: Array<Posts.MinorItem | Posts.OcrAdvImageItem>;
  dimensions: {
    width: number;
    height: number;
  };
}

const PostIdentificationMarkers = ({
  items = [],
  dimensions
}: PostIdentificationMarkersProps) => {
  const color = 'yellow';

  const overlayStyle = (item: Posts.MinorItem | Posts.OcrAdvImageItem) => ({
    width: item.coordinates.width * dimensions.width,
    height: item.coordinates.height * dimensions.height,
    left: item.coordinates.left * dimensions.width,
    top: item.coordinates.top * dimensions.height
  });

  return (
    <>
      {items.map((item, index) => (
        <Tooltip
          open={true}
          arrow
          title={
            'ageRange' in item && `${item.ageRange.low}-${item.ageRange.high}`
          }
          key={index}
          placement="top"
          disableHoverListener
        >
          <Paper
            id={`identification-marker-${index}`}
            elevation={3}
            sx={{
              ...overlayStyle(item),
              position: 'absolute',
              borderRadius: 0,
              border: `1px solid ${color}`,
              backgroundColor: 'transparent'
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: color,
                opacity: 0.1
              }}
            />
          </Paper>
        </Tooltip>
      ))}
    </>
  );
};

export default PostIdentificationMarkers;
