import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Typography, Stack } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import AutocompleteCreators from '@/components/autocompleteCreators/AutocompleteCreators';
import Button from '@/components/button';
import Dialog from '@/components/dialog/Dialog';
import {
  useFetchCreatorsAutocomplete,
  useMergeCreators
} from '@/hooks/queries/creators';
import useAlert from '@/hooks/useAlert';
import { Creator, CreatorsAutocomplete } from '@/models/creator';

interface Props {
  creator: Creator.Details;
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_ERRORS = {
  noCreatorsSelected: ''
};

const MergeCreators = ({ creator, isOpen, onClose }: Props) => {
  const { id } = useParams();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [creators, setCreators] = useState<CreatorsAutocomplete.Creator[]>([]);
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  const { data } = useFetchCreatorsAutocomplete({
    params: {
      groups: false
    }
  });

  const { mutate: mergeCreators, isLoading: isSubmitting } = useMergeCreators({
    id: Number(id),
    onSuccess: () => {
      alert.success(`Creators successfully merged!`);
      queryClient.invalidateQueries({
        queryKey: ['creators'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creatorsAutocomplete'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creator', Number(id)],
        refetchType: 'all'
      });
      onClose();
    },
    onError: () =>
      alert.error(`Some error occurred while merging the creators.`)
  });

  const handleSelectCreator = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: CreatorsAutocomplete.Creator[]
  ) => {
    setCreators(value);
    if (errors.noCreatorsSelected !== '') setErrors(INITIAL_ERRORS);
  };

  const handleSubmit = () => {
    if (creators.length === 0) {
      setErrors({
        ...errors,
        noCreatorsSelected: 'Please select at least one creator'
      });
      return;
    }

    mergeCreators({
      ids: creators.flatMap((item) => item.id)
    });
  };

  const creatorsOptions = useMemo(
    () => data?.creators.filter((item) => item.id !== creator.id),
    [data]
  );

  return (
    <Dialog
      open={isOpen}
      title="Merge Creator"
      subTitle={`Select the creators you want to merge with ${creator.name}. This
      action is not reversible.`}
      fullWidth
      maxWidth="sm"
      maxHeight={400}
      dialogContent={
        <Stack marginTop={1}>
          <AutocompleteCreators
            value={creators}
            onChange={handleSelectCreator}
            creators={creatorsOptions}
            label="Creators"
          />
          {errors.noCreatorsSelected && (
            <Typography color="error">{errors.noCreatorsSelected}</Typography>
          )}
        </Stack>
      }
      actions={
        <>
          <Button variant="text" text="Cancel" onClick={onClose} />
          <Button onClick={handleSubmit} text="Merge" loading={isSubmitting} />
        </>
      }
    />
  );
};

export default MergeCreators;
