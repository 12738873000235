import { SvgIcon, SvgIconProps } from '@mui/material';

import TreeIcon from '../TreeIcon';

const OrganicIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
  >
    <path d="M19 37.8889C29.432 37.8889 37.8889 29.432 37.8889 19C37.8889 8.56793 29.432 0.111084 19 0.111084C8.56793 0.111084 0.111084 8.56793 0.111084 19C0.111084 29.432 8.56793 37.8889 19 37.8889Z" />
    <g transform="translate(7 7)">
      <TreeIcon />
    </g>
  </SvgIcon>
);

export default OrganicIcon;
