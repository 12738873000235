import { Close } from '@mui/icons-material';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  IconButton
} from '@mui/material';

interface DialogPros extends MuiDialogProps {
  dialogContent: JSX.Element | string;
  actions?: JSX.Element;
  showHeaderDivider?: boolean;
  maxHeight?: number;
  titleIcon?: JSX.Element;
  subTitle?: string;
  showCloseIcon?: boolean;
}

const Dialog = ({
  open,
  title,
  onClose,
  actions,
  subTitle,
  titleIcon,
  dialogContent,
  showHeaderDivider = false,
  maxHeight = undefined,
  showCloseIcon = false,
  ...props
}: DialogPros) => (
  <MUIDialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        maxHeight
      }
    }}
    {...props}
  >
    {title && (
      <DialogTitle variant="h5" id="alert-dialog-title">
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          {title}
          {titleIcon && titleIcon}
          {showCloseIcon && onClose && (
            <IconButton
              onClick={() => onClose({}, 'backdropClick')}
              sx={{
                marginLeft: 'auto',
                color: (theme) => theme.palette.text.disabled
              }}
            >
              <Close />
            </IconButton>
          )}
        </Stack>
        {subTitle && <Typography color="textSecondary">{subTitle}</Typography>}
      </DialogTitle>
    )}
    {showHeaderDivider && <Divider />}
    {dialogContent && <DialogContent>{dialogContent}</DialogContent>}
    {actions && (
      <DialogActions sx={{ padding: '0 24px', paddingBottom: '24px' }}>
        <Stack direction="row" gap={1}>
          {actions}
        </Stack>
      </DialogActions>
    )}
  </MUIDialog>
);

export default Dialog;
