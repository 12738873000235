import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import {
  Stack,
  Typography,
  Tabs,
  Tab,
  Chip,
  Box,
  useTheme,
  Theme
} from '@mui/material';

import Card from '@/components/card';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { Posts } from '@/models/posts';
import { msToPrettyTimestamp, msToSeconds } from '@/utils/datesAndTime';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingY={1}>{children}</Box>}
    </div>
  );
};

interface MinorSquareProps {
  obj: Posts.MinorItem | Posts.VideoMinorItem;
  index: number;
  isSelected?: boolean;
  theme: Theme;
  onClick?: () => void;
}

const MinorSquare = ({
  obj,
  index,
  isSelected,
  theme,
  onClick
}: MinorSquareProps) => {
  const isVideoMinorItem = 'timestamp' in obj;
  const minorsFound = isVideoMinorItem
    ? (obj as Posts.VideoMinorItem).minorsFound
    : [];

  const renderBox = (key: number) => (
    <Box
      display="flex"
      width="78px"
      height="78px"
      paddingY="27px"
      alignItems="center"
      justifyContent="center"
      color={theme.palette.secondary.contrastText}
      sx={{
        backgroundColor: theme.palette.primary.main,
        transition: 'all 0.3s ease',
        position: 'relative',
        zIndex: isSelected ? 1 : 0
      }}
      key={key}
    >
      <Typography variant="h5" fontWeight={600}>
        {index}
      </Typography>
    </Box>
  );

  const renderTimestamp = () => (
    <Typography variant="caption" fontSize={10} fontWeight={600}>
      {msToPrettyTimestamp((obj as Posts.VideoMinorItem).timestamp)}
    </Typography>
  );

  return minorsFound.length > 1 ? (
    <Stack
      direction="row"
      gap={1.8}
      border={
        !isSelected
          ? `1px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.secondary.main}`
      }
      onClick={onClick}
      sx={{
        backgroundColor: isSelected
          ? 'rgba(162, 130, 83, 0.30)'
          : 'rgba(0, 0, 0, 0.30)',
        transition: 'all 0.3s ease',
        position: 'relative',
        zIndex: isSelected ? 1 : 0,
        cursor: 'pointer'
      }}
    >
      {minorsFound.map((_, i) => (
        <Stack padding="5px" key={i}>
          {renderBox(i)}
          {renderTimestamp()}
        </Stack>
      ))}
    </Stack>
  ) : (
    <Stack
      padding="5px"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        backgroundColor: isSelected
          ? 'rgba(162, 130, 83, 0.30)'
          : 'transparent',
        border: isSelected
          ? `1px solid ${theme.palette.secondary.main}`
          : 'none',
        transition: 'all 0.3s ease',
        position: 'relative',
        zIndex: isSelected ? 1 : 0
      }}
    >
      {renderBox(0)}
      {isVideoMinorItem && renderTimestamp()}
    </Stack>
  );
};
const PostVisuals = () => {
  const {
    data,
    activeIndex,
    hasMinorsResults,
    minors,
    setVideoPlaybackStart,
    setVideoLastClick,
    isRecognitionHighlighted0to6,
    setRecognitionHighlighted0to6,
    isRecognitionHighlighted7to14,
    setRecognitionHighlighted7to14,
    isRecognitionHighlighted15to17,
    setRecognitionHighlighted15to17,
    selectedMinorItems,
    setSelectedMinorItems
  } = useContext(PostDetailsContext);

  const value = 0; // Tab value may be useState if more tabs are added
  const [ageRange, setAgeRange] = useState({ min: 0, max: 17 });
  const theme = useTheme();

  const onClickSquare2 = (obj: Posts.MinorItem | Posts.VideoMinorItem) => {
    if ('timestamp' in obj) {
      // Handle VideoMinorItem
      const isAlreadySelected = selectedMinorItems.some(
        (selectedItem) =>
          JSON.stringify(selectedItem) === JSON.stringify(obj.minorsFound[0])
      );

      const updatedSelectedItems = isAlreadySelected
        ? selectedMinorItems.filter(
            (selectedItem) =>
              !obj.minorsFound.some(
                (item) => JSON.stringify(selectedItem) === JSON.stringify(item)
              )
          )
        : obj.minorsFound;

      setSelectedMinorItems(updatedSelectedItems);
      setVideoPlaybackStart(msToSeconds(obj.timestamp));
      setVideoLastClick(new Date().getTime());
    } else {
      // Handle MinorItem
      const isAlreadySelected = selectedMinorItems.some(
        (selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(obj)
      );

      const updatedSelectedItems = isAlreadySelected
        ? selectedMinorItems.filter(
            (selectedItem) =>
              JSON.stringify(selectedItem) !== JSON.stringify(obj)
          )
        : [...selectedMinorItems, obj];

      setSelectedMinorItems(updatedSelectedItems);
    }
  };

  const getMinorsInRange = useMemo(() => {
    if (minors.length === 0) return () => [];

    return (min: number, max: number) => {
      const activeMedia = minors[activeIndex];
      if (activeMedia.length === 0) return [];

      return activeMedia.flatMap((obj) => {
        const isVideo = 'timestamp' in obj;

        const filteredMinors = isVideo
          ? (obj as Posts.VideoMinorItem).minorsFound.filter(
              (minorItem) =>
                minorItem.predictedAge >= min && minorItem.predictedAge <= max
            )
          : [obj as Posts.MinorItem].filter(
              (minorItem) =>
                minorItem.predictedAge >= min && minorItem.predictedAge <= max
            );

        return filteredMinors.length > 0
          ? [{ ...obj, minorsFound: filteredMinors }]
          : [];
      });
    };
  }, [minors, activeIndex]);

  const minorResultsChips = useMemo(
    () => [
      {
        type: '0-6',
        label: 'Age 0-6',
        onClick: () =>
          setRecognitionHighlighted0to6(!isRecognitionHighlighted0to6),
        isVisible: hasMinorsResults && getMinorsInRange(0, 6).length > 0,
        active: isRecognitionHighlighted0to6
      },
      {
        type: '7-14',
        label: 'Age 7-14',
        onClick: () =>
          setRecognitionHighlighted7to14(!isRecognitionHighlighted7to14),
        isVisible: hasMinorsResults && getMinorsInRange(7, 14).length > 0,
        active: isRecognitionHighlighted7to14
      },
      {
        type: '15-17',
        label: 'Age 15-17',
        onClick: () =>
          setRecognitionHighlighted15to17(!isRecognitionHighlighted15to17),
        isVisible: hasMinorsResults && getMinorsInRange(15, 17).length > 0,
        active: isRecognitionHighlighted15to17
      }
    ],
    [
      minors,
      hasMinorsResults,
      isRecognitionHighlighted0to6,
      isRecognitionHighlighted7to14,
      isRecognitionHighlighted15to17
    ]
  );

  const visualRecognitionForMinors = (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {minorResultsChips
        .filter((chip) => chip.isVisible)
        .map((chip) => (
          <Chip
            key={chip.type}
            onClick={chip.onClick}
            color="primary"
            label={chip.label}
            size="small"
            variant={chip.active ? 'filled' : 'outlined'}
          />
        ))}
    </Stack>
  );

  useEffect(() => {
    let newMin = 0;
    let newMax = 17;

    const ranges = [
      isRecognitionHighlighted0to6 && { min: 0, max: 6 },
      isRecognitionHighlighted7to14 && { min: 7, max: 14 },
      isRecognitionHighlighted15to17 && { min: 15, max: 17 }
    ].filter((range): range is { min: number; max: number } => Boolean(range));

    if (ranges.length > 0) {
      newMin = Math.min(...ranges.map((range) => range.min));
      newMax = Math.max(...ranges.map((range) => range.max));
    }

    setSelectedMinorItems([]);
    setAgeRange({ min: newMin, max: newMax });
  }, [
    isRecognitionHighlighted0to6,
    isRecognitionHighlighted7to14,
    isRecognitionHighlighted15to17
  ]);

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  return hasMinorsResults ? (
    <Card
      // sx={{ marginBottom: 2 }} // Replacing gap: 2 in PostLayout due to display block
      shadow
      hasBorder={
        isRecognitionHighlighted0to6 ||
        isRecognitionHighlighted7to14 ||
        isRecognitionHighlighted15to17
      }
      isCollapsible
      title="Visuals"
      content={
        <>
          <Tabs value={value}>
            <Tab wrapped style={{ padding: 0 }} label="Children" value={0} />
          </Tabs>
          <TabPanel value={0} index={0}>
            <Stack gap={2}>
              <Stack
                border={`1px solid ${theme.palette.grey[400]}`}
                borderRadius="4px"
                gap={1}
                padding={1}
                flexWrap="wrap"
                width="fit-content"
              >
                {visualRecognitionForMinors}
              </Stack>
              <Stack
                gap={2}
                maxHeight="265px"
                direction="row"
                alignSelf="stretch"
                flexWrap="wrap"
                sx={{ overflowY: 'auto' }}
              >
                {getMinorsInRange(ageRange.min, ageRange.max).map(
                  (obj, index) => {
                    const isVideo = 'timestamp' in obj;

                    // Determine if the current item is selected
                    const isSelected = isVideo
                      ? obj.minorsFound.every((minor) =>
                          selectedMinorItems.some(
                            (selectedItem) =>
                              JSON.stringify(selectedItem) ===
                              JSON.stringify(minor)
                          )
                        )
                      : selectedMinorItems.some(
                          (selectedItem) =>
                            JSON.stringify(selectedItem) === JSON.stringify(obj)
                        );

                    return (
                      <MinorSquare
                        onClick={() => onClickSquare2(obj)}
                        key={index}
                        obj={obj}
                        index={index + 1}
                        isSelected={isSelected}
                        theme={theme}
                      />
                    );
                  }
                )}
              </Stack>
            </Stack>
          </TabPanel>
        </>
      }
    />
  ) : (
    <></>
  );
};

export default PostVisuals;
