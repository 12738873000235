import { useState } from 'react';
import { useParams } from 'react-router';

import {
  DateRange,
  DescriptionOutlined,
  Group,
  PeopleOutlined,
  RemoveRedEye,
  SmartDisplay,
  BarChart
} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import InfoIcon from '@mui/icons-material/Info';
import {
  Typography,
  IconButton,
  Divider,
  Stack,
  Chip,
  Skeleton,
  Tooltip
} from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Card from '@/components/card';
import CircularProgress from '@/components/circularProgress/CircularProgress';
import { useAppSelector } from '@/hooks/redux';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { getPrettyDate } from '@/utils/datesAndTime';
import { serviceMapper } from '@/utils/evaluations/mapper';
import getRoute from '@/utils/getRoute';
import EditEvaluation from '@/views/evaluations/edit';
import EvaluationSummary from '@/views/evaluations/summary';

const DataCard = ({ icon, title, value }) => (
  <Stack gap={1} sx={{ width: 240 }}>
    <Stack direction="row" gap={1} alignItems="center">
      {icon}
      <Typography noWrap>{title}</Typography>
    </Stack>
    {value}
  </Stack>
);

DataCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  value: PropTypes.node
};

const EvaluationCriteria = ({ isFetching }) => {
  const queryClient = useQueryClient();
  const navigate = useWorkspaceNavigate();
  const evaluationId = Number(useParams().evaluationId);

  const { parameters, authorId, totalCreators, totalItems, requestedServices } =
    queryClient.getQueryData(['evaluation', evaluationId]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const user = useAppSelector((state) => state.user.user);

  const handleOpenEditEvaluationDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditEvaluationDialog = () => {
    setIsEditDialogOpen(false);
  };

  // Function to return String of sponsership
  const sponsorship = () => {
    if (parameters.platformDisclosure === 1) return 'Sponsored';
    if (parameters.platformDisclosure === 0) return 'Not sponsored';
    return 'All';
  };

  // Function to return String of platform
  const getPlatformsString = () => {
    const platforms = [];

    if (!parameters.platforms) return platforms.join(', ');

    const { youtube, instagram, tiktok } = parameters.platforms;

    if (youtube.shorts || youtube.videos) platforms.push('Youtube');
    if (tiktok) platforms.push('Tiktok');
    if (instagram.reels || instagram.posts || instagram.stories)
      platforms.push('Instagram');

    return platforms.join(', ');
  };

  const isUserEvaluationOwner = authorId === user.id;

  const hasVRForMinorsSelected = requestedServices?.some(
    (service) => service.type === 'minors'
  );

  return (
    <Card
      content={
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Evaluation Criteria:</Typography>
            <Stack direction="row">
              <Tooltip
                arrow
                placement="top"
                title={
                  isUserEvaluationOwner && hasVRForMinorsSelected
                    ? 'You cannot edit an evaluation with Visual Recognition requested'
                    : ''
                }
              >
                <IconButton
                  onClick={handleOpenEditEvaluationDialog}
                  size="large"
                >
                  {isUserEvaluationOwner && !hasVRForMinorsSelected ? (
                    <CreateIcon />
                  ) : (
                    <InfoIcon />
                  )}
                </IconButton>
              </Tooltip>
              <IconButton
                size="large"
                onClick={() =>
                  navigate(getRoute.evaluations.DASHBOARD(evaluationId))
                }
              >
                <BarChart />
              </IconButton>
            </Stack>
          </Stack>
          {isEditDialogOpen && isUserEvaluationOwner && (
            <EditEvaluation
              isOpen={isEditDialogOpen}
              onClose={handleCloseEditEvaluationDialog}
            />
          )}
          {isEditDialogOpen && !isUserEvaluationOwner && (
            <EvaluationSummary
              isOpen={isEditDialogOpen}
              onClose={handleCloseEditEvaluationDialog}
            />
          )}
          <Stack gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              padding="12px 0px"
              justifyContent="space-between"
            >
              <DataCard
                icon={<DateRange color="action" fontSize="small" />}
                title="Date Range"
                value={
                  !isFetching ? (
                    <Stack direction="row" alignItems="center" gap={1.25}>
                      <Typography variant="body2" color="textSecondary">
                        {getPrettyDate(parameters.startCreationDate)}
                      </Typography>
                      <Typography>-</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {getPrettyDate(parameters.endCreationDate)}
                      </Typography>
                    </Stack>
                  ) : (
                    <Skeleton width="100%" />
                  )
                }
              />
              <Divider orientation="vertical" flexItem />
              <DataCard
                icon={<Group color="action" fontSize="small" />}
                title="Followers"
                value={
                  !isFetching ? (
                    <Stack direction="row" alignItems="center" gap={1.25}>
                      <Typography variant="body2" color="textSecondary">
                        {parameters.followersRange.min || 'Minimum'}
                      </Typography>
                      <Typography>-</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {parameters.followersRange.max || 'Maximum'}
                      </Typography>
                    </Stack>
                  ) : (
                    <Skeleton width="100%" />
                  )
                }
              />
              <Divider orientation="vertical" flexItem />
              <DataCard
                icon={<SmartDisplay color="action" fontSize="small" />}
                title="Platforms"
                value={
                  !isFetching ? (
                    <Typography variant="body2" color="textSecondary">
                      {getPlatformsString()}
                    </Typography>
                  ) : (
                    <Skeleton width="100%" />
                  )
                }
              />
              <Divider orientation="vertical" flexItem />
              <DataCard
                icon={<CheckCircleIcon color="action" fontSize="small" />}
                title="Sponsorship"
                value={
                  !isFetching ? (
                    <Typography variant="body2" color="textSecondary">
                      {sponsorship()}
                    </Typography>
                  ) : (
                    <Skeleton width="100%" />
                  )
                }
              />
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="flex-start"
              alignSelf="stretch"
              gap={3}
            >
              <Stack
                justifyContent="center"
                alignItems="flex-start"
                gap={2}
                flex="1 0 0"
              >
                <Typography variant="h6">Retrieved:</Typography>
                <Stack direction="row" gap={3}>
                  <Stack direction="row" gap={1}>
                    <PeopleOutlined fontSize="small" color="action" />
                    <Typography>{`${totalCreators} Creators`}</Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <DescriptionOutlined fontSize="small" color="action" />
                    <Typography>{`${totalItems} Items`}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              {requestedServices?.length > 0 && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <Stack alignItems="flex-start" gap={1} flex="1 0 0">
                    <Stack gap={1} alignItems="center" direction="row">
                      <RemoveRedEye color="action" />
                      <Typography variant="h6">Visual Recognition</Typography>
                    </Stack>
                    <Stack
                      gap={1}
                      direction="row"
                      maxWidth="523px"
                      sx={{ overflowX: 'auto' }}
                    >
                      {requestedServices?.map((service, index) => (
                        <Chip
                          sx={{ height: '33px' }}
                          key={index}
                          color="opacity75"
                          label={`${serviceMapper[service.type]}`}
                          onDelete={() => {}}
                          deleteIcon={
                            <Stack>
                              <CircularProgress
                                color="inherit"
                                typographysx={{ color: 'white' }}
                                size={27}
                                showValue
                                value={
                                  (service.analysedItems * 100) / totalItems ||
                                  0
                                }
                              />
                            </Stack>
                          }
                        />
                      ))}
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </>
      }
    />
  );
};

EvaluationCriteria.propTypes = {
  isFetching: PropTypes.bool
};

export default EvaluationCriteria;
