import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@/components/Page';
import Button from '@/components/button';
import Header from '@/components/header';
import { DEFAULT_PAGE } from '@/constants/table';
import { useFetchKeywordGroup } from '@/hooks/queries/keywords';
import { getPathWithLocalParams, useUrlParams } from '@/hooks/urlParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute';

import Error from '../../misc/error';
import AddKeywordToGroup from './_partials/AddKeywordsDialog';
import DeleteKeywordsDialog from './_partials/DeleteKeywordsDialog';
import Results from './_partials/Results';
import Toolbar from './_partials/Toolbar';

const defaultParams = {
  search: ''
};

const Keywords = () => {
  const id = Number(useParams().id);
  const navigate = useWorkspaceNavigate();

  const urlParams = useUrlParams(getRoute.keywords.DETAIL(), defaultParams);

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const {
    data: keywordGroup = {},
    isLoading,
    isError
  } = useFetchKeywordGroup(id);

  const handleChangeSearch = ({ target: { value } }) => {
    urlParams.updateParams({ search: value, page: DEFAULT_PAGE });
  };

  const keywordsList = keywordGroup?.keywords?.filter((data) =>
    data.value.toLowerCase().includes(urlParams.params.search?.toLowerCase())
  );

  const { search } = urlParams.params;

  if (isError) return <Error />;

  return (
    <>
      <Page
        title="Keywords"
        header={
          <Header
            actions={
              <Button
                onClick={() => setAddDialogOpen(true)}
                text="Add keyword"
              />
            }
            onClickReturn={() =>
              navigate(getPathWithLocalParams(getRoute.keywords.LIST()))
            }
            breadcrumbsList={[
              {
                name: 'Keyword Group',
                onClick: () =>
                  navigate(getPathWithLocalParams(getRoute.keywords.LIST()))
              }
            ]}
            title={keywordGroup?.title}
          />
        }
        toolbar={
          <Toolbar
            openDeleteDialog={() => setDeleteDialogOpen(true)}
            keywordsLength={keywordGroup.keywords?.length}
            keywordsList={keywordsList}
            onChangeSearch={handleChangeSearch}
            selectedKeywords={selectedKeywords}
            searchValue={search ? String(search) : ''}
          />
        }
      >
        <Results
          isLoading={isLoading}
          keywordsList={keywordsList}
          onSelectKeyword={setSelectedKeywords}
          selectedKeywords={selectedKeywords}
        />
      </Page>
      {isDeleteDialogOpen && (
        <DeleteKeywordsDialog
          isOpen={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          keywordsList={keywordsList}
          selectedKeywords={selectedKeywords}
        />
      )}
      {isAddDialogOpen && (
        <AddKeywordToGroup
          isOpen={isAddDialogOpen}
          onClose={() => setAddDialogOpen(false)}
        />
      )}
    </>
  );
};

export default Keywords;
