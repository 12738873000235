import { SvgIcon, SvgIconProps } from '@mui/material';

const DangerousIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.7298 2.99976H8.26976L2.99976 8.26976V15.7298L8.26976 20.9998H15.7298L20.9998 15.7298V8.26976L15.7298 2.99976ZM18.9998 14.8998L14.8998 18.9998H9.09976L4.99976 14.8998V9.09976L9.09976 4.99976H14.8998L18.9998 9.09976V14.8998ZM14.8298 7.75976L11.9998 10.5898L9.16976 7.75976L7.75976 9.16976L10.5898 11.9998L7.75976 14.8298L9.16976 16.2398L11.9998 13.4098L14.8298 16.2398L16.2398 14.8298L13.4098 11.9998L16.2398 9.16976L14.8298 7.75976Z"
      fill="white"
    />
  </SvgIcon>
);

export default DangerousIcon;
