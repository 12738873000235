import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import { MenuItem, Stack, Typography } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  useGridApiContext,
  gridDataRowIdsSelector,
  GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import {
  useDeleteEvaluationPosts,
  useGetEvaluationReport
} from '@/hooks/queries/evaluations';
import { useUrlParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import getRoute from '@/utils/getRoute/getRoute';

import KeywordsFilter from './KeywordsFilter';

const defaultParams = {
  rating: undefined,
  platformDisclosure: undefined,
  tags: undefined,
  hashtags: undefined,
  platform: undefined,
  createTime: undefined,
  keywords: undefined,
  keywordGroups: undefined,
  vrDetected: undefined
};

const getAvailableRows = ({ apiRef }) => gridDataRowIdsSelector(apiRef);

const Toolbar = ({
  selectionModel,
  setSelectionModel,
  refetch,
  onChangeApiRef,
  setIsExportingCSV
}) => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const evaluationId = Number(useParams().evaluationId);
  const apiRef = useGridApiContext();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [filterExpand, setFilterExpand] = useState(false);

  const urlParams = useUrlParams(
    getRoute.evaluations.DETAIL(evaluationId),
    defaultParams
  );
  const { parameters, totalItems } = queryClient.getQueryData([
    'evaluation',
    evaluationId
  ]);

  const { mutate: getReport, isLoading: isExportingPDF } =
    useGetEvaluationReport({
      id: evaluationId,
      onSuccess: () => {
        alert.success(
          `The selected ${
            selectionModel.length === 0 ? totalItems : selectionModel.length
          } posts will be exported! Please check your email.`
        );
      },
      onError: () => {
        alert.error('An error occurred while exporting the evaluation.');
      }
    });

  const { mutate: deletePosts, isLoading: isDeleting } =
    useDeleteEvaluationPosts(
      (result) => {
        const { data, status, response } = result;
        if (status === 200) {
          const message =
            data.deleted.length > 1
              ? 'Posts were successfully deleted!'
              : 'The post was successfully deleted!';
          alert.success(message);

          queryClient.invalidateQueries({ queryKey: ['evaluation'] });
          queryClient.invalidateQueries({ queryKey: ['posts'] });
          queryClient.invalidateQueries({
            queryKey: ['evaluations'],
            refetchType: 'inactive'
          });
          setIsDeleteDialogOpen(false);
          setSelectionModel([]);
        } else if (response.data.message) alert.error(response.data.message);
      },
      () => {
        alert.error('Something went wrong...');
      }
    );

  const handleExpand = () => {
    setFilterExpand(!filterExpand);
  };

  const handleConfirmDeletePost = () => {
    const payload = { evaluationId, postsIds: selectionModel };
    deletePosts(payload);
  };

  const handlePDFClickExport = () => {
    setIsExportDialogOpen(true);
  };

  const exportReport = () => {
    getReport({ params: urlParams.params, postsIds: selectionModel });
    setIsExportDialogOpen(false);
    setSelectionModel([]);
  };

  useEffect(() => {
    onChangeApiRef(apiRef);
  }, [apiRef]);

  const isExportCSVdisabled = getAvailableRows({ apiRef }).length === 0;

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px 12px 0 12px',
          height: '48px'
        }}
      >
        <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <KeywordsFilter
            handleExpand={handleExpand}
            parameters={parameters}
            filterExpand={filterExpand}
            onClickAway={() => {
              setFilterExpand(false);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Button
            text="delete"
            variant={selectionModel.length ? 'contained' : 'text'}
            color="error"
            disabled={!selectionModel.length}
            onClick={() => setIsDeleteDialogOpen(true)}
            startIcon={<DeleteOutlined />}
            size="small"
          />
          <GridToolbarExportContainer>
            <MenuItem
              disabled={isExportCSVdisabled}
              onClick={() => {
                setIsExportingCSV(true);
                refetch();
              }}
            >
              Export CSV
            </MenuItem>
            <MenuItem disabled={isExportingPDF} onClick={handlePDFClickExport}>
              Export PDF
            </MenuItem>
          </GridToolbarExportContainer>
        </Stack>
      </GridToolbarContainer>
      <Dialog
        title={selectionModel.length === 1 ? 'Delete Post' : 'Delete Posts'}
        onClose={isDeleting ? undefined : () => setIsDeleteDialogOpen(false)}
        dialogContent={
          selectionModel.length === 1 ? (
            <>
              <Typography>
                Are you sure you want to delete this post?
              </Typography>
              <Typography>You will not be able to recover it.</Typography>
            </>
          ) : (
            <>
              <Typography>
                Are you sure you want to delete {selectionModel.length} posts?
              </Typography>
              <Typography>You will not be able to recover these.</Typography>
            </>
          )
        }
        open={isDeleteDialogOpen}
        actions={
          <>
            <Button
              variant="text"
              text="cancel"
              disabled={isDeleting}
              onClick={() => setIsDeleteDialogOpen(false)}
            />
            <Button
              color="error"
              text="delete"
              onClick={handleConfirmDeletePost}
              loading={isDeleting}
            />
          </>
        }
      />
      <Dialog
        open={isExportDialogOpen}
        title="Export Posts"
        onClose={
          isExportingPDF ? undefined : () => setIsExportDialogOpen(false)
        }
        dialogContent={
          selectionModel.length === 0 ? (
            <Typography>
              Are you sure you want to export {totalItems} post(s)?
            </Typography>
          ) : (
            <Typography>
              Are you sure you want to export {selectionModel.length} post(s)?
            </Typography>
          )
        }
        actions={
          <>
            <Button
              variant="text"
              text="cancel"
              disabled={isExportingPDF}
              onClick={() => setIsExportDialogOpen(false)}
            />
            <Button
              text="export"
              onClick={exportReport}
              loading={isExportingPDF}
            />
          </>
        }
      />
    </>
  );
};

Toolbar.propTypes = {
  selectionModel: PropTypes.array,
  setSelectionModel: PropTypes.func,
  onChangeApiRef: PropTypes.func,
  refetch: PropTypes.func,
  setIsExportingCSV: PropTypes.func
};

export default Toolbar;
