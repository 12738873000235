import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { useAppDispatch } from '@/hooks/redux';
import { logout } from '@/store/userSlice';

const Logout = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Invalidate all queries in React Query
    queryClient.invalidateQueries({ refetchType: 'all' });

    // Perform the logout action
    dispatch(logout());
  }, [dispatch, queryClient]);

  return <Navigate to="/auth/login" />;
};

export default Logout;
