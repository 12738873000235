import {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useEffect,
  useRef
} from 'react';
import { VariableSizeList } from 'react-window';

import {
  useTheme,
  useMediaQuery,
  ListSubheader,
  Autocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderGroupParams
} from '@mui/material';

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = 'OuterElementType';

const LISTBOX_PADDING = 8;

interface RenderRowProps {
  data: ReactElement<any, string | JSXElementConstructor<any>>[];
  index: number;
  style: React.CSSProperties;
}

const renderRow = ({ data, index, style }: RenderRowProps) =>
  cloneElement(data[index], {
    style: {
      ...style,
      top:
        typeof style.top === 'number'
          ? style.top + LISTBOX_PADDING
          : LISTBOX_PADDING
    }
  });

const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props;
  const theme = useTheme();
  const itemData = Children.toArray(children).filter(
    (child) => isValidElement(child) && typeof child.type !== 'string'
  ) as ReactElement[];

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.displayName = 'ListboxComponent';

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children
];

const AutocompleteComponent = ({
  getOptionLabel,
  isOptionEqualToValue,
  options,
  renderInput,
  renderOption,
  value,
  defaultValue,
  onChange,
  renderTags,
  size,
  autoSelect,
  freeSolo,
  style,
  groupBy,
  onKeyDown,
  onInputChange,
  multiple = true,
  disabled = false,
  onBlur,
  clearOnBlur
}: MuiAutocompleteProps<
  any,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
>) => (
  <Autocomplete
    sx={{ backgroundColor: '#FFF' }}
    limitTags={3}
    size={size}
    style={style}
    multiple={multiple}
    freeSolo={freeSolo}
    autoSelect={autoSelect}
    value={value}
    defaultValue={defaultValue}
    groupBy={groupBy}
    onKeyDown={onKeyDown}
    data-testid="autocomplete"
    disableListWrap
    disableCloseOnSelect
    onChange={onChange}
    onInputChange={onInputChange}
    ListboxComponent={ListboxComponent}
    renderGroup={renderGroup}
    options={options}
    isOptionEqualToValue={isOptionEqualToValue}
    getOptionLabel={getOptionLabel}
    renderInput={renderInput}
    renderOption={renderOption}
    renderTags={renderTags}
    disabled={disabled}
    onBlur={onBlur}
    clearOnBlur={clearOnBlur}
  />
);

export default AutocompleteComponent;
