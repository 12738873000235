import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack, TextField } from '@mui/material';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@/components/button';
import { useAppDispatch } from '@/hooks/redux';
import { login } from '@/store/userSlice';

const LoginForm = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [failedToLogin, setFailedToLogin] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={({ email, password }) => {
          setIsLoading(true);
          dispatch(login({ email, password })).catch(
            (err: { code: string; message: string }) => {
              setFailedToLogin(true);
              setIsLoading(false);
              if (
                err.code === 'auth/wrong-password' ||
                err.code === 'auth/user-not-found'
              ) {
                setFailedMessage(
                  'Invalid email or password. Please try again.'
                );
              } else {
                setFailedMessage(err.message);
              }
            }
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit} style={{ marginBottom: '16px' }}>
            <Stack gap={2}>
              <TextField
                error={Boolean(touched.email && errors.email) || failedToLogin}
                fullWidth
                helperText={touched.email && errors.email}
                label="Business Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                sx={{ margin: 0 }}
              />
              <TextField
                error={
                  Boolean(touched.password && errors.password) || failedToLogin
                }
                fullWidth
                helperText={
                  touched.password && errors.password
                    ? errors.password
                    : failedMessage
                }
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                sx={{ margin: 0 }}
              />
              <Button
                fullWidth
                size="large"
                type="submit"
                text="Log in"
                loading={isLoading}
              />
            </Stack>
          </form>
        )}
      </Formik>
      <Link
        component={RouterLink}
        to="/auth/recover"
        color="textSecondary"
        variant="body2"
        sx={{
          textDecoration: 'underline'
        }}
      >
        Forgot Password?
      </Link>
    </>
  );
};

export default LoginForm;
