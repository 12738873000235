import { Stack, Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

const NoRowsOverlay = () => (
  <GridOverlay>
    <Stack justifyContent="center" alignItems="center" gap={2}>
      <img
        src={`${process.env.REACT_APP_S3_ASSETS}/empty-states/noResultsFound.svg`}
        alt="No Results Found"
      />
      <Typography variant="h6">No Results Found</Typography>
      <Typography>
        Try adjusting your search to find what you&apos;re looking for.
      </Typography>
    </Stack>
  </GridOverlay>
);

export default NoRowsOverlay;
