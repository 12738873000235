import { useContext } from 'react';

import { PostDetailsContext } from '@/contexts/PostDetailsContext';

import PostOcrAdvanced from './PostOcrAdvanced';
import PostOcrOld from './PostOcrOld';

const PostOcr = () => {
  const { hasOcrAdvancedItems } = useContext(PostDetailsContext);

  return hasOcrAdvancedItems ? <PostOcrAdvanced /> : <PostOcrOld />;
};

export default PostOcr;
