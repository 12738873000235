import React from 'react';

import { Avatar as MUIAvatar, Typography } from '@mui/material';

const getHashOfString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash: number, min: number, max: number): number =>
  Math.floor((hash % (max - min)) + min);

const HSLtoString = (hsl: number[]): string =>
  `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;

const generateHSL = (
  name: string,
  saturationRange: number[],
  lightnessRange: number[]
): number[] => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const getBackgroundColor = (
  name: string,
  saturationRange: number[],
  lightnessRange: number[]
): string => HSLtoString(generateHSL(name, saturationRange, lightnessRange));

const getInitialChars = (name = '') => {
  if (name === '') return '';

  const words = name.split(' ');

  const firstName = words[0];
  const lastName = words[words.length - 1];

  if (words.length === 1) return firstName[0];
  return `${firstName[0]}${lastName[0]}`;
};

const initialsAvatar = (
  name: string,
  width: string,
  fontSize: string | number
) => ({
  sx: {
    backgroundColor: getBackgroundColor(name, [40, 60], [30, 50]),
    width,
    height: width
  },
  children: (
    <Typography
      sx={{
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize
      }}
    >
      {getInitialChars(name)}
    </Typography>
  )
});

interface Props {
  src: string;
  name?: string;
  width?: string;
  fontSize?: string | number;
  onClick?: (e: React.MouseEvent) => void;
}

const Avatar = ({
  src,
  name = '',
  width = 'auto',
  fontSize = 'inherit',
  onClick
}: Props) => (
  <MUIAvatar
    onClick={onClick}
    src={src}
    alt={name}
    {...initialsAvatar(name, width, fontSize)}
  />
);

export default Avatar;
