import { AxiosPromise } from 'axios';

import { CreatorGroups } from '@/models/creator';
import { UnknownObject } from '@/models/misc';

import { decomposeParams, api } from '.';

export const getCreatorGroups = (
  params: UnknownObject
): AxiosPromise<CreatorGroups.ListItem> =>
  api.get(`/v2/creator-groups/${decomposeParams(params)}`);

export const getCreatorGroup = (
  id: number,
  params: UnknownObject
): AxiosPromise<CreatorGroups.Details> =>
  api.get(`/v2/creator-groups/${id}${decomposeParams(params)}`);

export const createCreatorGroup = (
  payload: CreatorGroups.Create
): AxiosPromise<CreatorGroups.Details> =>
  api.post(`/v2/creator-groups/`, payload);

export const addCreatorsToGroup = (
  id: number,
  payload: CreatorGroups.AddCreators
) => api.post(`/v2/creator-groups/${id}`, payload);

export const deleteCreatorGroup = (payload: CreatorGroups.DeleteGroups) =>
  api.delete(`/v2/creator-groups/`, { data: payload });

export const deleteCreatorsFromGroup = (
  id: number,
  payload: CreatorGroups.DeleteCreators
) => api.delete(`/v2/creator-groups/${id}/creators`, { data: payload });
