import { AxiosPromise } from 'axios';

import { User } from '@/models/user';

import { api } from '.';

export const getUserSelf = (): AxiosPromise<User> => api.get(`users/self`);

export const updateUserSelf = (payload: Partial<User>): AxiosPromise<User> =>
  api.put(`users/self`, payload);
