import { useContext, useMemo, useEffect, useRef } from 'react';

import { Stack, Typography, Link } from '@mui/material';

import parse, { HTMLReactParserOptions, Element } from 'html-react-parser';

import Card from '@/components/card';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import {
  highlightWords,
  replaceNewLinesWithBreaks,
  replaceUrlsWithLinks
} from '@/utils/text/text';

const PostOcrOld = () => {
  const { data, selectedKeywords, activeIndex, hasOcrOldItems } =
    useContext(PostDetailsContext);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const highlightedRecognition = useMemo(() => {
    const text = data?.media[activeIndex]?.visualRecognition?.find(
      (item) => item.type === 'ocr'
    )?.text;

    if (!text) return undefined;

    let textOnScreen = text;

    if (selectedKeywords.length > 0) {
      textOnScreen = highlightWords(textOnScreen, selectedKeywords);
    }

    textOnScreen = replaceUrlsWithLinks(textOnScreen);
    textOnScreen = replaceNewLinesWithBreaks(textOnScreen);

    return textOnScreen;
  }, [data, selectedKeywords, activeIndex]);

  const textSegments = useMemo(
    () => (highlightedRecognition ? highlightedRecognition.split('--') : []),
    [highlightedRecognition]
  );

  const hasBorder = useMemo(
    () => highlightedRecognition?.includes('</b>'),
    [highlightedRecognition]
  );

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const { attribs } = domNode;
        if (attribs?.href) {
          return (
            <Link href={attribs.href} target="_blank">
              {attribs.href}
            </Link>
          );
        }
      }
      return domNode;
    }
  };

  useEffect(() => {
    if (containerRef.current && selectedKeywords.length > 0) {
      const highlightedElement = containerRef.current.querySelector('b');
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [highlightedRecognition, selectedKeywords]);

  if (!data) {
    return <Typography variant="body2">Data not available</Typography>;
  }

  return hasOcrOldItems ? (
    <Card
      sx={{ marginBottom: 2 }} // Replacing gap: 2 in PostLayout due to display block
      shadow
      hasBorder={hasBorder}
      isCollapsible
      title="Text on Screen"
      content={
        <Stack
          ref={containerRef}
          sx={{
            overflow: 'auto',
            maxHeight: 240
          }}
        >
          {textSegments.map((segment, index) => (
            <Typography key={index} variant="caption" color="textSecondary">
              {parse(segment, options)}
            </Typography>
          ))}
        </Stack>
      }
    />
  ) : (
    <></>
  );
};

export default PostOcrOld;
