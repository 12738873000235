import { Refresh } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import Alert from '@/components/alert';
import Button from '@/components/button';
import Dialog from '@/components/dialog';
import PlatformStatus from '@/components/platformStatus';
import { useGetScraperStatus } from '@/hooks/queries/scraper';
import { Scraper } from '@/models/scraper';
import { statusChecker } from '@/utils/scraperStatus';

interface ScraperStatusDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ScraperStatusDialog = ({ isOpen, onClose }: ScraperStatusDialogProps) => {
  const queryClient = useQueryClient();

  const scraperStatus = queryClient.getQueryData([
    'scraperStatus'
  ]) as Scraper.Status;

  const { refetch } = useGetScraperStatus();

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      open={isOpen}
      title="Data Collection Status"
      titleIcon={
        <IconButton
          color="primary"
          onClick={() => refetch()}
          sx={{
            marginLeft: 'auto'
          }}
        >
          <Refresh />
        </IconButton>
      }
      dialogContent={
        <>
          <Alert
            sx={{ borderRadius: 0, marginY: 1 }}
            variant="filled"
            content={statusChecker(scraperStatus).text}
            severity={statusChecker(scraperStatus).severity}
          />
          <Stack paddingY={1.25} gap={1}>
            <PlatformStatus
              platform="instagram"
              status={scraperStatus.instagram.status}
              lastUpdateDate={scraperStatus.instagram.lastUpdated}
            />
            <PlatformStatus
              platform="tiktok"
              status={scraperStatus.tiktok.status}
              lastUpdateDate={scraperStatus.tiktok.lastUpdated}
            />
            <PlatformStatus
              platform="youtube"
              status={scraperStatus.youtube.status}
              lastUpdateDate={scraperStatus.youtube.lastUpdated}
            />
          </Stack>
        </>
      }
      actions={<Button variant="text" text="Close" onClick={onClose} />}
    />
  );
};

export default ScraperStatusDialog;
