import { SvgIcon, SvgIconProps } from '@mui/material';

const TreeIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.17 10.8097H7.82998C6.96998 10.8097 6.31 10.4697 6.01 9.88972C5.71 9.29972 5.82998 8.56972 6.32998 7.87972L10.5 2.03973C11.24 0.979725 12.77 0.979727 13.52 2.04973L17.69 7.87972C18.19 8.56972 18.31 9.29972 18.01 9.88972C17.69 10.4697 17.03 10.8097 16.17 10.8097ZM12 2.69972C11.91 2.69972 11.81 2.77973 11.72 2.89973L7.54998 8.74972C7.34998 9.01972 7.34 9.17972 7.35 9.20972C7.36 9.22972 7.49999 9.30972 7.83999 9.30972H16.18C16.51 9.30972 16.65 9.21972 16.67 9.19972C16.67 9.17972 16.66 9.01972 16.47 8.74972L12.3 2.90973C12.19 2.76973 12.09 2.69972 12 2.69972Z"
      fill="white"
    />
    <path
      d="M17.5899 18.7498H6.41992C4.98992 18.7498 4.4199 18.0598 4.2099 17.6498C3.9999 17.2398 3.7799 16.3798 4.6199 15.2198L8.60989 9.61976C8.74989 9.41976 8.97991 9.30975 9.21991 9.30975H14.7899C15.0299 9.30975 15.2599 9.42976 15.3999 9.61976L19.3899 15.2298C20.2299 16.3798 19.9999 17.2398 19.7899 17.6498C19.5799 18.0598 19.0199 18.7498 17.5899 18.7498ZM9.59992 10.8098L5.8299 16.0998C5.5099 16.5398 5.47992 16.8398 5.53992 16.9698C5.60992 17.0998 5.86991 17.2498 6.40991 17.2498H17.5799C18.1199 17.2498 18.3899 17.0998 18.4499 16.9698C18.5199 16.8398 18.4799 16.5398 18.1599 16.0998L14.3899 10.7998H9.59992V10.8098Z"
      fill="white"
    />
    <path
      d="M11.9998 22.7498C11.5898 22.7498 11.2498 22.4098 11.2498 21.9998V17.9998C11.2498 17.5898 11.5898 17.2498 11.9998 17.2498C12.4098 17.2498 12.7498 17.5898 12.7498 17.9998V21.9998C12.7498 22.4098 12.4098 22.7498 11.9998 22.7498Z"
      fill="white"
    />
  </SvgIcon>
);

export default TreeIcon;
