import { useQuery } from '@tanstack/react-query';

import { getScraperStatus } from '@/api/scraper';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

export const useGetScraperStatus = (
  onSuccess?: onSuccessType,
  onError?: onErrorType
) =>
  useQuery({
    queryKey: ['scraperStatus'],
    queryFn: async () => {
      const result = await getScraperStatus();
      return result.data;
    },
    onSuccess,
    onError
  });
