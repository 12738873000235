import { useEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const FilterDateRange = ({ item, applyValue }: GridFilterInputValueProps) => {
  const SUBMIT_FILTER_STROKE_TIME = 500;

  const filterTimeout = useRef<any>();
  const [dates, setDates] = useState<[Date | null, Date | null]>(
    item.value || [null, null]
  );

  useEffect(
    () => () => {
      clearTimeout(filterTimeout.current);
    },
    []
  );

  const updateFilterValue = (newDates: [Date | null, Date | null]) => {
    clearTimeout(filterTimeout.current);
    setDates(newDates);

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: newDates });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleChangeFrom = (value: Date | null) => {
    updateFilterValue([value, dates[1]]);
  };

  const handleChangeTo = (value: Date | null) => {
    updateFilterValue([dates[0], value]);
  };

  return (
    <Stack direction="row" gap={1} pl="10px" pr="10px" width="350px" mt={1}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="dd/MM/yyyy"
          label="From"
          maxDate={dates[1] !== null ? dates[1] : undefined}
          onChange={handleChangeFrom}
          slotProps={{ textField: { size: 'small' } }}
          value={dates[0]}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="dd/MM/yyyy"
          label="To"
          minDate={dates[0] !== null ? dates[0] : undefined}
          onChange={handleChangeTo}
          slotProps={{ textField: { size: 'small' } }}
          value={dates[1]}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default FilterDateRange;
