import { Typography, Stack, Container } from '@mui/material';

import Page from '@/components/Page';

interface props {
  error?: number;
}

const Error = ({ error = 0 }: props) => (
  <Page>
    <Container>
      <Stack
        height="calc(100vh - 120px)"
        justifyContent="center"
        alignItems="center"
        spacing={5.5}
      >
        <img
          src={`${process.env.REACT_APP_S3_ASSETS}/empty-states/error.svg`}
          alt="Error"
        />
        <Stack gap={3}>
          <Stack padding="8px" gap={2.5} alignItems="center">
            <Typography variant="h4">Oops something went wrong</Typography>
            <Typography align="center">
              We are working on fixing the problem. We&apos;ll be up and running
              shortly.
            </Typography>
          </Stack>
          {error > 0 && (
            <Typography align="center" variant="caption">
              Error code: {error}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Container>
  </Page>
);

export default Error;
