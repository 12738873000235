import { JSXElementConstructor, useMemo } from 'react';

import { LinearProgress } from '@mui/material';
import {
  DataGridPro,
  GridPaginationModel,
  GridColumnMenuProps,
  GridColumnMenu,
  GridSortModel,
  DataGridProProps,
  GridSortDirection,
  GridFilterModel,
  GetColumnForNewFilterArgs,
  FilterColumnsArgs
} from '@mui/x-data-grid-pro';

import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ROWS_PER_PAGE_OPTIONS
} from '@/constants/table';

import NoRowsOverlay from './customNoRowsOverlay/NoRowsOverlay';

interface DataTableProps extends DataGridProProps {
  initialFilterModel?: GridFilterModel;
  onChangePage?: (newPage: string) => void;
  onChangePageSize?: (newPageSize: string) => void;
  order?: GridSortDirection;
  page?: string;
  size?: string;
  sort?: string;
  rowCount?: number;
  multipleFiltersColumns?: string[];
  toolbar?: JSXElementConstructor<any>;
  noRowsOverlay?: JSXElementConstructor<any>;
}

const DataTable = ({
  initialFilterModel,
  onChangePage,
  onChangePageSize,
  order = 'desc',
  page = DEFAULT_PAGE,
  rowCount,
  size = DEFAULT_PAGE_SIZE,
  sort,
  toolbar,
  columnVisibilityModel,
  pagination = true,
  noRowsOverlay = NoRowsOverlay,
  multipleFiltersColumns = [],
  ...props
}: DataTableProps) => {
  const handlePaginationModelChange = (model: GridPaginationModel) => {
    onChangePage?.((model.page + 1).toString());
    onChangePageSize?.(model.pageSize.toString());
  };
  const getInitialSortModel = useMemo(() => {
    const sortModel: GridSortModel = [
      {
        field: sort || '',
        sort: undefined
      }
    ];

    if (order === 'asc') sortModel[0].sort = 'asc';
    else sortModel[0].sort = 'desc';

    return sortModel;
  }, [sort]);

  const filterColumns = ({
    field,
    columns: ColumnsTemp,
    currentFilters
  }: FilterColumnsArgs) => {
    const filteredFields = currentFilters?.map((item) => {
      if (!multipleFiltersColumns.includes(item.field)) return item.field;
      return null;
    });
    return ColumnsTemp.filter(
      (colDef) =>
        colDef.filterable &&
        (colDef.field === field || !filteredFields.includes(colDef.field))
    ).map((column) => column.field);
  };

  const getColumnForNewFilter = ({
    currentFilters,
    columns: ColumnsTemp
  }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);

    const columnForNewFilter = ColumnsTemp.filter(
      (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
    ).find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? multipleFiltersColumns?.[0] ?? null;
  };

  return (
    <DataGridPro
      sx={{
        '.MuiDataGrid-cell:focus': { outline: 'none' },
        '& .MuiDataGrid-row:hover': {
          cursor: props.onRowClick ? 'pointer' : 'default'
        },
        height: '65vh'
      }}
      autoHeight={props.rows.length !== 0}
      disableRowSelectionOnClick
      initialState={{
        sorting: { sortModel: getInitialSortModel },
        filter: { filterModel: initialFilterModel },
        columns: { columnVisibilityModel }
      }}
      onPaginationModelChange={handlePaginationModelChange}
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      pagination={pagination}
      paginationModel={{ page: Number(page) - 1, pageSize: Number(size) }}
      rowCount={rowCount || props.rows.length}
      slotProps={{
        filterPanel: {
          filterFormProps: {
            filterColumns
          },
          getColumnForNewFilter
        }
      }}
      slots={{
        toolbar,
        noRowsOverlay,
        columnMenu: (menuProps: GridColumnMenuProps) => (
          <GridColumnMenu
            {...menuProps}
            slots={{ columnMenuColumnsItem: null }}
          />
        ),
        loadingOverlay: LinearProgress
      }}
      {...props}
    />
  );
};

export default DataTable;
