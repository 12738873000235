const YOUTUBE_LINK = 'https://www.youtube.com/';
const INSTAGRAM_LINK = 'https://www.instagram.com/';
const TIKTOK_LINK = 'https://www.tiktok.com/@';

const PLATFORM_LINKS = {
  youtube: YOUTUBE_LINK,
  instagram: INSTAGRAM_LINK,
  tiktok: TIKTOK_LINK
};

export default PLATFORM_LINKS;
