import { AxiosPromise } from 'axios';

import { POWERBI_API_URL } from '@/constants/powerbi';
import { PowerBI } from '@/models/powerbi';

import { api } from '.';

export const generateTokenInGroup = (
  groupId: string,
  reportId: string,
  payload: PowerBI.GenerateTokenInGroupPayload
): AxiosPromise<PowerBI.GenerateTokenResponse> =>
  api.post(
    `${POWERBI_API_URL}/myorg/groups/${groupId}/reports/${reportId}/GenerateToken`,
    payload
  );

export const generateEmbededToken = (
  payload: PowerBI.GenerateEmbedTokenPayload
): AxiosPromise<PowerBI.GenerateTokenResponse> =>
  api.post(`${POWERBI_API_URL}/myorg/GenerateToken`, payload);

export const getReportInGroup = (
  groupId: string,
  reportId: string
): AxiosPromise<PowerBI.GetReportInGroupResponse> =>
  api.get(`${POWERBI_API_URL}/myorg/groups/${groupId}/reports/${reportId}`);

export const getPowerBIapiToken =
  (): AxiosPromise<PowerBI.GetPowerBIapiTokenResponse> =>
    api.get(`v2/azure/powerbi-token`);

export const getRefreshHistoryInGroup = (
  datasetId: string,
  groupId: string,
  top?: number
): AxiosPromise<PowerBI.RefreshHistory> =>
  api.get(
    `${POWERBI_API_URL}/myorg/groups/${groupId}/datasets/${datasetId}/refreshes?$top=${top}`
  );

export const refreshDatasetInGroup = (
  datasetId: string,
  groupId: string,
  payload: any
): AxiosPromise<any> =>
  api.post(
    `${POWERBI_API_URL}/myorg/groups/${groupId}/datasets/${datasetId}/refreshes`,
    payload
  );
