import { FormControl, TextField, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  step: {
    maxWidth: '613px'
  },
  input: {
    marginTop: '24px'
  }
}));

const Step1 = ({
  errors,
  isEditDialog = false,
  onChange,
  onClickContinue,
  values
}) => {
  const { classes } = useStyles();

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) onClickContinue();
  };

  const handleChangeTitle = ({ target: { value } }) => {
    onChange('title', value);
  };

  const { title } = values;

  return (
    <div style={{ padding: !isEditDialog ? '24px 16px' : '0' }}>
      {!isEditDialog && (
        <Typography variant="h3" className={classes.step}>
          Let&apos;s start with the title of your evaluation
        </Typography>
      )}
      <FormControl
        className={classes.input}
        variant="outlined"
        fullWidth={isEditDialog}
      >
        <TextField
          error={Boolean(errors.title)}
          helperText={errors.title}
          label="Evaluation"
          onChange={handleChangeTitle}
          onKeyDown={handleKeyDown}
          value={title}
        />
      </FormControl>
    </div>
  );
};

Step1.propTypes = {
  errors: PropTypes.shape({
    title: PropTypes.string
  }),
  isEditDialog: PropTypes.bool,
  onChange: PropTypes.func,
  onClickContinue: PropTypes.func,
  values: PropTypes.shape({
    title: PropTypes.string
  })
};

export default Step1;
