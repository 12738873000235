import { Stack, Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

interface props {
  icon?: JSX.Element;
  title?: string;
  text?: string;
}

const OnBoardingOverlay = ({ icon, title, text }: props) => (
  <GridOverlay>
    <Stack justifyContent="center" alignItems="center" gap={2}>
      {icon && icon}
      {title && <Typography variant="h6">{title}</Typography>}
      {text && <Typography>{text}</Typography>}
    </Stack>
  </GridOverlay>
);

export default OnBoardingOverlay;
