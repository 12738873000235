import { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import { Group, Person } from '@mui/icons-material';
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import {
  GridRowSelectionModel,
  GridSortDirection,
  GridSortModel
} from '@mui/x-data-grid';

import Page from '@/components/Page';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable/DataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar/Toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchCreatorGroups } from '@/hooks/queries/creatorGroups';
import { useUrlParams } from '@/hooks/urlParams';
import { useDebounce } from '@/hooks/useDebounce';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { URLParams } from '@/models/misc';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute/getRoute';
import Error from '@/views/misc/error';

import CreateCreatorGroup from '../create/CreateCreatorGroup';
import DeleteCreatorGroup from '../delete/DeleteCreatorGroup';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined
};

const GroupsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<Group color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Creators Groups"
    text="A creators group enables you to effortlessly include the same group of creators in multiple evaluations."
  />
);

const CreatorGroupsList = () => {
  const urlParams = useUrlParams(getRoute.creators.LIST(), defaultParams);
  const deferredGroupsValue = useDebounce(urlParams.params.name, 500);
  const navigate = useWorkspaceNavigate();
  const theme = useTheme();
  const location = useLocation();

  const [isCreateGroupOpen, setIsCreateGroupOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const {
    data: groups,
    isLoading: isLoadingGroups,
    isError: isErrorGroups
  } = useFetchCreatorGroups({
    params: {
      ...urlParams.params,
      name: deferredGroupsValue ? String(deferredGroupsValue) : undefined
    }
  });

  const [selectedGroups, setSelectedGroups] = useState<GridRowSelectionModel>(
    []
  );

  const handleChangePageSize = useCallback(
    (newSize: string) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const handleChangePage = useCallback(
    (newPage: string) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const handleSortModelChange = useCallback(
    (params: GridSortModel) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({
        sort: newSort,
        order: (newOrder?.toUpperCase() as URLParams['order']) || undefined
      });
    },
    [urlParams]
  );

  const handleChangeSearch = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      urlParams.updateParams({
        name: value.replace(/^\s+/g, ''),
        page: DEFAULT_PAGE
      });
    },
    [urlParams]
  );

  const columns = useMemo(
    () => [
      {
        sortable: false,
        field: 'title',
        headerName: 'Title',
        flex: 1,
        width: 160,
        renderCell: (data: any) => {
          const { name } = data.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </Typography>
          );
        }
      },
      {
        field: 'creators',
        headerName: 'Creators',
        flex: 1,
        width: 160,
        renderCell: (data: any) => {
          const { totalItems: totalCreators } = data.row;

          return <Typography variant="body2">{totalCreators}</Typography>;
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Last edited',
        flex: 1,
        width: 140,
        renderCell: (data: any) => {
          const { updatedAt } = data.row;

          return (
            <Typography variant="body2">{getPrettyDate(updatedAt)}</Typography>
          );
        }
      }
    ],
    [groups?.results]
  );

  let emptyStateCreatorGroups = GroupsOnBoardingOverlay;
  if (urlParams.params.name !== undefined)
    emptyStateCreatorGroups = () => <NoRowsOverlay />;

  const { sort, order, page, size, name } = urlParams.params;

  if (isErrorGroups) return <Error />;

  return (
    <>
      <Page
        header={
          <Header
            title="Creator Groups"
            actions={
              <>
                <ToggleButtonGroup value="creatorGroups" size="small">
                  <ToggleButton
                    value="creators"
                    onClick={() => navigate('/creators')}
                    style={{
                      color: !location.pathname.includes('creator-groups') // FIXME: remove this when action theme colors are fixed (default theme)
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                    }}
                  >
                    <Person />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => navigate('/creator-groups')}
                    value="creatorGroups"
                    style={{
                      color: location.pathname.includes('creator-groups') // FIXME: remove this when action theme colors are fixed (default theme)
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                    }}
                  >
                    <Group />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  text="add group"
                  onClick={() => setIsCreateGroupOpen(true)}
                />
              </>
            }
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedGroups.length > 0}
            onClickDelete={() => setIsOpenDeleteDialog(true)}
            onChangeSearch={handleChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={name ? String(name) : ''}
            totalItems={groups?.totalItems || 0}
          />
        }
        title="Creator Groups"
      >
        {/* <Tabs
          value={1}
          onChange={(event, newValue) =>
            navigate(newValue === 0 ? '/creators' : '/creator-groups')
          }
          selectionFollowsFocus
          variant="fullWidth"
          aria-label="icon_label_tabs"
          sx={{ marginBottom: '16px' }}
        >
          <Tab icon={<Person />} label="CREATORS" />
          <Tab icon={<Group />} label="CREATOR GROUPS" />
        </Tabs> */}
        <DataTable
          columns={columns}
          rowHeight={80}
          rows={groups?.results || []}
          rowCount={groups?.totalItems}
          checkboxSelection
          disableColumnMenu
          loading={isLoadingGroups}
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          onChangePageSize={handleChangePageSize}
          onChangePage={handleChangePage}
          onSortModelChange={handleSortModelChange}
          onRowClick={(row) => navigate(getRoute.creatorGroups.DETAIL(row.id))}
          sort={sort}
          order={order?.toLowerCase() as GridSortDirection}
          page={page}
          size={size}
          rowSelectionModel={selectedGroups}
          onRowSelectionModelChange={setSelectedGroups}
          noRowsOverlay={emptyStateCreatorGroups}
        />
      </Page>
      {isCreateGroupOpen && (
        <CreateCreatorGroup
          isOpen={isCreateGroupOpen}
          onClose={() => setIsCreateGroupOpen(false)}
        />
      )}
      {isOpenDeleteDialog && (
        <DeleteCreatorGroup
          isOpen={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          selected={selectedGroups}
          clearSelection={() => {
            setSelectedGroups([]);
          }}
          groupsList={groups?.results || []}
        />
      )}
    </>
  );
};

export default CreatorGroupsList;
