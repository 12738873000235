import { useMemo, useState } from 'react';

import { InfoOutlined, List as ListIcon } from '@mui/icons-material';
import {
  TextField,
  useTheme,
  Checkbox,
  Chip,
  ListItem,
  ListItemText,
  Typography,
  List,
  Stack,
  Box,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';

import PropTypes from 'prop-types';

import AutocompleteComponent from '@/components/autocomplete';
import Dialog from '@/components/dialog';
import InfoTooltip from '@/components/infoTooltip';
import {
  useFetchKeywordGroups,
  useFetchKeywordsAutocomplete
} from '@/hooks/queries/keywords';
import { useDebounce } from '@/hooks/useDebounce';

// Validate input keyword
const validateKeywordsInput = (inputKeywords) => {
  const clearedKeywords = inputKeywords
    .replace(/\s(?=\s)/g, '') // replace multiple spaces for just one
    .toLowerCase()
    .split(',') // split to array from ,
    .filter((k) => k) // remove empty strings
    .filter((k) => k !== ' '); // remove spaces

  const trimmedKeywords = clearedKeywords.map((element) =>
    // remove space on the begin and end of the keyword
    element.trim()
  );

  // only let unique values
  const unique = [...new Set(trimmedKeywords)];
  return unique;
};

const Step3 = ({
  onChange,
  showSubtitle = true,
  title = 'Set Keywords and Keywords Groups',
  values,
  isDisabled = false
}) => {
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const [keywordGroupDialog, setKeywordGroupDialog] = useState({
    group: {},
    isOpen: false
  });

  const deferredSearch = useDebounce(searchTerm || null, 500);

  const { data: keywordGroups = [] } = useFetchKeywordGroups();
  const { data: availableKeywords = [] } =
    useFetchKeywordsAutocomplete(deferredSearch);

  const handleClickOpenKeywordGroup = (group) => {
    const completeGroup = keywordGroups.find((g) => g.id === group.id);
    setKeywordGroupDialog({ group: completeGroup, isOpen: true });
  };

  // HANDLERS AND HELPERS
  // Close keywords of the keywordGroup
  const handleCloseKeywordGroup = () => {
    setKeywordGroupDialog({ group: {}, isOpen: false });
  };

  // User adds new option/chip/keyword
  const handleNewChip = (input, field) => {
    const clearedKeywords = validateKeywordsInput(input);

    const keywordsToAdd = [];

    clearedKeywords.forEach((e) => {
      const newKeyword = {
        type: 'Keyword',
        title: e
      };
      keywordsToAdd.push(newKeyword);
    });

    onChange(field, {
      ...values[field],
      keywords: values[field].keywords.concat(keywordsToAdd)
    });
  };

  // When the user deletes a chip
  const handleDeleteChip = (element, type, field) => {
    const index = values[field][type].findIndex(
      (i) => i.title === element.title && i.type === element.type
    );

    const copyArray = values[field][type];
    if (index > -1) {
      copyArray.splice(index, 1);
    }

    onChange(field, { ...values[field], [type]: copyArray });
  };

  const handleOnChange = (reason, details, field) => {
    switch (reason) {
      case 'selectOption':
        {
          const fieldToChange =
            details.option.type === 'Keyword' ? 'keywords' : 'groups';
          const newValue = values[field][fieldToChange].concat(details.option);

          onChange(field, { ...values[field], [fieldToChange]: newValue });
        }
        break;
      case 'removeOption':
        handleDeleteChip(
          details.option,
          details.option.type === 'Keyword' ? 'keywords' : 'groups',
          field
        );
        break;
      case 'clear':
        onChange(field, { groups: [], keywords: [] });
        break;
      case 'createOption':
        handleNewChip(details.option, field);
        break;
      default:
    }
  };

  const handleInputChange = (_, value) => {
    setSearchTerm(value.trim());
  };

  const allOptions = useMemo(
    () => keywordGroups.concat(availableKeywords),
    [keywordGroups, availableKeywords]
  );

  const renderInput = (params) => (
    <TextField
      {...params}
      size="medium"
      variant="outlined"
      label="search keywords and keyword groups"
    />
  );

  const renderOption = (props, option, { selected }) => (
    <Stack direction="row" alignItems="center" {...props}>
      <Checkbox checked={selected} color="primary" />
      <Typography>{option.title}</Typography>
      {option.type === 'Keyword group' && (
        <Typography color="textSecondary" ml="auto" variant="body2">
          {option.keywords.length}
        </Typography>
      )}
    </Stack>
  );

  const renderTags = (tags, getTagProps, type) =>
    tags.map((tag, index) => {
      const color =
        type !== 'never' && tag.type === 'Keyword' ? 'opacity75' : 'primary';

      const iconColor = type === 'never' ? theme.palette.primary.dark : '#FFF';

      const onClick =
        tag.type !== 'Keyword' ? () => handleClickOpenKeywordGroup(tag) : null;

      const styleNever =
        tag.type === 'Keyword'
          ? theme.palette.action.active
          : theme.palette.primary.dark;

      const variant = type === 'never' ? 'outlined' : 'filled';

      return (
        <Chip
          {...getTagProps({ index })}
          color={color}
          icon={
            tag.type !== 'Keyword' ? (
              <ListIcon style={{ color: iconColor }} />
            ) : undefined
          }
          key={`chip__${tag.title}`}
          label={tag.title}
          onClick={onClick}
          size="small"
          style={{
            borderColor: type === 'never' ? styleNever : 'inherit'
          }}
          variant={variant}
        />
      );
    });

  return (
    <>
      <Box style={{ padding: '8px 16px' }}>
        <Stack marginBottom={showSubtitle ? '32px' : '16px'}>
          <Typography variant="h6">{title}</Typography>
          {showSubtitle && (
            <>
              <Typography variant="body2" mt={1}>
                Please add the keywords and keywords groups that you would like
                to search for.
              </Typography>
              <Typography variant="body2">
                You can separate them by comma, or choose presets from the
                dropdown menu.
              </Typography>
            </>
          )}
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack alignItems="center" direction="row">
              <Typography color="textSecondary">MUST ALL include...</Typography>
              <Tooltip
                placement="right"
                arrow
                title={
                  <InfoTooltip text="All of these must be represented in the content items" />
                }
              >
                <IconButton>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <AutocompleteComponent
              size="medium"
              value={[
                ...values.mustKeywordsAndKeywordsGroup.groups,
                ...values.mustKeywordsAndKeywordsGroup.keywords
              ]}
              options={allOptions}
              freeSolo
              groupBy={(option) => option.type}
              onChange={(event, newValue, reason, details) =>
                handleOnChange(reason, details, 'mustKeywordsAndKeywordsGroup')
              }
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) =>
                option.type === value.type && option.title === value.title
              }
              renderInput={(params) => renderInput(params)}
              renderOption={renderOption}
              renderTags={(tags, getTagProps) =>
                renderTags(tags, getTagProps, 'must')
              }
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack alignItems="center" direction="row">
              <Typography color="textSecondary">MIGHT include...</Typography>
              <Tooltip
                placement="right"
                arrow
                title={
                  <InfoTooltip text="Feeling lucky: show if these are present in the content items" />
                }
              >
                <IconButton>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <AutocompleteComponent
              size="medium"
              value={[
                ...values.mightKeywordsAndKeywordsGroup.groups,
                ...values.mightKeywordsAndKeywordsGroup.keywords
              ]}
              options={allOptions}
              freeSolo
              groupBy={(option) => option.type}
              onChange={(event, newValue, reason, details) =>
                handleOnChange(reason, details, 'mightKeywordsAndKeywordsGroup')
              }
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) =>
                option.type === value.type && option.title === value.title
              }
              renderInput={(params) => renderInput(params)}
              renderOption={renderOption}
              renderTags={(tags, getTagProps) =>
                renderTags(tags, getTagProps, 'might')
              }
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack alignItems="center" direction="row">
              <Typography color="textSecondary">
                SHOULD (1 or more) include...
              </Typography>
              <Tooltip
                placement="right"
                arrow
                title={
                  <InfoTooltip text="At least one of these needs to be present in the content items" />
                }
              >
                <IconButton>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <AutocompleteComponent
              size="medium"
              value={[
                ...values.someKeywordsAndKeywordsGroup.groups,
                ...values.someKeywordsAndKeywordsGroup.keywords
              ]}
              options={allOptions}
              freeSolo
              groupBy={(option) => option.type}
              onChange={(event, newValue, reason, details) =>
                handleOnChange(reason, details, 'someKeywordsAndKeywordsGroup')
              }
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) =>
                option.type === value.type && option.title === value.title
              }
              renderInput={(params) => renderInput(params)}
              renderOption={renderOption}
              renderTags={(tags, getTagProps) =>
                renderTags(tags, getTagProps, 'some')
              }
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack alignItems="center" direction="row">
              <Typography color="textSecondary">MUST NOT include...</Typography>
              <Tooltip
                placement="right"
                arrow
                title={
                  <InfoTooltip
                    text="Content items with these keywords will be excluded from your
                  search"
                  />
                }
              >
                <IconButton>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <AutocompleteComponent
              size="medium"
              value={[
                ...values.noneKeywordsAndKeywordsGroup.groups,
                ...values.noneKeywordsAndKeywordsGroup.keywords
              ]}
              groupBy={(option) => option.type}
              freeSolo
              onChange={(event, newValue, reason, details) =>
                handleOnChange(reason, details, 'noneKeywordsAndKeywordsGroup')
              }
              getOptionLabel={(option) => option.title}
              onInputChange={handleInputChange}
              isOptionEqualToValue={(option, value) =>
                option.type === value.type && option.title === value.title
              }
              options={allOptions}
              renderInput={(params) => renderInput(params)}
              renderOption={renderOption}
              renderTags={(tags, getTagProps) =>
                renderTags(tags, getTagProps, 'never')
              }
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        maxWidth="lg"
        open={keywordGroupDialog.isOpen}
        onClose={handleCloseKeywordGroup}
        dialogContent={
          <List
            style={{ position: 'relative', maxHeight: 300 }}
            subheader={<li />}
          >
            {keywordGroupDialog.group?.keywords?.map((keyword) => (
              <ListItem key={`item-${keyword.id}-${keyword.value}`}>
                <ListItemText primary={keyword.value} />
              </ListItem>
            ))}
          </List>
        }
      />
    </>
  );
};

Step3.propTypes = {
  onChange: PropTypes.func,
  showSubtitle: PropTypes.bool,
  title: PropTypes.string,
  values: PropTypes.shape({
    mustKeywordsAndKeywordsGroup: PropTypes.shape({
      groups: PropTypes.array,
      keywords: PropTypes.array
    }),
    mightKeywordsAndKeywordsGroup: PropTypes.shape({
      groups: PropTypes.array,
      keywords: PropTypes.array
    }),
    noneKeywordsAndKeywordsGroup: PropTypes.shape({
      groups: PropTypes.array,
      keywords: PropTypes.array
    }),
    someKeywordsAndKeywordsGroup: PropTypes.shape({
      groups: PropTypes.array,
      keywords: PropTypes.array
    })
  }),
  isDisabled: PropTypes.bool
};

export default Step3;
