import { useState } from 'react';

import Page from '@/components/Page';
import Button from '@/components/button/Button';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar/Toolbar';
import { DEFAULT_PAGE } from '@/constants/table';
import { useFetchKeywordGroups } from '@/hooks/queries/keywords';
import { useUrlParams } from '@/hooks/urlParams';
import getRoute from '@/utils/getRoute/getRoute';
import Error from '@/views/misc/error';

import CreateKeywordGroups from '../create/CreateKeywordGroups';
import DeleteKeywordGroups from '../delete/DeleteKeywordGroups';
import Results from './_partials/Results';

const defaultParams = {
  search: ''
};

const KeywordGroups = () => {
  const urlParams = useUrlParams(getRoute.keywords.LIST(), defaultParams);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isKeywordsDialogOpen, setKeywordsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    data: keywordGroups = [],
    isLoading,
    isError
  } = useFetchKeywordGroups();

  const handleChangeSearch = ({ target: { value } }) => {
    urlParams.updateParams({
      search: value.replace(/^\s+/g, ''),
      page: DEFAULT_PAGE
    });
  };

  const groupsList = keywordGroups.filter((item) =>
    item.title.toLowerCase().includes(urlParams.params.search.toLowerCase())
  );

  const { search } = urlParams.params;

  if (isError) return <Error />;

  return (
    <>
      <Page
        header={
          <Header
            actions={
              <Button
                onClick={() => {
                  setKeywordsDialogOpen(true);
                }}
                text="add group"
              />
            }
            title="Keyword Groups"
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedGroups.length > 0}
            onClickDelete={() => setIsDeleteDialogOpen(true)}
            onChangeSearch={handleChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={search ? String(search) : ''}
            totalItems={groupsList?.length || 0}
          />
        }
        title="Keyword Groups"
      >
        <Results
          isLoading={isLoading}
          keywordGroupsList={groupsList}
          onSelectGroup={setSelectedGroups}
          selectedGroups={selectedGroups}
        />
      </Page>
      {isKeywordsDialogOpen && (
        <CreateKeywordGroups
          isOpen={isKeywordsDialogOpen}
          onClose={() => setKeywordsDialogOpen(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteKeywordGroups
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          selected={selectedGroups}
          clearSelection={() => {
            setSelectedGroups([]);
          }}
          groupsList={groupsList || []}
        />
      )}
    </>
  );
};

export default KeywordGroups;
