import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import alertSlice from './alertSlice';
import userSlice from './userSlice';

const reducer = combineReducers({
  user: userSlice,
  alert: alertSlice
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;
export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
