import { useContext, useState } from 'react';
import { useParams } from 'react-router';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import Header from '@/components/header';
import Loading from '@/components/loading';
import {
  PostDetailsContext,
  PostDetailsProvider
} from '@/contexts/PostDetailsContext';
import { useDeleteEvaluationPosts } from '@/hooks/queries/evaluations';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute';
import Error from '@/views/misc/error';
import NotFound from '@/views/misc/notFound';

import PostLayout from './PostLayout';

const PostContainer = () => {
  const navigate = useWorkspaceNavigate();
  const queryClient = useQueryClient();
  const { postId, evaluationId, data, isLoading, resetState } =
    useContext(PostDetailsContext);
  const alert = useAlert();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const urlParams = queryString.parse(window.location.search);
  const previousPostId = data?.evaluation?.previousPostId;
  const nextPostId = data?.evaluation?.nextPostId;

  const { mutate: deletePost, isLoading: isDeleting } =
    useDeleteEvaluationPosts(
      (result) => {
        if (result?.status === 200) {
          alert.success('The post was successfully deleted');
          if (previousPostId) {
            navigate(
              getRoute.evaluations.POST(evaluationId, previousPostId, urlParams)
            );
          } else if (nextPostId) {
            navigate(
              getRoute.evaluations.POST(evaluationId, nextPostId, urlParams)
            );
          } else {
            navigate(getRoute.evaluations.DETAIL(evaluationId, urlParams));
          }
          queryClient.invalidateQueries({
            queryKey: ['post'],
            refetchType: 'all'
          });
          queryClient.invalidateQueries({
            queryKey: ['posts'],
            refetchType: 'all'
          });
          queryClient.invalidateQueries({
            queryKey: ['evaluation'],
            refetchType: 'all'
          });
          queryClient.invalidateQueries({
            queryKey: ['evaluations'],
            refetchType: 'all'
          });
        } else if (result?.response?.data?.message) {
          alert.error(result.response.data.message);
        }
        setIsDeleteDialogOpen(false);
      },
      () => {
        alert.error('Something went wrong...');
        setIsDeleteDialogOpen(false);
      }
    );

  if (isLoading) return <Loading />;

  if (!data) return <Error />;

  const handleClickDeletePost = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleClickPreviousPost = () => {
    resetState();
    if (previousPostId) {
      navigate(
        getRoute.evaluations.POST(evaluationId, previousPostId, urlParams)
      );
    }
  };

  const handleClickNextPost = () => {
    resetState();
    if (nextPostId) {
      navigate(getRoute.evaluations.POST(evaluationId, nextPostId, urlParams));
    }
  };

  const handleConfirmDeletePost = () => {
    const payload = { evaluationId, postsIds: [postId] };
    deletePost(payload);
  };

  const showHeader = !Number.isNaN(evaluationId);

  return (
    <>
      <Container maxWidth="xl">
        {showHeader && (
          <Header
            sx={{ pt: 3 }}
            mb
            actions={
              <>
                <Button
                  color="error"
                  onClick={handleClickDeletePost}
                  size="small"
                  text="Delete"
                />
                <Button
                  disabled={!previousPostId}
                  onClick={handleClickPreviousPost}
                  size="small"
                  startIcon={<ArrowBackIcon />}
                  text="Previous"
                />
                <Button
                  disabled={!nextPostId}
                  endIcon={<ArrowForwardIcon />}
                  onClick={handleClickNextPost}
                  size="small"
                  text="Next"
                />
              </>
            }
            isSmallHeader
            onClickReturn={() => {
              queryClient.invalidateQueries({
                queryKey: ['posts'],
                refetchType: 'all'
              });
              navigate(
                getPathWithLocalParams(
                  getRoute.evaluations.DETAIL(evaluationId)
                )
              );
            }}
            showDivider
            title={data.evaluation.title}
          />
        )}
        <PostLayout />
      </Container>
      <Dialog
        title="Delete Post"
        onClose={isDeleting ? undefined : () => setIsDeleteDialogOpen(false)}
        dialogContent={
          <>
            <Typography>Are you sure you want to delete this post?</Typography>
            <Typography>You will not be able to recover it.</Typography>
          </>
        }
        open={isDeleteDialogOpen}
        actions={
          <>
            <Button
              variant="text"
              text="cancel"
              disabled={isDeleting}
              onClick={() => setIsDeleteDialogOpen(false)}
            />
            <Button
              color="error"
              text="delete"
              onClick={handleConfirmDeletePost}
              loading={isDeleting}
            />
          </>
        }
      />
    </>
  );
};

const PostDetails = () => {
  const { evaluationId, postId } = useParams();

  if (!evaluationId || !postId) return <NotFound />;

  return (
    <PostDetailsProvider
      postId={Number(postId)}
      evaluationId={Number(evaluationId)}
      urlParams={queryString.parse(window.location.search)}
    >
      <PostContainer />
    </PostDetailsProvider>
  );
};

export default PostDetails;
