import { Outlet, useLocation } from 'react-router-dom';

import { Box, Typography, Stack } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import Page from '@/components/Page';
import Card from '@/components/card';

const useStyles = makeStyles()(() => ({
  page: {
    height: '100vh',
    backgroundColor: '#191919'
  },
  card: {
    width: '485px',
    padding: '40px 40px'
  },
  logo: {
    marginTop: '100px'
  }
}));

const Login = () => {
  const { classes } = useStyles();
  const location = useLocation();

  const title =
    location.pathname.indexOf('recover') !== -1 ? 'Recover Password' : 'Log in';

  return (
    <Page title={title} className={classes.page}>
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <Card
          className={classes.card}
          shadow
          content={
            <Box>
              <Stack justifyContent="center" alignItems="center" mb="32px">
                <Typography variant="h5">Influencer Monitor</Typography>
                <Typography gutterBottom color="textSecondary">
                  {title}
                </Typography>
              </Stack>
              <Outlet />
            </Box>
          }
        />
      </Stack>
    </Page>
  );
};

export default Login;
