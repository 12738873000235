import { useContext, useMemo, useCallback } from 'react';

import {
  Box,
  Typography,
  Stack,
  Skeleton,
  Badge,
  styled,
  BadgeProps
} from '@mui/material';

import parse from 'html-react-parser';
import { makeStyles } from 'tss-react/mui';

import Accordion from '@/components/accordion';
import Card from '@/components/card';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { Posts } from '@/models/posts';
import { msToPrettyTimestamp, msToSeconds } from '@/utils/datesAndTime';
import { highlightWords, stringHasKeyword } from '@/utils/text';

const Loading = () => (
  <Card
    shadow
    title="Audio transcript"
    content={
      <Box>
        {Array(5).map(() => (
          <Skeleton
            variant="rectangular"
            sx={{ width: '100%', height: 16, mb: 1 }}
            key={new Date().getTime()}
          />
        ))}
      </Box>
    }
  />
);

const useStyles = makeStyles()((theme) => ({
  highlighted: {
    background: theme.palette.grey[100],
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    paddingLeft: 5,
    cursor: 'pointer'
  },
  notHighlighted: {
    cursor: 'pointer'
  }
}));

interface PostTranscriptLineProps {
  line: Posts.TranscriptLine;
}

const PostTranscriptLine = ({ line }: PostTranscriptLineProps) => {
  const {
    selectedKeywords,
    videoPlaybackStart,
    setVideoPlaybackStart,
    setVideoLastClick
  } = useContext(PostDetailsContext);

  const prettyTimestamp = msToPrettyTimestamp(line.timestamp);

  const { classes } = useStyles();

  const handleTimestampClick = useCallback(() => {
    const seconds = msToSeconds(line.timestamp);
    setVideoPlaybackStart(seconds);
    setVideoLastClick(new Date().getTime());
  }, [line]);

  const boxClass = useMemo(
    () =>
      videoPlaybackStart === msToSeconds(line.timestamp)
        ? classes.highlighted
        : classes.notHighlighted,
    [videoPlaybackStart, line.timestamp]
  );

  const boxSx = useMemo(
    () => ({
      opacity: stringHasKeyword(line.content, selectedKeywords) ? 1 : 0.75
    }),
    [line.content, selectedKeywords]
  );

  return (
    <Stack
      key={line.timestamp}
      direction="row"
      alignItems="center"
      gap={2}
      overflow="visible"
      padding={0.25}
      sx={boxSx}
      className={boxClass}
      onClick={handleTimestampClick}
    >
      <Typography
        justifyContent="center"
        color="primary"
        fontSize={12}
        width={35}
        fontWeight={500}
      >
        {prettyTimestamp}
      </Typography>
      <Typography variant="body2" fontSize={12}>
        {parse(line.content)}
      </Typography>
    </Stack>
  );
};

interface PostTrascriptBlockProps {
  transcript?: Posts.TranscriptLine[];
}

const PostTranscriptStack = ({ transcript }: PostTrascriptBlockProps) => (
  <Stack maxHeight={332} overflow={'auto'} gap={0.25}>
    {transcript &&
      transcript.map((line) => (
        <PostTranscriptLine line={line} key={line.timestamp} />
      ))}
  </Stack>
);

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -15,
    top: 12
  }
}));

const keywordMatchCount = (
  transcript: Posts.TranscriptLine[],
  keywords: string[]
) =>
  transcript.filter((line) => stringHasKeyword(line.content, keywords)).length;

interface PostTranscriptBadgeProps {
  mediaItem: Posts.Media;
  selectedKeywords: string[];
  index: number;
}

const PostTranscriptBadge = ({
  mediaItem,
  selectedKeywords,
  index
}: PostTranscriptBadgeProps) => (
  <StyledBadge
    badgeContent={
      mediaItem.transcript
        ? keywordMatchCount(mediaItem.transcript, selectedKeywords)
        : 0
    }
    color="primary"
  >
    {`Item ${index + 1}`}
  </StyledBadge>
);

const filterTranscript = (
  transcript: Posts.TranscriptLine[],
  keywords: string[]
) =>
  transcript.filter((line, index) => {
    const hasKeyword = stringHasKeyword(line.content, keywords);

    if (hasKeyword) return true;

    const prevHasKeyword =
      index > 0 && stringHasKeyword(transcript[index - 1].content, keywords);

    if (prevHasKeyword) return true;

    const nextHasKeyword =
      index < transcript.length - 1 &&
      stringHasKeyword(transcript[index + 1].content, keywords);

    if (nextHasKeyword) return true;

    return false;
  });

interface PostTranscriptBodyProps {
  media: Posts.Media[];
}

const PostTranscriptBody = ({ media }: PostTranscriptBodyProps) => {
  const {
    data,
    isLoading,
    activeIndex,
    selectedKeywords,
    setActiveIndex,
    setVideoPlaybackStart
  } = useContext(PostDetailsContext);

  const handleChange = useCallback(
    (index: number) => {
      setActiveIndex(index);
      setVideoPlaybackStart(0);
    },
    [activeIndex]
  );

  if (isLoading) return <Loading />;

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  if (media.length === 1) {
    return <PostTranscriptStack transcript={media[0].transcript} />;
  }

  return (
    <Stack>
      {media.map((mediaItem, index) => (
        <Accordion
          id={mediaItem.mediaPath}
          title={
            <PostTranscriptBadge
              mediaItem={mediaItem}
              selectedKeywords={selectedKeywords}
              index={index}
            />
          }
          handleChange={() => handleChange(index)}
          isExpanded={index === activeIndex || media.length === 1}
          key={mediaItem.mediaPath}
          condensed
        >
          <PostTranscriptStack transcript={mediaItem.transcript} />
        </Accordion>
      ))}
    </Stack>
  );
};

const PostTranscript = () => {
  const { data, selectedKeywords, keywordList } =
    useContext(PostDetailsContext);

  const modMedia = useMemo(() => {
    if (!data?.media) return [];

    if (selectedKeywords.length === 0) return data.media;

    if (keywordList.length > 0) {
      const filteredMedia = data.media.map((media) => ({
        ...media,
        transcript:
          selectedKeywords.length > 0 && media.transcript
            ? filterTranscript(media.transcript, selectedKeywords)
            : media.transcript
      }));

      if (
        !filteredMedia.some(
          (media) => media.transcript && media.transcript.length > 0
        )
      )
        return data.media;

      return filteredMedia.map((media) => ({
        ...media,
        transcript:
          media.transcript &&
          media.transcript.map((line) => ({
            ...line,
            content: highlightWords(line.content, selectedKeywords)
          }))
      }));
    }

    return data.media;
  }, [selectedKeywords, data, keywordList]);

  const hasAtLeastOneTranscript = useMemo(
    () =>
      modMedia.some(
        (mediaItem) => mediaItem.transcript && mediaItem.transcript.length > 0
      ),
    [modMedia]
  );

  const hasBorder = useMemo(
    () =>
      modMedia?.some(
        (media) =>
          media.transcript &&
          media.transcript.some((item) => item.content.includes('</b>'))
      ),
    [modMedia]
  );

  return hasAtLeastOneTranscript ? (
    <Card
      sx={{ marginBottom: 2 }} // Replacing gap: 2 in PostLayout due to display block
      shadow
      isCollapsible
      title="Audio Transcript"
      hasBorder={hasBorder}
      content={<PostTranscriptBody media={modMedia} />}
    />
  ) : (
    <></>
  );
};

export default PostTranscript;
