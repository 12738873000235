import { Groups } from '@mui/icons-material';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
  Avatar,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { CreatorsAutocomplete, CreatorGroups } from '@/models/creator';
import nFormatter from '@/utils/nFormatter/nFormatter';

import AutocompleteComponent from '../autocomplete/Autocomplete';
import InstagramIcon from '../icons/InstagramIcon';
import TikTokIcon from '../icons/TikTokIcon';
import YoutubeIcon from '../icons/YoutubeIcon';

interface Props {
  value: CreatorsAutocomplete.Creator[] | CreatorGroups.Group[];
  onChange: any;
  creators?: CreatorsAutocomplete.Creator[];
  creatorGroups?: CreatorGroups.Group[];
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

type AutocompleteOption = CreatorsAutocomplete.Creator | CreatorGroups.Group;

const AutocompleteCreators = ({
  style,
  value,
  onChange,
  creators = [],
  creatorGroups = [],
  label = 'Creators',
  disabled = false
}: Props) => (
  <AutocompleteComponent
    disabled={disabled}
    style={style}
    value={value && value}
    isOptionEqualToValue={(
      option: AutocompleteOption,
      valuee: AutocompleteOption
    ) => option.type === valuee.type && option.name === valuee.name}
    options={[...creators, ...creatorGroups]}
    groupBy={
      creators.length > 0 && creatorGroups.length > 0
        ? (option: AutocompleteOption) => option.type
        : undefined
    }
    getOptionLabel={(option: AutocompleteOption) => option.name}
    onChange={onChange}
    renderInput={(params) => (
      <TextField {...params} size="medium" label={label} />
    )}
    renderOption={(
      props: React.HTMLAttributes<HTMLElement>,
      option: AutocompleteOption,
      { selected }: AutocompleteRenderOptionState
    ) => (
      <Stack
        direction="row"
        alignItems="center"
        style={{ margin: '5px 0px' }}
        {...props}
      >
        <Checkbox checked={selected} color="primary" />
        <Typography>{option.name}</Typography>
        {'platforms' in option && option.type === 'Creators' && (
          <Stack
            ml="auto"
            direction="row"
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
          >
            {option.platforms.youtube.length > 0 && (
              <YoutubeIcon color="disabled" />
            )}
            {option.platforms.instagram.length > 0 && (
              <InstagramIcon color="disabled" />
            )}
            {option.platforms.tiktok.length > 0 && (
              <TikTokIcon color="disabled" />
            )}
          </Stack>
        )}
        {'totalItems' in option && option.type === 'Groups' && (
          <Typography color="textSecondary" ml="auto">
            {nFormatter(option.totalItems)}
          </Typography>
        )}
      </Stack>
    )}
    renderTags={(
      tags: AutocompleteOption[],
      getTagProps: AutocompleteRenderGetTagProps
    ) =>
      tags.map((tag: AutocompleteOption, index: number) => {
        const color = tag.type === 'Creators' ? 'primary' : 'secondary';

        if (
          'avatarUrl' in tag &&
          tag.type === 'Creators' &&
          tag.avatarUrl != null
        ) {
          return (
            <Chip
              {...getTagProps({ index })}
              color={color}
              avatar={<Avatar alt={tag.name} src={tag.avatarUrl} />}
              key={`chip__${tag.name}`}
              label={tag.name}
              size="small"
            />
          );
        }

        return (
          <Chip
            {...getTagProps({ index })}
            color={color}
            data-testid={`chip__${tag.name}`}
            avatar={
              tag.type === 'Creators' ? (
                <Avatar>{tag.name.split('')[0]}</Avatar>
              ) : undefined
            }
            icon={
              tag.type === 'Groups' ? (
                <Groups style={{ marginLeft: 6 }} />
              ) : undefined
            }
            key={`chip__${tag.name}`}
            label={tag.name}
            size="small"
          />
        );
      })
    }
  />
);

export default AutocompleteCreators;
