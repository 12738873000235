import { useState, useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';

import { ErrorOutline, Group, Person, WarningAmber } from '@mui/icons-material';
import {
  Box,
  Typography,
  Stack,
  Chip,
  ChipProps,
  useTheme,
  AlertColor,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {
  GridRowSelectionModel,
  GridSortModel,
  GridSortDirection,
  getGridSingleSelectOperators
} from '@mui/x-data-grid';

import Page from '@/components/Page';
import Alert from '@/components/alert';
import Avatar from '@/components/avatar/Avatar';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable/DataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar/Toolbar';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE } from '@/constants/table';
import { useFetchCreators } from '@/hooks/queries/creators';
import { useGetScraperStatus } from '@/hooks/queries/scraper';
import { useUrlParams } from '@/hooks/urlParams';
import { useDebounce } from '@/hooks/useDebounce';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Creator } from '@/models/creator';
import { URLParams } from '@/models/misc';
import { Scraper } from '@/models/scraper';
import getRoute from '@/utils/getRoute/getRoute';
import nFormatter from '@/utils/nFormatter/nFormatter';
import Error from '@/views/misc/error';

import CreateCreator from '../create/CreateCreator';
import DeleteCreators from '../delete/DeleteCreators';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  name: undefined,
  sort: undefined,
  order: undefined,
  platform: undefined,
  status: undefined
};

const getInitialFilterModelItems = (params: any) => {
  const { platform } = params;

  const items = [];

  if (platform !== undefined)
    items.push({
      field: 'platform',
      id: items.length,
      operator: 'is',
      value: platform
    });

  return items;
};

const CreatorsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<Person color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Creators"
    text="Add creators to analyse their content when creating evaluations."
  />
);

const getStatus = (accountStatus: Array<Creator.PlatformAccount['status']>) => {
  if (accountStatus.includes('missing') || accountStatus.includes('private')) {
    return {
      color: 'error',
      icon: <ErrorOutline fontSize="inherit" />
    };
  }
  if (accountStatus.includes('pending')) {
    return {
      color: 'warning',
      icon: <WarningAmber fontSize="inherit" />
    };
  }
  return {
    color: 'default',
    icon: undefined
  };
};

const CreatorsList = () => {
  const theme = useTheme();
  const location = useLocation();

  const urlParams = useUrlParams(getRoute.creators.LIST(), defaultParams);

  const deferredCreatorsValue = useDebounce(urlParams.params.name, 500);
  const navigate = useWorkspaceNavigate();

  const [isOpenCreateCreatorDialog, setIsOpenCreateCreatorDialog] =
    useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [selectedCreators, setSelectedCreators] =
    useState<GridRowSelectionModel>([]);

  const {
    data: creators,
    isLoading,
    isError
  } = useFetchCreators({
    params: {
      ...urlParams.params,
      name: deferredCreatorsValue ? String(deferredCreatorsValue) : undefined
    }
  });

  const { data: scraperStatus } = useGetScraperStatus();

  const initialFilterModel = useRef({
    items: getInitialFilterModelItems(urlParams.params)
  });

  const handleChangePageSize = useCallback(
    (newSize: string) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const handleChangePage = useCallback(
    (newPage: string) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const handleSortModelChange = useCallback(
    (params: GridSortModel) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({
        sort: newSort,
        order: (newOrder?.toUpperCase() as URLParams['order']) || undefined
      });
    },
    [urlParams]
  );

  const handleChangeSearch = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      urlParams.updateParams({
        name: value.replace(/^\s+/g, ''),
        page: DEFAULT_PAGE
      });
    },
    [urlParams]
  );

  const columns = useMemo(
    () => [
      {
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        field: 's3AvatarPath',
        headerName: 'Avatar',
        renderCell: (data: any) => {
          const { name, avatarUrl } = data.row;

          return (
            <Box paddingLeft={1}>
              <Avatar src={avatarUrl} name={name} width="40px" fontSize={16} />
            </Box>
          );
        }
      },
      {
        filterable: false,
        disableColumnMenu: true,
        field: 'name',
        sortable: false,
        headerName: 'Name',
        minWidth: 200,
        flex: 1,
        renderCell: (data: any) => {
          const { name } = data.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </Typography>
          );
        }
      },
      {
        field: 'platform',
        headerName: 'Platform',
        sortable: false,
        type: 'singleSelect',
        valueOptions: [
          { value: 'instagram', label: 'Instagram' },
          { value: 'tiktok', label: 'TikTok' },
          { value: 'youtube', label: 'YouTube' }
        ],
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'is'
        ),
        minWidth: 150,
        flex: 1,
        renderCell: (data: any) => {
          const { platforms } = data.row;

          return (
            <Stack direction="row" gap={1}>
              {Object.entries(platforms).map(
                ([platform, accountStatus]) =>
                  (accountStatus as Array<Creator.PlatformAccount['status']>)
                    .length > 0 && (
                    <Chip
                      key={platform.charAt(0).toUpperCase() + platform.slice(1)}
                      label={
                        platform.charAt(0).toUpperCase() + platform.slice(1)
                      }
                      icon={
                        getStatus(
                          accountStatus as Array<
                            Creator.PlatformAccount['status']
                          >
                        ).icon
                      }
                      color={
                        getStatus(
                          accountStatus as Array<
                            Creator.PlatformAccount['status']
                          >
                        ).color as ChipProps['color']
                      }
                    />
                  )
              )}
            </Stack>
          );
        }
      },
      {
        filterable: false,
        disableColumnMenu: true,
        field: 'contentCount',
        headerName: 'Content Count',
        minWidth: 100,
        flex: 1,
        renderCell: (data: any) => {
          const { contentCount } = data.row;

          return <Typography>{nFormatter(contentCount)}</Typography>;
        }
      }
    ],
    [creators?.results]
  );

  const onFilterModelChange = useCallback(
    (params: any) => {
      const newParams: any = {};

      params.items.forEach((item: any) => {
        const { field, value } = item;
        if (field === 'platform') {
          newParams.platform = value;
        }
      });

      const isAnyFilterActive = params.items.length > 0;

      // If no filters are active, clear all parameters
      if (!isAnyFilterActive) {
        urlParams.updateParams(defaultParams);
        return;
      }

      urlParams.updateParams({
        ...urlParams.paramsInTheUrl,
        ...newParams,
        page: DEFAULT_PAGE
      });
    },
    [urlParams]
  );

  let emptyStateCreators = CreatorsOnBoardingOverlay;
  if (urlParams.params.name !== undefined)
    emptyStateCreators = () => <NoRowsOverlay />;

  const { sort, order, page, size, name, status } = urlParams.params;

  const alertDetails = useCallback((): {
    severity: AlertColor | undefined;
    message: string;
  } => {
    if (scraperStatus) {
      const platformsWithError = Object.entries<
        Scraper.Status[Scraper.Platform]
      >(scraperStatus).filter(
        ([_, platform]) => (platform.missingPrivateProfiles ?? 0) > 0
      );

      if (platformsWithError.length > 0) {
        return {
          severity: 'error',
          message: 'Some accounts are not fully functional.'
        };
      }

      const platformsWithWarning = Object.entries<
        Scraper.Status[Scraper.Platform]
      >(scraperStatus).filter(
        ([_, platform]) =>
          (platform.pendingProfiles ?? 0) > 0 &&
          (platform.missingPrivateProfiles ?? 0) === 0
      );

      if (platformsWithWarning.length > 0) {
        return {
          severity: 'warning',
          message: 'Some accounts are still pending.'
        };
      }
    }
    return { severity: undefined, message: '' };
  }, [scraperStatus]);

  const isAlertOpen = alertDetails().message !== '';

  if (isError) return <Error />;

  return (
    <>
      <Page
        header={
          <Header
            title="Creators"
            actions={
              <>
                <ToggleButtonGroup value="creators" size="small">
                  <ToggleButton
                    value="creators"
                    onClick={() => navigate('/creators')}
                    style={{
                      color: !location.pathname.includes('creator-groups') // FIXME: remove this when action theme colors are fixed (default theme)
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                    }}
                  >
                    <Person />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => navigate('/creator-groups')}
                    value="creatorGroups"
                    style={{
                      color: location.pathname.includes('creator-groups') // FIXME: remove this when action theme colors are fixed (default theme)
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                    }}
                  >
                    <Group />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  text="add creator"
                  onClick={() => setIsOpenCreateCreatorDialog(true)}
                />
              </>
            }
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedCreators.length > 0}
            onClickDelete={() => setIsOpenDeleteDialog(true)}
            onChangeSearch={handleChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={name ? String(name) : ''}
            totalItems={creators?.totalItems || 0}
            sx={isAlertOpen ? { marginBottom: '0px' } : {}}
          />
        }
        title="Creators"
      >
        <>
          {isAlertOpen && (
            <Alert
              severity={alertDetails().severity}
              content={alertDetails().message}
              action={
                <Button
                  sx={{
                    backgroundColor: !status
                      ? 'transparent'
                      : theme.palette.action.selected,
                    border: status ? '1px solid transparent' : undefined
                  }}
                  text="Filter"
                  color="inherit"
                  variant="outlined"
                  onClick={() => {
                    if (!status) {
                      urlParams.updateParams({
                        ...urlParams.paramsInTheUrl,
                        status: 'missing,private,pending',
                        page: DEFAULT_PAGE
                      });
                      return;
                    }
                    urlParams.updateParams({
                      ...urlParams.paramsInTheUrl,
                      status: undefined
                    });
                  }}
                />
              }
            />
          )}
          <DataTable
            getRowId={(row) => row.id}
            columns={columns}
            rowHeight={80}
            rows={creators?.results || []}
            rowCount={creators?.totalItems}
            checkboxSelection
            keepNonExistentRowsSelected
            loading={isLoading}
            sortingMode="server"
            filterMode="server"
            paginationMode="server"
            onRowClick={(row) => navigate(getRoute.creators.DETAIL(row.id))}
            onChangePageSize={handleChangePageSize}
            onChangePage={handleChangePage}
            onSortModelChange={handleSortModelChange}
            initialFilterModel={initialFilterModel.current}
            onFilterModelChange={onFilterModelChange}
            sort={sort}
            order={order?.toLowerCase() as GridSortDirection}
            page={page}
            size={size}
            rowSelectionModel={selectedCreators}
            onRowSelectionModelChange={setSelectedCreators}
            noRowsOverlay={emptyStateCreators}
          />
          {isOpenCreateCreatorDialog && (
            <CreateCreator
              isOpen={isOpenCreateCreatorDialog}
              onClose={() => {
                setIsOpenCreateCreatorDialog(false);
              }}
            />
          )}
          {isOpenDeleteDialog && (
            <DeleteCreators
              isOpen={isOpenDeleteDialog}
              onClose={() => {
                setIsOpenDeleteDialog(false);
              }}
              selected={selectedCreators}
              clearSelection={() => {
                setSelectedCreators([]);
              }}
              creatorsList={creators?.results || []}
            />
          )}
        </>
      </Page>
    </>
  );
};

export default CreatorsList;
