import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createCompliance,
  deleteCompliance,
  getPost,
  getPosts,
  updateCompliance
} from '@/api/posts';
import { Posts } from '@/models/posts';

import { Callback } from '.';

type PostsProps = {
  params: Posts.ListParams;
  onSuccess?: (_data: Posts.List) => void;
  onError?: (_error: unknown) => void;
  enabled?: boolean;
  cacheTime?: number;
};

export const useFetchPosts = ({
  params,
  onSuccess,
  onError,
  enabled,
  cacheTime
}: PostsProps) =>
  useQuery({
    queryKey: ['posts', params],
    queryFn: async () => {
      const result = await getPosts(params);
      return result.data;
    },
    enabled,
    onSuccess,
    onError,
    cacheTime
  });

export const useFetchPost = (
  id: number,
  params: Posts.DetailParams,
  onSuccess?: (_data: Posts.Details) => void,
  onError?: (_error: unknown) => void
) =>
  useQuery({
    queryKey: ['post', id, params],
    queryFn: async () => {
      const { data } = await getPost(id, params);
      return data;
    },
    onSuccess,
    onError
  });

export const useCreateCompliance = (onSuccess: Callback, onError: Callback) =>
  useMutation({ mutationFn: createCompliance, onSuccess, onError });

export const useUpdateCompliance = (onSuccess: Callback, onError: Callback) =>
  useMutation({ mutationFn: updateCompliance, onSuccess, onError });

export const useDeleteCompliance = (
  onSuccess: Callback,
  onError: Callback,
  onSettled: Callback
) =>
  useMutation({ mutationFn: deleteCompliance, onSuccess, onError, onSettled });
