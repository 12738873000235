import { SvgIcon, SvgIconProps } from '@mui/material';

const InfluencerMonitorIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="500"
    viewBox="0 0 257 184"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.6309 135.259C92.919 135.073 103.521 124.416 103.521 111.436H112.744C112.744 129.59 97.9589 144.225 79.76 144.48L79.6309 135.259ZM171.769 135.259C158.481 135.073 147.879 124.416 147.879 111.436H138.656C138.656 129.59 153.441 144.225 171.64 144.48L171.769 135.259Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.819 109.901C137.704 109.901 147.339 100.266 147.339 88.3816C147.339 76.4969 137.704 66.8625 125.819 66.8625C113.934 66.8625 104.299 76.4969 104.299 88.3816C104.299 100.266 113.934 109.901 125.819 109.901ZM125.819 121.429C144.071 121.429 158.867 106.633 158.867 88.3816C158.867 70.1302 144.071 55.3345 125.819 55.3345C107.567 55.3345 92.7705 70.1302 92.7705 88.3816C92.7705 106.633 107.567 121.429 125.819 121.429Z"
      fill="white"
    />
    <path
      d="M137.49 88.3837C137.49 82.0169 132.328 76.8556 125.961 76.8556C119.594 76.8556 114.433 82.0169 114.433 88.3837C114.433 94.7505 119.594 99.9117 125.961 99.9117C132.328 99.9117 137.49 94.7505 137.49 88.3837Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.891 91.9039C199.766 58.7815 164.591 39.1947 126.607 39.1947C88.6235 39.1947 53.4479 58.7815 27.3228 91.9039C53.448 125.026 88.6235 144.613 126.607 144.613C164.591 144.613 199.766 125.026 225.891 91.9039ZM12.9124 91.9039C41.1155 131.39 81.6121 156.141 126.607 156.141C171.602 156.141 212.099 131.39 240.302 91.9039C212.099 52.4181 171.602 27.6666 126.607 27.6666C81.612 27.6666 41.1155 52.4181 12.9124 91.9039Z"
      fill="white"
    />
    <path
      d="M8.26219 107.457C64.7307 184.581 178.975 198.99 240.165 113.643C177.247 214.573 68.1066 196.869 0.614075 120.143C0.570099 120.093 0.562958 120.02 0.596893 119.963L8.00493 107.468C8.06123 107.374 8.19698 107.368 8.26219 107.457Z"
      fill="white"
    />
    <path
      d="M249.599 76.3238C193.268 -0.79005 78.9117 -15.35 17.8777 69.9933C80.6397 -30.9385 189.212 -13.3502 256.536 63.3796C256.578 63.4274 256.586 63.4965 256.557 63.5528L249.86 76.3057C249.807 76.4066 249.666 76.4158 249.599 76.3238Z"
      fill="white"
    />
  </SvgIcon>
);

export default InfluencerMonitorIcon;
