import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCPIXddNE03PCLgW8o8qb5iuVMftkdJmw0',
  authDomain: 'src-influencer.firebaseapp.com',
  projectId: 'src-influencer',
  storageBucket: 'src-influencer.appspot.com',
  messagingSenderId: '57376425003',
  appId: '1:57376425003:web:4b5328627553069ae6166e',
  measurementId: 'G-ECX45ZP00Q'
};

try {
  // Initialize Firebase only if it hasn't been initialized yet
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
} catch (err: any) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}
export default firebase;
