import { InfoOutlined } from '@mui/icons-material';
import { Stack, AlertTitle, Typography } from '@mui/material';

interface TooltipProps {
  icon: boolean;
  title?: string;
  text: string;
}

const InfoTooltip = ({ icon, title, text }: TooltipProps) => (
  <Stack gap={1.25} direction="row" alignItems="flex-start" marginRight={2}>
    {icon && (
      <InfoOutlined data-testid="tooltipIcon" sx={{ margin: '7px 0' }} />
    )}
    <Stack gap={0.5} sx={{ margin: '8px 0' }}>
      {title && <AlertTitle data-testid="tooltipTitle">{title}</AlertTitle>}
      <Typography data-testid="tooltipText" color="inherit" variant="body2">
        {text}
      </Typography>
    </Stack>
  </Stack>
);

export default InfoTooltip;
