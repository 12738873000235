import { useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import {
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  Chip,
  InputAdornment
} from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { createKeywordGroup, createKeywords } from '@/api/keywords';
import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { KEYWORD_GROUP_REGEX } from '@/constants/regex';
import useAlert from '@/hooks/useAlert';
import getUniqueKeywordsAndError from '@/utils/keywords/keywords';
import { validateString } from '@/utils/text';

const initialErrors = {
  invalidGroupName: '',
  duplicatedKeywords: ''
};

const isGroupValid = (groupTitle) =>
  validateString(groupTitle, KEYWORD_GROUP_REGEX, 2);

const CreateKeywordGroups = ({ isOpen, onClose }) => {
  const alert = useAlert();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [groupName, setGroupName] = useState('');
  const [keywordsInput, setKeywordsInput] = useState('');
  const [newKeywordsList, setNewKeywordsListList] = useState([]);
  const [error, setError] = useState(initialErrors);

  const handleChangeGroupName = ({ target: { value } }) => {
    setGroupName(value);

    const isValid = isGroupValid(value);
    const isNotEmpty = value !== '';

    if (!isValid && isNotEmpty) {
      if (error.invalidGroupName === '')
        setError({
          ...error,
          invalidGroupName:
            'The title cannot contain special characters and must be at least 2 characters long'
        });
    } else if (error.invalidGroupName !== '') {
      setError({ ...error, invalidGroupName: '' });
    }
  };

  const handleAddKeywords = () => {
    if (keywordsInput === '') return;

    const { keywordsArray, keywordsError } = getUniqueKeywordsAndError(
      keywordsInput,
      newKeywordsList
    );

    if (error !== keywordsError)
      setError({ ...error, duplicatedKeywords: keywordsError });
    setNewKeywordsListList(keywordsArray);
    setKeywordsInput('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') handleAddKeywords();
  };

  const handleDeleteChip = (keyword) => {
    const newKeywordsListArray = newKeywordsList.slice();
    setNewKeywordsListList(newKeywordsListArray.filter((e) => e !== keyword));
  };

  const handleSaveGroup = () => {
    createKeywordGroup({ title: groupName })
      .then((res) => {
        // Error 409 (keyword already exists) does not go into the catch block
        if (res.code === 'ERR_BAD_REQUEST') {
          throw new Error(res.response.data.error);
        }
        if (newKeywordsList.length > 0) {
          createKeywords({
            keywords: newKeywordsList,
            categoryId: res.data?.id
          }).then(() => {
            queryClient.invalidateQueries({
              queryKey: ['keywordGroups'],
              refetchType: 'all'
            });
          });
        } else
          queryClient.invalidateQueries({
            queryKey: ['keywordGroups'],
            refetchType: 'all'
          });

        alert.success(`"${res.data?.title}" successfully created`);
        onClose();
      })
      .catch((data) => {
        alert.error(data.toString());
      });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      title="Create a new group"
      open={isOpen}
      onClose={onClose}
      dialogContent={
        <>
          <Stack gap={2} marginTop={1}>
            <TextField
              fullWidth
              label="Group title"
              value={groupName}
              onChange={handleChangeGroupName}
              error={Boolean(error.invalidGroupName)}
              helperText={error.invalidGroupName}
            />
            <TextField
              fullWidth
              label="Add keywords"
              error={Boolean(error.duplicatedKeywords)}
              helperText={
                error.duplicatedKeywords ||
                'Add keywords separated by comma with at least 2 characters'
              }
              onKeyDown={(event) => handleKeyDown(event)}
              value={keywordsInput}
              onChange={(e) => setKeywordsInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddKeywords}>
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Stack>
              <Typography>Included keywords:</Typography>
              <Stack
                sx={{ overflowY: 'auto' }}
                height={150}
                border={`1px solid ${theme.palette.grey[400]}`}
                borderRadius="4px"
                direction="row"
                gap={1}
                padding={1}
                flexWrap="wrap"
              >
                {newKeywordsList?.map((keyword) => (
                  <Chip
                    onDelete={() => handleDeleteChip(keyword)}
                    sx={{
                      backgroundColor: theme.palette.primary.light
                    }}
                    key={keyword}
                    label={keyword}
                    color="primary"
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </>
      }
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleSaveGroup}
            text="save"
            disabled={groupName === '' || !isGroupValid(groupName)}
          />
        </>
      }
    />
  );
};

CreateKeywordGroups.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default CreateKeywordGroups;
