import { ReactNode } from 'react';

import { ChevronRight } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Typography,
  Link,
  Breadcrumbs,
  IconButton,
  Divider,
  Stack
} from '@mui/material';

interface Props {
  actions?: JSX.Element;
  breadcrumbsList?: Array<{
    name: string;
    onClick: () => void;
  }>;
  isSmallHeader?: boolean;
  onClickReturn?: () => void;
  showDivider?: boolean;
  title: ReactNode;
  sx?: object;
  mb?: boolean;
}

const Header = ({
  actions,
  breadcrumbsList = [],
  isSmallHeader = false,
  onClickReturn,
  showDivider = false,
  title,
  mb = false,
  sx
}: Props) => {
  const returnButtonSize = isSmallHeader ? 'small' : 'medium';
  const titleSize = isSmallHeader ? 'h5' : 'h4';

  return (
    <Stack sx={sx} gap={1} mb={mb ? 2 : 0} data-testid="header">
      {breadcrumbsList.length > 0 && (
        <Breadcrumbs
          aria-label="breadcrumb"
          data-testid="header-breadcrumbs"
          separator={<ChevronRight fontSize="small" />}
        >
          {breadcrumbsList.map((item, index) => (
            <Link
              color="inherit"
              component="button"
              key={index}
              onClick={item.onClick}
            >
              {item.name}
            </Link>
          ))}
          <Typography color="textPrimary" maxWidth={'200px'} noWrap>
            {title}
          </Typography>
        </Breadcrumbs>
      )}
      <Stack gap={1} direction="row" alignItems="center">
        {onClickReturn && (
          <IconButton
            color="inherit"
            data-testid="header-return-btn"
            onClick={onClickReturn}
            size={returnButtonSize}
          >
            <ArrowBackIosNewIcon fontSize={returnButtonSize} />
          </IconButton>
        )}
        {title && (
          <Typography
            data-testid="header-title"
            noWrap
            variant={titleSize}
            onClick={onClickReturn && onClickReturn}
            sx={{ cursor: onClickReturn ? 'pointer' : 'auto' }}
          >
            {title}
          </Typography>
        )}
        {actions && (
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            flexShrink="0"
            data-testid="header-actions"
            ml="auto"
          >
            {actions}
          </Stack>
        )}
      </Stack>
      {showDivider && <Divider data-testid="header-divider" />}
    </Stack>
  );
};

export default Header;
