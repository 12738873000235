import { Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  titleTypography: { fontWeight: 700 }
}));

const BooleanColumn = ({ value }) => {
  const { classes } = useStyles();

  if (value === null) return <></>;

  const variant = value ? 'body2' : 'caption';
  const color = value ? 'primary' : 'error';
  const text = value ? 'YES' : 'NO';

  return (
    <Typography
      className={classes.titleTypography}
      variant={variant}
      color={color}
    >
      {text}
    </Typography>
  );
};

BooleanColumn.propTypes = {
  value: PropTypes.bool.isRequired
};

export default BooleanColumn;
