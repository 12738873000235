import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { Chip, Typography } from '@mui/material';

import DataTable from '@/components/dataTable';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useUrlParams } from '@/hooks/urlParams';
import { Creator } from '@/models/creator';
import { Platform } from '@/models/posts';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute/getRoute';
import BooleanColumn from '@/views/evaluations/detail/body/_partials/BooleanColumn';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE
};

const platformMapper = {
  'instagram-post': 'IG: Post',
  'instagram-reel': 'IG: Reel',
  'instagram-story': 'IG: Story',
  tiktok: 'TT: Video',
  'youtube-short': 'YT: Short',
  'youtube-video': 'YT: Video'
};

interface Props {
  items: Creator.Post[];
  isLoading: boolean;
  totalItems: number;
}

const Results = ({ items, isLoading, totalItems }: Props) => {
  const { id } = useParams();
  const urlParams = useUrlParams(
    getRoute.creators.DETAIL(Number(id), defaultParams),
    defaultParams
  );

  const onChangePageSize = useCallback(
    (newSize: string) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const onChangePage = useCallback(
    (newPage: string) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const { page, size } = urlParams.params;

  const columns = useMemo(
    () => [
      // {
      //   field: 'previewUrl',
      //   headerName: 'Preview',
      //   width: 160,
      //   flex: 1,
      //   renderCell: (params) =>
      //     params.row.previewUrl && (
      //       <Box paddingLeft={1}>
      //         <img
      //           height="55px"
      //           width="93.5px"
      //           src={`${params.row.previewUrl}`}
      //         />
      //       </Box>
      //     )
      // },
      // {
      //   field: 'title',
      //   headerName: 'Title',
      //   width: 160,
      //   renderCell: (params) => (
      //     <Typography
      //       style={{
      //         // Need to break the word and put ellipsis
      //         // maxWidth: '100px',
      //         maxHeight: '50px',
      //         whiteSpace: 'normal',
      //         textOverflow: 'ellipsis',
      //         // wordBreak: 'break-word',
      //         overflow: 'hidden'
      //       }}
      //     >
      //       {params.row.title}
      //     </Typography>
      //   )
      // },
      {
        sortable: false,
        field: 'username',
        headerName: 'Account',
        minWidth: 150,
        flex: 1,
        renderCell: (params: any) => (
          <Typography>{params.row.username}</Typography>
        )
      },
      {
        sortable: false,
        field: 'platform',
        headerName: 'Platform',
        minWidth: 150,
        flex: 1,
        renderCell: (params: any) => (
          <Chip label={platformMapper[params.row.platform as Platform]} />
        )
      },
      {
        sortable: false,
        field: 'uploadDate',
        headerName: 'Publication Date',
        minWidth: 150,
        flex: 1,
        renderCell: (params: any) => (
          <Typography variant="body2">
            {getPrettyDate(params.row.uploadDate)}
          </Typography>
        )
      },
      {
        sortable: false,
        field: 'platformDisclosure',
        headerName: 'Sponsored',
        minWidth: 150,
        flex: 1,
        renderCell: (params: any) => (
          <BooleanColumn value={params.row.platformDisclosure.length > 0} />
        )
      }
    ],
    [items]
  );

  return (
    <DataTable
      rowHeight={88}
      rows={items}
      rowCount={totalItems}
      columns={columns}
      loading={isLoading}
      checkboxSelection={false}
      paginationMode="server"
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      page={page}
      size={size}
      disableColumnMenu
    />
  );
};

export default Results;
