import { useEffect, useRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { makeStyles } from 'tss-react/mui';

interface VideoProps {
  id?: string;
  lastClick?: string | number;
  onReady?: () => void;
  onPlay?: () => void;
  onProgress?: ReactPlayerProps['onProgress'];
  platform?: string;
  playing?: boolean;
  seek?: number;
  url: string;
}

const useStyles = makeStyles()(() => ({
  youtubeWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  defaultWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0
  }
}));

const VideoPlayer = ({
  id,
  lastClick = new Date().toTimeString(),
  onReady,
  onPlay,
  onProgress,
  platform,
  playing = false,
  seek,
  url
}: VideoProps) => {
  const videoRef = useRef<ReactPlayer | null>(null);
  const styles = useStyles();

  useEffect(() => {
    videoRef.current?.seekTo(seek || 0);
  }, [lastClick]);

  if (platform === 'youtube-video')
    return (
      <div className={styles.classes.youtubeWrapper} id={id}>
        <ReactPlayer
          onReady={onReady}
          onPlay={onPlay}
          onProgress={onProgress}
          progressInterval={10}
          className={styles.classes.player}
          playing={playing}
          controls={true}
          url={url}
          ref={videoRef}
          width="100%"
          height="100%"
        />
      </div>
    );

  return (
    <div className={styles.classes.defaultWrapper} id={id}>
      <ReactPlayer
        onReady={onReady}
        onPlay={onPlay}
        onProgress={onProgress}
        progressInterval={10}
        playing={playing}
        controls={true}
        url={url}
        ref={videoRef}
        height="600px"
        width="100%"
      />
    </div>
  );
};

export default VideoPlayer;
