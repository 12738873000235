import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AutocompleteComponent from '@/components/autocomplete/Autocomplete';
import Avatar from '@/components/avatar/Avatar';
import Button from '@/components/button';
import Dialog from '@/components/dialog/Dialog';
import InstagramIcon from '@/components/icons/InstagramIcon';
import TikTokIcon from '@/components/icons/TikTokIcon';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import { useFetchCreatorAvatars } from '@/hooks/queries/creators';

const INITIAL_ERRORS = {
  youtube: '',
  instagram: '',
  tikTok: ''
};

const initialAccounts = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const newAcc = acc;
    newAcc[key] = value.map((item) =>
      item.username?.startsWith('@') ? item.username : `@${item.username}`
    );
    return newAcc;
  }, {});

const CREATOR_INITIALS_OPTION = 'creatorInitials';

const EditCreator = ({
  creator,
  isOpen,
  onSubmit,
  onClose,
  onClickDelete,
  isUpdating
}) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [form, setForm] = useState({
    avatar: '',
    name: creator.name,
    accounts: initialAccounts(creator.accounts)
  });

  const [errors, setErrors] = useState(INITIAL_ERRORS);

  const { data: avatarAccounts = {} } = useFetchCreatorAvatars({
    id: Number(id)
  });

  useEffect(() => {
    if (avatarAccounts === undefined) return;

    const selectedAccount = Object.entries(avatarAccounts)
      .flatMap(([platform, accs]) =>
        accs.map((account) => ({ ...account, platform }))
      )
      .find((account) => account.selected);

    setForm({
      ...form,
      avatar: selectedAccount
        ? `${selectedAccount.platform}-${selectedAccount.username}`
        : CREATOR_INITIALS_OPTION
    });
  }, [avatarAccounts]);

  const isAccountValid = (account, platform) => {
    if (
      platform === 'youtube' &&
      !(account.startsWith('@') || account.startsWith('UC'))
    ) {
      return false;
    }
    if (platform !== 'youtube' && !account.startsWith('@')) {
      return false;
    }
    return account.length >= 3;
  };

  const handleChangeName = ({ target: { value } }) => {
    setForm({ ...form, name: value });
  };

  const handleChangeAccount = (reason, details, field) => {
    switch (reason) {
      case 'createOption':
        {
          const value = details.option;

          if (!isAccountValid(value, field)) {
            setErrors({
              ...errors,
              [field]:
                field === 'youtube'
                  ? `Account must start with '@' or 'UC' and be at least 3 characters long`
                  : `Account must start with '@' and be at least 3 characters long`
            });
            return;
          }

          if (!form.accounts[field].includes(value)) {
            const newAccounts = {
              ...form.accounts,
              [field]: [...form.accounts[field], value]
            };
            setForm({ ...form, accounts: newAccounts });
            setErrors(INITIAL_ERRORS);
          } else setErrors({ ...errors, [field]: `${value} already exists` });
        }
        break;
      case 'removeOption':
        {
          const index = form.accounts[field].findIndex(
            (i) => i === details.option
          );

          const copyArray = form.accounts[field];
          if (index > -1) copyArray.splice(index, 1);

          const newAccounts = { ...form.accounts, [field]: copyArray };
          const removedAvatarAccount =
            form.avatar === `${field}-${details.option}`;

          // remove from queryClient avatars cache the removed option
          queryClient.setQueryData(['creatorAvatars', Number(id)], (data) => {
            const platformObject = data[field].filter(
              (item) => item.username !== details.option
            );
            return { ...data, [field]: platformObject };
          });

          setForm({
            ...form,
            accounts: newAccounts,
            avatar: removedAvatarAccount ? CREATOR_INITIALS_OPTION : form.avatar
          });
          setErrors(INITIAL_ERRORS);
        }
        break;
      case 'clear':
        {
          const newAccounts = { ...form.accounts, [field]: [] };
          setForm({ ...form, accounts: newAccounts });
          setErrors(INITIAL_ERRORS);
        }
        break;
      default:
    }
  };

  const handleClickSubmit = () => {
    let newAvatarAccount = null;

    if (form.avatar !== CREATOR_INITIALS_OPTION) {
      const [platform, username] = form.avatar.split('-');
      newAvatarAccount = { platform, username };
    }

    const modifiedAccounts = {
      youtube: form.accounts.youtube,
      instagram: form.accounts.instagram.map((account) =>
        account.startsWith('@') ? account.slice(1) : account
      ),
      tiktok: form.accounts.tiktok.map((account) =>
        account.startsWith('@') ? account.slice(1) : account
      )
    };

    onSubmit({
      name: form.name,
      accounts: modifiedAccounts,
      avatarAccount: newAvatarAccount
    });
  };

  const avatarOptions = useMemo(
    () =>
      Object.entries(avatarAccounts).flatMap(([platform, acc]) =>
        acc
          .filter((account) => account.avatarUrl)
          .map((account) => ({ platform, ...account }))
      ),
    [avatarAccounts]
  );

  const avatarSrc = useMemo(() => {
    if (form.avatar !== '' && form.avatar !== CREATOR_INITIALS_OPTION) {
      const [platform, username] = form.avatar.split('-');
      const selectedOption = avatarOptions.find(
        (option) => option.platform === platform && option.username === username
      );

      if (selectedOption) return selectedOption.avatarUrl;
    }

    return null;
  }, [avatarOptions, form.avatar]);

  return (
    <Dialog
      open={isOpen}
      title="Edit Creator"
      fullWidth
      maxWidth="sm"
      dialogContent={
        <Stack gap={2}>
          <Stack
            alignItems="center"
            justifyContent="center"
            p="10px"
            sx={{ height: '168px' }}
          >
            <Avatar
              src={avatarSrc}
              name={creator.name}
              width="148px"
              fontSize={68}
            />
          </Stack>
          <FormControl variant="standard">
            <InputLabel>Creator Image</InputLabel>
            <Select
              value={form.avatar}
              onChange={(e) => setForm({ ...form, avatar: e.target.value })}
            >
              <MenuItem value={CREATOR_INITIALS_OPTION}>
                Creator initials
              </MenuItem>
              {avatarOptions.map((option) => (
                <MenuItem
                  key={`${option.platform}-${option.username}`}
                  value={`${option.platform}-${option.username}`}
                  gap={1}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    {option.platform === 'instagram' && (
                      <InstagramIcon fontSize="small" />
                    )}
                    {option.platform === 'tiktok' && (
                      <TikTokIcon fontSize="small" />
                    )}
                    {option.platform === 'youtube' && (
                      <YoutubeIcon fontSize="small" />
                    )}
                    <Typography variant="subtitle1">
                      {option.username}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Creator Name"
            variant="standard"
            value={form.name}
            onChange={handleChangeName}
          />
          <Stack mt={2} mb={1}>
            <Typography>Creators Accounts</Typography>
            <Typography variant="caption" color="textSecondary">
              Accounts must start with &quot;@&quot; or &quot;UC&quot; for
              Youtube
            </Typography>
          </Stack>
          <Stack gap={2}>
            {/* YOUTUBE */}
            <AutocompleteComponent
              freeSolo
              clearOnBlur
              getOptionLabel={(t) => t}
              options={[]}
              value={form.accounts.youtube}
              onChange={(event, newValue, reason, details) => {
                handleChangeAccount(reason, details, 'youtube');
              }}
              onBlur={({ target: { value } }) => {
                if (value === '') return;
                handleChangeAccount(
                  'createOption',
                  { option: value },
                  'youtube'
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.youtube)}
                  helperText={errors.youtube}
                  label={
                    <Stack direction="row" gap={1}>
                      <YoutubeIcon />
                      Youtube
                    </Stack>
                  }
                  placeholder={
                    form.accounts.youtube.length > 0
                      ? ''
                      : 'Add Youtube account'
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={tag}
                    key={`chip__${tag}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
            {/* INSTAGRAM */}
            <AutocompleteComponent
              freeSolo
              clearOnBlur
              getOptionLabel={(t) => t}
              options={[]}
              value={form.accounts.instagram}
              onChange={(event, newValue, reason, details) => {
                handleChangeAccount(reason, details, 'instagram');
              }}
              onBlur={({ target: { value } }) => {
                if (value === '') return;
                handleChangeAccount(
                  'createOption',
                  { option: value },
                  'instagram'
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.instagram)}
                  helperText={errors.instagram}
                  label={
                    <Stack direction="row" gap={1}>
                      <InstagramIcon />
                      Instagram
                    </Stack>
                  }
                  placeholder={
                    form.accounts.instagram.length > 0
                      ? ''
                      : 'Add Instagram account'
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={tag}
                    key={`chip__${tag}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
            {/* TIKTOK */}
            <AutocompleteComponent
              freeSolo
              clearOnBlur
              getOptionLabel={(t) => t}
              options={[]}
              value={form.accounts.tiktok}
              onChange={(event, newValue, reason, details) => {
                handleChangeAccount(reason, details, 'tiktok');
              }}
              onBlur={({ target: { value } }) => {
                if (value === '') return;
                handleChangeAccount(
                  'createOption',
                  { option: value },
                  'tiktok'
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.tiktok)}
                  helperText={errors.tiktok}
                  label={
                    <Stack direction="row" gap={1}>
                      <TikTokIcon />
                      TikTok
                    </Stack>
                  }
                  placeholder={
                    form.accounts.tiktok.length > 0 ? '' : 'Add TikTok account'
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={tag}
                    key={`chip__${tag}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Stack>
          <Button
            color="error"
            onClick={onClickDelete}
            size="small"
            sx={{ padding: 0, alignSelf: 'flex-start' }}
            text="Delete Creator"
            variant="text"
          />
        </Stack>
      }
      actions={
        <>
          <Button onClick={() => onClose()} text="Cancel" variant="text" />
          <Button
            loading={isUpdating}
            onClick={handleClickSubmit}
            text="Submit"
          />
        </>
      }
    />
  );
};

EditCreator.propTypes = {
  creator: PropTypes.object,
  isOpen: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onClickDelete: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default EditCreator;
