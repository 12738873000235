import { useState } from 'react';
import { useParams } from 'react-router';

import { AddCircle, InfoOutlined } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Stack,
  Chip,
  useTheme
} from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { createKeywords } from '@/api/keywords';
import Button from '@/components/button';
import Dialog from '@/components/dialog';
import useAlert from '@/hooks/useAlert';
import getUniqueKeywordsAndError from '@/utils/keywords/keywords';

const AddKeywordToGroup = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const id = Number(useParams().id);

  const [error, setError] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [newKeywords, setNewKeywords] = useState([]);
  const [onBoardingInfo, setOnBoardingInfo] = useState(false);

  const handleConfirmAddKeyword = () => {
    if (newKeywords.length > 0) {
      const data = {
        keywords: newKeywords,
        categoryId: id
      };
      createKeywords(data)
        .then((res) => {
          if (res.status === 201) {
            if (res.data.notCreated.length === 0) {
              alert.success('Keywords successfully added.');
            } else {
              alert.info(
                'Some keywords were not created. Please check the keywords list.'
              );
            }
          }
          queryClient.invalidateQueries({
            queryKey: ['keywordGroups'],
            refetchType: 'all'
          });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            alert.error('Failed to add keywords. Keyword(s) already exist.');
          } else {
            alert.error('Some error occurred while adding keywords.');
          }
        });
    }
    onClose();
  };

  const addKeywords = () => {
    if (textInput) {
      const { keywordsArray, keywordsError } = getUniqueKeywordsAndError(
        textInput,
        newKeywords
      );
      if (error !== keywordsError) setError(keywordsError);
      setNewKeywords(keywordsArray);
      setTextInput('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addKeywords();
    }
  };

  const handleDeleteChip = (keyword) => {
    const newArray = newKeywords.slice();
    setNewKeywords(newArray.filter((e) => e !== keyword));
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      titleIcon={
        !onBoardingInfo && (
          <IconButton onClick={() => setOnBoardingInfo(true)}>
            <InfoOutlined color="action" />
          </IconButton>
        )
      }
      subTitle={
        onBoardingInfo &&
        'The keyword group feature enables you to effortlessly establish your keyword criteria for evaluations.'
      }
      title="Add Keywords"
      open={isOpen}
      onClose={onClose}
      dialogContent={
        <Stack gap={2} marginTop={1}>
          <TextField
            fullWidth
            label="Add Keywords"
            error={Boolean(error)}
            helperText={
              error ||
              'Add keywords separated by comma with at least 2 characters'
            }
            onKeyDown={(event) => handleKeyDown(event)}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={addKeywords}>
                    <AddCircle />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Stack>
            <Typography>Added keywords:</Typography>
            <Stack
              sx={{ overflowY: 'auto' }}
              height={150}
              border={`1px solid ${theme.palette.grey[400]}`}
              borderRadius="4px"
              direction="row"
              gap={1}
              padding={1}
              flexWrap="wrap"
            >
              {newKeywords?.map((keyword) => (
                <Chip
                  onDelete={() => handleDeleteChip(keyword)}
                  sx={{
                    backgroundColor: theme.palette.primary.light
                  }}
                  key={keyword}
                  label={keyword}
                  color="primary"
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      }
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleConfirmAddKeyword}
            text="save"
            disabled={newKeywords.length < 1}
          />
        </>
      }
    />
  );
};

AddKeywordToGroup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddKeywordToGroup;
