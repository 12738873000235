import {
  Box,
  CircularProgressProps,
  CircularProgress as MuiCircularProgress,
  Stack,
  Typography
} from '@mui/material';

// Add new prop if needed to control when to show the value or not
const CircularProgressWithLabel = (
  props: CircularProgressProps & {
    value?: number;
    typographysx?: any;
    showValue?: boolean;
  }
) => {
  const fontSize = (12 / 40) * Number(props.size);
  const { showValue, ...restProps } = props;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <MuiCircularProgress variant="determinate" {...restProps} />
      <Stack
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        alignItems="center"
        justifyContent="center"
      >
        {showValue && (
          <Typography
            data-testid="circular-value"
            sx={props.typographysx}
            variant="caption"
            fontSize={fontSize}
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value || 0)}%`}</Typography>
        )}
      </Stack>
    </Box>
  );
};

interface CircularProps {
  variant?: CircularProgressProps['variant'];
  value?: CircularProgressProps['value'];
  size?: CircularProgressProps['size'];
  typographysx?: any;
  showValue?: boolean;
}

const CircularProgress = (props: CircularProps) => {
  const { showValue, ...restProps } = props;
  return restProps.value !== undefined && restProps.value >= 0 ? (
    <CircularProgressWithLabel
      {...restProps}
      data-testid="circular-progress-with-value"
      showValue={showValue}
    />
  ) : (
    <MuiCircularProgress data-testid="circular-progress" {...restProps} />
  );
};

export default CircularProgress;
