import { useMutation } from '@tanstack/react-query';

import { resetPassword } from '@/api/auth';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

export const useResetPassword = (
  onSuccess?: onSuccessType,
  onError?: onErrorType
) =>
  useMutation({
    mutationFn: resetPassword,
    onSuccess,
    onError
  });
