import { CheckCircle, Warning, Error } from '@mui/icons-material';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';

import { getPrettyDateWithTime } from '@/utils/datesAndTime/datesAndTime';

import InstagramIcon from '../icons/InstagramIcon';
import TikTokIcon from '../icons/TikTokIcon';
import YoutubeIcon from '../icons/YoutubeIcon';

interface PlatformStatusProps {
  platform: 'instagram' | 'tiktok' | 'youtube';
  status: 'active' | 'disabled' | 'delayed';
  lastUpdateDate: number;
}

const platformIconMapper = {
  instagram: {
    icon: <InstagramIcon />,
    text: 'Instagram'
  },
  tiktok: { icon: <TikTokIcon />, text: 'TikTok' },
  youtube: { icon: <YoutubeIcon />, text: 'Youtube' }
};

const statusMapper = {
  active: {
    icon: <CheckCircle color="success" />,
    text: 'Operational'
  },
  disabled: {
    icon: <Error color="error" />,
    text: 'Inactive'
  },
  delayed: {
    icon: <Warning color="warning" />,
    text: 'Delayed'
  }
};

const PlatformStatus = ({
  platform,
  status,
  lastUpdateDate
}: PlatformStatusProps) => {
  const theme = useTheme();
  const stringDate = new Date(lastUpdateDate).toString();
  return (
    <Stack
      data-testid={`platform-status-${status}`}
      borderRadius="4px"
      border={`1px solid ${theme.palette.grey[400]}`}
      padding={2}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row" gap={1.25}>
          {platformIconMapper[platform].icon}
          <Typography variant="h6">
            {platformIconMapper[platform].text} Status
          </Typography>
        </Stack>
        <Tooltip title={statusMapper[status].text} arrow placement="top">
          {statusMapper[status].icon}
        </Tooltip>
      </Stack>
      {lastUpdateDate !== 0 ? (
        <Stack paddingY={1} direction="row" gap={1}>
          <Typography fontWeight="500" variant="body2" color="textSecondary">
            Last Update:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {getPrettyDateWithTime(stringDate)}
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default PlatformStatus;
