import { AxiosResponse } from 'axios';

import { UnknownObject } from '@/models/misc';
import { Posts } from '@/models/posts';

import { decomposeParams, api } from '.';

export const getPosts = (
  params: Posts.ListParams
): Promise<AxiosResponse<Posts.List>> =>
  api.get(`v2/posts${decomposeParams(params)}`);

export const getPost = (
  id: number,
  params: Posts.DetailParams
): Promise<AxiosResponse<Posts.Details>> =>
  api.get(`v2/posts/${id}${decomposeParams(params)}`);

/**
 * Rate compliance for a specific post in evaluation
 * @param payload { postId, evaluationId, reason, rating }
 */
export const createCompliance = (
  payload: UnknownObject
): Promise<AxiosResponse<Posts.Compliance>> =>
  api.post(`v2/compliance`, payload);

/**
 * Updates compliance for a specific post in evaluation
 * @param payload { postId, evaluationId, reason, rating }
 */
export const updateCompliance = (
  payload: UnknownObject
): Promise<AxiosResponse<Posts.Compliance>> =>
  api.put(`v2/compliance`, payload);

/**
 * Remove compliance rating from a specific post in evaluation
 * @param payload { postId, evaluationId }
 */
export const deleteCompliance = (payload: UnknownObject) =>
  api.delete(`v2/compliance`, { data: payload });
