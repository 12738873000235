import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MuiAccordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

interface Props {
  id: number | string;
  title: string | React.ReactNode;
  subtitle?: string;
  isExpanded?: boolean;
  condensed?: boolean;
  handleChange: (id: number | string) => void;
  children: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  condensed: {
    boxShadow: 'none!important',
    margin: '0 0 4px!important',
    '&:before': { display: 'none' },
    '.MuiAccordionSummary-root': {
      padding: '0!important',
      margin: '0 0 4px!important',
      height: 'auto!important',
      minHeight: 'auto!important',
      '.MuiAccordionSummary-content': {
        margin: '0!important'
      }
    },
    '.MuiAccordionDetails-root': {
      padding: '0!important'
    }
  },
  isExpanded: {
    cursor: 'default!important'
  }
}));

const Accordion = ({
  id,
  isExpanded = false,
  title,
  subtitle,
  condensed,
  children,
  handleChange
}: Props) => {
  const styles = useStyles();
  return (
    <MuiAccordion
      expanded={isExpanded}
      onChange={() => handleChange(id)}
      className={condensed ? styles.classes.condensed : ''}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id.toString()}
        id={id.toString()}
        className={isExpanded ? styles.classes.isExpanded : ''}
      >
        <Typography>{title}</Typography>

        {subtitle && <Typography>{subtitle}</Typography>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
