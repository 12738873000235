import { Link as RouterLink } from 'react-router-dom';

import { Typography, Stack, Container } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import Page from '@/components/Page';
import Button from '@/components/button/Button';

const useStyles = makeStyles()(() => ({
  button: {
    alignSelf: 'center'
  }
}));

const NotFound = () => {
  const { classes } = useStyles();
  return (
    <Page>
      <Container>
        <Stack
          height="calc(100vh - 120px)"
          justifyContent="center"
          alignItems="center"
          spacing={5.5}
        >
          <img
            src={`${process.env.REACT_APP_S3_ASSETS}/empty-states/notFound.svg`}
            alt="Not Found"
          />
          <Stack gap={3}>
            <Stack padding="8px" gap={2.5} alignItems="center">
              <Typography variant="h4">Page not found</Typography>
              <Typography align="center">
                The page you are looking for doesn&apos;t exist or another error
                might have occurred.
                <br />
                <b>Go back</b>, and choose a different direction.
              </Typography>
            </Stack>
            <Typography align="center" variant="caption">
              Error code: 404
            </Typography>

            <RouterLink to="/" className={classes.button}>
              <Button text="Back to home" />
            </RouterLink>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};

export default NotFound;
