import { useContext, useMemo, useCallback, FC } from 'react';

import {
  Typography,
  Stack,
  Badge,
  styled,
  BadgeProps,
  Box
} from '@mui/material';

import parse from 'html-react-parser';
import { makeStyles } from 'tss-react/mui';

import Accordion from '@/components/accordion';
import Card from '@/components/card';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { Posts } from '@/models/posts';
import { msToPrettyTimestamp, msToSeconds } from '@/utils/datesAndTime';
import { highlightWords, stringHasKeyword } from '@/utils/text';

const useStyles = makeStyles()((theme) => ({
  highlighted: {
    background: theme.palette.grey[100],
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    paddingLeft: 5,
    cursor: 'pointer'
  },
  notHighlighted: {
    cursor: 'pointer'
  }
}));

interface OcrAdvVideoLineProps {
  item: Posts.OcrAdvVideoItem;
}

const OcrAdvVideoLine: FC<OcrAdvVideoLineProps> = ({ item }) => {
  const {
    selectedKeywords,
    setVideoPlaybackStart,
    setVideoLastClick,
    selectedOcrAdvItems,
    setSelectedOcrAdvItems
  } = useContext(PostDetailsContext);

  const prettyTimestamp = msToPrettyTimestamp(item.timestamp);

  const { classes } = useStyles();

  const isAlreadySelected = useMemo(
    () =>
      item.textFound.some((itemToAdd) =>
        selectedOcrAdvItems.some(
          (selectedItem) =>
            JSON.stringify(selectedItem) === JSON.stringify(itemToAdd)
        )
      ),
    [item, selectedOcrAdvItems]
  );

  const handleOcrAdvVideoLine = () => {
    const itemsToAdd = item.textFound;

    if (isAlreadySelected) {
      const updatedSelectedItems = selectedOcrAdvItems.filter(
        (selectedItem) =>
          !itemsToAdd.some(
            (itemToAdd) =>
              JSON.stringify(selectedItem) === JSON.stringify(itemToAdd)
          )
      );
      setSelectedOcrAdvItems(updatedSelectedItems);
    } else {
      setSelectedOcrAdvItems([...selectedOcrAdvItems, ...itemsToAdd]);
    }
    const seconds = msToSeconds(item.timestamp);
    setVideoPlaybackStart(seconds);
    setVideoLastClick(new Date().getTime());
  };

  const boxSx = useMemo(
    () => ({
      opacity: item.textFound.some((text) =>
        stringHasKeyword(text.content, selectedKeywords)
      )
        ? 1
        : 0.75
    }),
    [item.textFound, selectedKeywords]
  );

  return (
    <Stack
      key={item.timestamp}
      direction="row"
      alignItems="center"
      gap={2}
      overflow="visible"
      padding={0.25}
      sx={boxSx}
      className={
        isAlreadySelected ? classes.highlighted : classes.notHighlighted
      }
      onClick={handleOcrAdvVideoLine}
    >
      <Typography
        justifyContent="center"
        color="primary"
        fontSize={12}
        width={35}
        fontWeight={500}
      >
        {prettyTimestamp}
      </Typography>
      <Typography variant="body2" fontSize={12}>
        {item.textFound.map((text, index) => (
          <span key={index}>{parse(text.content)} </span>
        ))}
      </Typography>
    </Stack>
  );
};

const OcrAdvImageLine: FC<{ item: Posts.OcrAdvImageItem }> = ({ item }) => {
  const { classes } = useStyles();

  const { selectedOcrAdvItems, setSelectedOcrAdvItems, selectedKeywords } =
    useContext(PostDetailsContext);

  const isAlreadySelected = useMemo(
    () =>
      selectedOcrAdvItems.some(
        (selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(item)
      ),
    [selectedOcrAdvItems, item]
  );

  const handleOcrAdvImageLine = () => {
    const updatedSelectedItems = isAlreadySelected
      ? selectedOcrAdvItems.filter(
          (selectedItem) =>
            JSON.stringify(selectedItem) !== JSON.stringify(item)
        )
      : [...selectedOcrAdvItems, item];

    setSelectedOcrAdvItems(updatedSelectedItems);
  };

  const boxSx = useMemo(
    () => ({
      opacity: stringHasKeyword(item.content, selectedKeywords) ? 1 : 0.75
    }),
    [item.content, selectedKeywords]
  );

  return (
    <Stack
      className={
        isAlreadySelected ? classes.highlighted : classes.notHighlighted
      }
      sx={boxSx}
      alignItems="center"
      px={1}
      onClick={handleOcrAdvImageLine}
    >
      <Typography variant="body2" fontSize={12}>
        {parse(item.content)}
      </Typography>
    </Stack>
  );
};

const OcrAdvStack: FC<{
  ocrAdvItems: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[];
}> = ({ ocrAdvItems }) => (
  <>
    {ocrAdvItems.map((item, index) => (
      <Box key={index} display="flex" alignItems="center">
        {'timestamp' in item ? (
          <OcrAdvVideoLine item={item as Posts.OcrAdvVideoItem} />
        ) : (
          <OcrAdvImageLine item={item as Posts.OcrAdvImageItem} />
        )}
      </Box>
    ))}
  </>
);

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -15,
    top: 12
  }
}));

const keywordMatchCount = (
  items: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[],
  keywords: string[]
) =>
  items.filter((line: Posts.OcrAdvImageItem | Posts.OcrAdvVideoItem) => {
    if ('textFound' in line) {
      // Narrowing down to OcrAdvVideoItem
      return (line as Posts.OcrAdvVideoItem).textFound.some((text) =>
        stringHasKeyword(text.content, keywords)
      );
    }
    // Handling OcrAdvImageItem
    return stringHasKeyword((line as Posts.OcrAdvImageItem).content, keywords);
  }).length;

interface OcrAdvBadgeProps {
  mediaItem: Posts.Media;
  selectedKeywords: string[];
  index: number;
}

const OcrAdvBadge = ({
  mediaItem,
  selectedKeywords,
  index
}: OcrAdvBadgeProps) => {
  const isOcrAdvVideoItem = (
    item: Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem
  ): item is Posts.OcrAdvVideoItem => 'content' in item || 'textFound' in item;

  return (
    <StyledBadge
      badgeContent={
        mediaItem.visualRecognition
          ? keywordMatchCount(
              mediaItem.visualRecognition
                .filter(
                  (
                    item
                  ): item is Posts.Recognition & {
                    type: 'ocr-adv';
                    items: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[];
                  } => item.type === 'ocr-adv'
                )
                .flatMap((item) => item.items.filter(isOcrAdvVideoItem)),
              selectedKeywords
            )
          : 0
      }
      color="primary"
    >
      {`Item ${index + 1}`}
    </StyledBadge>
  );
};

const filterOcrAdvItems = (
  items: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[],
  keywords: string[]
) =>
  items.filter((line) => {
    if (isOcrAdvVideoItem(line)) {
      return line.textFound.some((text) =>
        stringHasKeyword(text.content, keywords)
      );
    }
    return stringHasKeyword(line.content, keywords);
  });

const isOcrAdvVideoItem = (
  item: Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem
): item is Posts.OcrAdvVideoItem => 'textFound' in item;

interface OcrAdvBodyProps {
  media: Posts.Media[];
}

const OcrAdvBody = ({ media }: OcrAdvBodyProps) => {
  const {
    data,
    activeIndex,
    selectedKeywords,
    setActiveIndex,
    setVideoPlaybackStart,
    setSelectedMinorItems,
    setSelectedOcrAdvItems
  } = useContext(PostDetailsContext);

  const handleChange = useCallback(
    (index: number) => {
      setActiveIndex(index);
      setVideoPlaybackStart(0);
      setSelectedMinorItems([]);
      setSelectedOcrAdvItems([]);
    },
    [setActiveIndex, setVideoPlaybackStart]
  );

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  if (media.length === 1) {
    return (
      <OcrAdvStack
        ocrAdvItems={media[0].visualRecognition
          ?.filter(
            (
              item
            ): item is Posts.Recognition & {
              type: 'ocr-adv';
              items: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[];
            } => item.type === 'ocr-adv'
          )
          .flatMap((item) => item.items)}
      />
    );
  }

  return (
    <Stack>
      {media.map((mediaItem, index) => (
        <Accordion
          id={mediaItem.mediaPath}
          title={
            <OcrAdvBadge
              mediaItem={mediaItem}
              selectedKeywords={selectedKeywords}
              index={index}
            />
          }
          handleChange={() => handleChange(index)}
          isExpanded={index === activeIndex || media.length === 1}
          key={mediaItem.mediaPath}
          condensed
        >
          <OcrAdvStack
            ocrAdvItems={mediaItem.visualRecognition
              ?.filter(
                (
                  item
                ): item is Posts.Recognition & {
                  type: 'ocr-adv';
                  items: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[];
                } => item.type === 'ocr-adv'
              )
              .flatMap((item) => item.items)}
          />
        </Accordion>
      ))}
    </Stack>
  );
};

const PostOcrAdvanced = () => {
  const { data, selectedKeywords, keywordList } =
    useContext(PostDetailsContext);

  const modMedia = useMemo(() => {
    if (!data?.media) return [];

    if (selectedKeywords.length === 0) return data.media;

    if (keywordList.length > 0) {
      const filteredMedia = data.media.map((media) => ({
        ...media,
        visualRecognition: media.visualRecognition?.map((recognition) => {
          if (recognition.type === 'ocr-adv') {
            const items = recognition.items as
              | Posts.OcrAdvVideoItem[]
              | Posts.OcrAdvImageItem[];
            return {
              ...recognition,
              items:
                selectedKeywords.length > 0
                  ? filterOcrAdvItems(items, selectedKeywords)
                  : items
            };
          }
          return recognition;
        })
      }));

      const hasFilteredItems = filteredMedia.some(
        (media) =>
          media.visualRecognition &&
          media.visualRecognition.some(
            (recognition) =>
              recognition.type === 'ocr-adv' && recognition.items.length > 0
          )
      );

      if (!hasFilteredItems) return data.media;

      return filteredMedia.map((media) => ({
        ...media,
        visualRecognition: media.visualRecognition?.map((recognition) => {
          if (recognition.type === 'ocr-adv') {
            const items = recognition.items as
              | Posts.OcrAdvVideoItem[]
              | Posts.OcrAdvImageItem[];
            return {
              ...recognition,
              items: items.map((item) =>
                'textFound' in item
                  ? {
                      ...item,
                      textFound: item.textFound.map((text) => ({
                        ...text,
                        content: highlightWords(text.content, selectedKeywords)
                      }))
                    }
                  : {
                      ...item,
                      content: highlightWords(item.content, selectedKeywords)
                    }
              )
            };
          }
          return recognition;
        })
      }));
    }

    return data.media;
  }, [selectedKeywords, data, keywordList]);

  const hasAtLeastOneOcrAdv = useMemo(
    () =>
      modMedia.some(
        (mediaItem) =>
          mediaItem.visualRecognition &&
          mediaItem.visualRecognition.some(
            (recognition) =>
              recognition.type === 'ocr-adv' && recognition.items.length > 0
          )
      ),
    [modMedia]
  );

  const hasBorder = useMemo(
    () =>
      modMedia?.some((media) =>
        media.visualRecognition?.some(
          (recognition) =>
            recognition.type === 'ocr-adv' &&
            recognition.items.some(
              (item) =>
                ('textFound' in item &&
                  item.textFound.some((text) =>
                    text.content.includes('</b>')
                  )) ||
                ('content' in item && item.content.includes('</b>'))
            )
        )
      ),
    [modMedia]
  );

  return hasAtLeastOneOcrAdv ? (
    <Card
      shadow
      sx={{ marginBottom: 2 }} // Replacing gap: 2 in PostLayout due to display block
      isCollapsible
      title="Text on Screen"
      hasBorder={hasBorder}
      content={<OcrAdvBody media={modMedia} />}
    />
  ) : (
    <></>
  );
};

export default PostOcrAdvanced;
