import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { useQueryClient } from '@tanstack/react-query';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { useDeleteCreatorGroup } from '@/hooks/queries/creatorGroups';
import useAlert from '@/hooks/useAlert';
import { CreatorGroups } from '@/models/creator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clearSelection: () => void;
  selected: GridRowSelectionModel;
  groupsList: Array<CreatorGroups.Item>;
}

const DeleteCreatorGroup = ({
  isOpen,
  onClose,
  selected,
  clearSelection,
  groupsList
}: Props) => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutate: deleteGroups, isLoading: isDeleting } = useDeleteCreatorGroup(
    {
      onSuccess: () => {
        alert.success(`Creator group(s) successfully deleted`);
        queryClient.invalidateQueries({ queryKey: ['creatorGroups'] });
        queryClient.invalidateQueries({
          queryKey: ['creatorsAutocomplete'],
          refetchType: 'all'
        });
        onClose();
        clearSelection();
      },
      onError: () =>
        alert.error(`Some error occurred while deleting creator groups`)
    }
  );

  const handleDeleteGroups = () => {
    deleteGroups({ ids: selected });
  };

  return (
    <Dialog
      title="Delete Creators"
      dialogContent={
        selected.length === 1 ? (
          <>
            <Typography>
              Are you sure you want to delete &ldquo;
              {groupsList.find((item) => item.id === selected[0])?.name}
              &ldquo;?
            </Typography>
            <Typography>
              You will not be able to recover this creator group.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              {`Are you sure you want to delete ${selected.length} creator groups?`}
            </Typography>
            <Typography>You will not be able to recover these.</Typography>
          </>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            color="error"
            text="delete"
            onClick={handleDeleteGroups}
            loading={isDeleting}
          />
        </>
      }
    />
  );
};

export default DeleteCreatorGroup;
