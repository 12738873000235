import { forwardRef } from 'react';

import {
  Alert as MuiAlert,
  AlertTitle,
  AlertProps as MuiAlertProps
} from '@mui/material';

import parse from 'html-react-parser';

export interface AlertProps extends MuiAlertProps {
  content?: string;
}

type Ref = HTMLDivElement;

const Alert = forwardRef<Ref, AlertProps>(
  (
    {
      title,
      content,
      action,
      variant = 'standard',
      severity = 'success',
      icon,
      ...props
    },
    ref
  ) => (
    <MuiAlert
      ref={ref}
      icon={icon}
      variant={variant}
      severity={severity}
      action={action}
      {...props}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {parse(content || '')}
    </MuiAlert>
  )
);

Alert.displayName = 'Alert';

export default Alert;
