import { Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { deleteKeywordGroups } from '@/api/keywords';
import Button from '@/components/button';
import Dialog from '@/components/dialog';
import useAlert from '@/hooks/useAlert';

const DeleteKeywordGroups = ({
  isOpen,
  onClose,
  selected,
  clearSelection,
  groupsList
}) => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const handleConfirmDeleteGroup = () => {
    const groupsToDelete = groupsList
      .filter((group) => selected.includes(group.id))
      .map((group) => group.title);

    const data = {
      categoryIds: selected.join(',')
    };

    deleteKeywordGroups(data)
      .then(() => {
        const alertMessage =
          selected.length === 1
            ? `"${groupsToDelete[0]}" has been successfully deleted`
            : `${selected.length} groups have been successfully deleted`;

        alert.info(alertMessage);
        queryClient.invalidateQueries({
          queryKey: ['keywordGroups'],
          refetchType: 'all'
        });
        clearSelection();
      })
      .catch(() => alert.error(`Some error occurred while deleting the groups`))
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog
      title="Delete Keyword Group"
      dialogContent={
        selected.length === 1 ? (
          <>
            <Typography>
              Are you sure you want to delete &ldquo;
              {groupsList.find((item) => item.id === selected[0])?.title}
              &ldquo;?
            </Typography>
            <Typography>
              You will not be able to recover this keyword group.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              Are you sure you want to delete {selected.length} keyword groups?
            </Typography>
            <Typography>You will not be able to recover these.</Typography>
          </>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleConfirmDeleteGroup}
            color="error"
            text="delete"
          />
        </>
      }
    />
  );
};

DeleteKeywordGroups.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selected: PropTypes.array,
  clearSelection: PropTypes.func,
  groupsList: PropTypes.array
};

export default DeleteKeywordGroups;
