/* eslint-disable no-underscore-dangle */
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { POWERBI_API_URL } from '@/constants/powerbi';
import fire from '@/fire';
import { UnknownObject } from '@/models/misc';
import LocalStorageService from '@/services/LocalStorageService';
import getRoute from '@/utils/getRoute';

import { getPowerBIapiToken } from './powerbi';

let isRefreshing = false;
const refreshSubscribers: ((token: string) => void)[] = [];
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

const isTokenExpired = (expiresIn: number) => {
  const expirationTime = new Date().getTime() + expiresIn * 1000;
  return expirationTime <= new Date().getTime();
};

export const getHttpConfig = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const requestUrl = config.url;

  const isPowerBI = requestUrl && requestUrl.startsWith(POWERBI_API_URL || '');

  const localStorageItemName = isPowerBI ? 'powerbi-token' : 'token';

  if (
    isPowerBI &&
    isTokenExpired(LocalStorageService.getItem('powerbi-token-expires-in'))
  ) {
    getPowerBIapiToken().then((data: any) => {
      LocalStorageService.setItem('powerbi-token', data.access_token);
      LocalStorageService.setItem('powerbi-token-expires-in', data.expires_in);
    });
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${LocalStorageService.getItem(
        localStorageItemName
      )}`
    }
  };
};

export const api = axios.create({ baseURL: apiURL });

export const decomposeParams = (params?: UnknownObject) =>
  params
    ? `?${Object.entries(params)
        .filter(([, value]) => ![undefined, null].includes(value))
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    : '';

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onTokenRefreshed = (token: string) => {
  refreshSubscribers.map((cb) => cb(token));
};

const refreshToken = () =>
  new Promise((resolve, reject) => {
    fire.auth().onIdTokenChanged((user: any) => {
      if (user) {
        const { Aa: newToken } = user;
        LocalStorageService.setItem('token', newToken);
        resolve(newToken);
      } else {
        reject();
      }
    });
  });

api.interceptors.request.use((config) => ({
  ...config,
  ...(getHttpConfig(config) as InternalAxiosRequestConfig<unknown>)
}));

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshToken()
          .then((newToken) => {
            isRefreshing = false;
            onTokenRefreshed(newToken as string);
          })
          .catch(() => {
            isRefreshing = false;
            window.location.href = getRoute.auth.LOGOUT();
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(api.request(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);
