import { useState } from 'react';

import Results from './_partials/Results';

const Body = () => {
  const [selectionModel, setSelectionModel] = useState([]);

  return (
    <Results
      selectionModel={selectionModel}
      setSelectionModel={setSelectionModel}
    />
  );
};

export default Body;
