import { Typography } from '@mui/material';

import Alert from '@/components/alert/Alert';
import Button from '@/components/button';
import Dialog from '@/components/dialog/Dialog';

interface Props {
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isDeleting: boolean;
}

const DeleteCreator = ({
  name,
  isOpen,
  onClose,
  onSubmit,
  isDeleting
}: Props) => (
  <Dialog
    open={isOpen}
    title="Delete Creator"
    fullWidth
    maxWidth="sm"
    dialogContent={
      <>
        <Typography mt={2} mb={2}>
          {`Are you sure you want to delete "${name}" as a creator?`}
        </Typography>
        <Alert
          color="error"
          content="You will lose access to this creator"
          severity="error"
        />
      </>
    }
    actions={
      <>
        <Button variant="text" text="Cancel" onClick={onClose} />
        <Button
          color="error"
          text="Confirm & Delete"
          onClick={onSubmit}
          loading={isDeleting}
        />
      </>
    }
  />
);

export default DeleteCreator;
