import { useContext } from 'react';

import { Grid, Stack } from '@mui/material';

import { PostDetailsContext } from '@/contexts/PostDetailsContext';

import PostCompliance from './_partials/PostCompliance';
import PostCreator from './_partials/PostCreator';
import PostKeywordsDetails from './_partials/PostKeywordsDetails';
import PostMedia from './_partials/PostMedia';
import PostOcr from './_partials/PostOcr';
import PostTranscript from './_partials/PostTranscript';
import PostVisuals from './_partials/PostVisuals';

const PostLayout = () => {
  const { hasVRForMinorsSelected } = useContext(PostDetailsContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Stack gap={2}>
          <PostCreator />
          <PostMedia />
          {hasVRForMinorsSelected && <PostVisuals />}
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          style={{
            display: 'block',
            maxHeight: 'calc(100vh - 100px)', // change due to removing top bar
            overflowY: 'scroll'
          }}
        >
          <PostKeywordsDetails />
          <PostTranscript />
          <PostOcr />
          <PostCompliance />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PostLayout;
