import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Workspaces } from '@mui/icons-material';
import { Snackbar, Box, Typography, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { useQueryClient } from '@tanstack/react-query';
import { makeStyles } from 'tss-react/mui';

import Alert from '@/components/alert';
import { useUpdateUser } from '@/hooks/queries/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LocalStorageService from '@/services/LocalStorageService';
import { handleCloseAlert } from '@/store/alertSlice';
import Login from '@/views/auth/login';

import NavBar from './NavBar/NavBar';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 64,
    paddingLeft: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: theme.spacing(3)
    // paddingTop: theme.spacing(3)
  },
  spinningIcon: {
    animation: 'spin 2s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

const Layout = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const queryClient = useQueryClient();

  const [isInvalidating, setIsInvalidating] = useState(false);
  const [user] = useState(JSON.parse(LocalStorageService.getItem('user')));

  const { mutate: updateWorkspace } = useUpdateUser(
    () => {
      const localUser = JSON.parse(LocalStorageService.getItem('user') || '');
      const updatedUser = { ...localUser, workspaceId };
      LocalStorageService.setItem('user', JSON.stringify(updatedUser));

      // Invalidate queries and then set the invalidation state to false
      setIsInvalidating(true);
      queryClient.invalidateQueries({ refetchType: 'all' }).then(() => {
        setIsInvalidating(false);
      });
    },
    () => {
      navigate(`/workspace/${user.workspaceId}/evaluations`, {
        replace: true
      });
    }
  );

  const { open, action, severity, content } = useAppSelector(
    (state) => state.alert
  );

  useEffect(() => {
    if (user && user.workspaceId) {
      const storedWorkspaceId = user.workspaceId;

      if (
        workspaceId &&
        workspaceId.toString() !== storedWorkspaceId.toString()
      ) {
        updateWorkspace({ workspaceId: parseInt(workspaceId, 10) });
      } else if (!workspaceId) {
        navigate(`/workspace/${storedWorkspaceId}/evaluations`, {
          replace: true
        });
      }
    } else {
      navigate('/auth/login', { replace: true });
    }
  }, [workspaceId]);

  if (!user) {
    return <Login />;
  }

  if (isInvalidating) {
    // Show a loading spinner or a placeholder until the queries are invalidated
    return (
      <Box className={classes.root}>
        <NavBar />
        <Box className={classes.wrapper}>
          <Box className={classes.contentContainer}>
            <Box className={classes.content}>
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={2}
                height="calc(100vh - 120px)"
              >
                <Workspaces
                  fontSize="large"
                  sx={{
                    animation: 'spin 2s linear infinite',
                    '@keyframes spin': {
                      '0%': {
                        transform: 'rotate(360deg)'
                      },
                      '100%': {
                        transform: 'rotate(0deg)'
                      }
                    }
                  }}
                />
                <Typography variant="h6">Changing Workspace...</Typography>
                <Typography>
                  Please wait until we finish changing the workspace
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {/* <TopBar /> */}
      <NavBar />
      <Box className={classes.wrapper}>
        <Box className={classes.contentContainer}>
          <Box className={classes.content}>
            <Outlet />
            <Snackbar
              open={open}
              autoHideDuration={6500}
              onClose={() => dispatch(handleCloseAlert())}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert action={action} severity={severity} content={content} />
            </Snackbar>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
