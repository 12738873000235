import { useNavigate } from 'react-router';

import {
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  Stack,
  Typography
} from '@mui/material';

import Button from '@/components/button';
import { useAppSelector } from '@/hooks/redux';
import getRoute from '@/utils/getRoute';

interface AccountInfoMenuProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const AccountInfoMenu = ({
  isOpen,
  anchorEl,
  handleClose
}: AccountInfoMenuProps) => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user.user);

  const handleLogout = () => {
    navigate(getRoute.auth.LOGOUT());
  };

  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
      <ListSubheader>Account</ListSubheader>
      <ListItem>
        <ListItemText>
          <Typography fontWeight={600}>{user.workspaceName}</Typography>
          <Typography>{user.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {user.email}
          </Typography>
        </ListItemText>
      </ListItem>

      <Stack direction="row" justifyContent="flex-end" paddingX={1}>
        <Button
          sx={{ margin: '8px' }}
          variant="text"
          text="Log Out"
          onClick={handleLogout}
        />
      </Stack>
    </Menu>
  );
};

export default AccountInfoMenu;
