import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { PrismicProvider } from '@prismicio/react';

import App from './App';
import { client } from './prismic';
import * as serviceWorker from './serviceWorker';
import store from './store';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <PrismicProvider client={client}>
          <App />
        </PrismicProvider>
      </Provider>
    </BrowserRouter>
  );
}

serviceWorker.unregister();
