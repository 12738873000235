import {
  PlayCircleFilled,
  AccountCircle,
  MenuOpen,
  Folder
} from '@mui/icons-material';
import { Stack } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import Alert from '@/components/alert';
import Checkbox from '@/components/checkbox';
import ResultCard from '@/components/resultCard';
import { VISUAL_RECOGNITION_FEATURE } from '@/constants/features';
import { useAppSelector } from '@/hooks/redux';
import { Workspace } from '@/models/workspace';

interface Props {
  results: {
    items: number;
    creators: number;
    keywords: number;
    keywordGroups: number;
  };
  isLoading: boolean;
  error: boolean;
  onChange: (field: string, value: any) => void;
  requestedServices: Array<any>;
  isDisabled: boolean;
}

const Step4 = ({
  error,
  results,
  isLoading,
  onChange,
  isDisabled,
  requestedServices
}: Props): JSX.Element => {
  const videosLabel = `${results.items} Items`;
  const creatorsLabel = `${results.creators} Creators`;
  const keywordsLabel = `${results.keywords} Keywords`;
  const keywordGroupLabel = `${results.keywordGroups} Keyword Groups`;
  const queryClient = useQueryClient();

  const hasVRForMinorsSelected = requestedServices?.some(
    (service) => service.type === 'minors'
  );

  const user = useAppSelector((state) => state.user.user);

  const workspaces = queryClient.getQueryData(['workspaces']) as Workspace[];

  const workspace = workspaces.find(
    (w: Workspace) => w.id === user?.workspaceId
  );

  const IS_VR_ENABLED = workspace
    ? workspace.features.includes(VISUAL_RECOGNITION_FEATURE)
    : false;

  return (
    <Stack gap={2}>
      <ResultCard
        disabled={error}
        items={[
          {
            icon: (
              <PlayCircleFilled
                fontSize="large"
                color={error ? 'disabled' : 'primary'}
              />
            ),
            label: videosLabel,
            loading: isLoading,
            title: 'Includes:'
          },
          {
            icon: (
              <AccountCircle
                fontSize="large"
                color={error ? 'disabled' : 'primary'}
              />
            ),
            label: creatorsLabel,
            loading: isLoading,
            title: 'Includes:'
          },
          {
            icon: (
              <MenuOpen
                fontSize="large"
                color={error ? 'disabled' : 'primary'}
              />
            ),
            label: keywordsLabel,
            loading: isLoading,
            title: 'Includes:'
          },
          {
            icon: (
              <Folder fontSize="large" color={error ? 'disabled' : 'primary'} />
            ),
            label: keywordGroupLabel,
            loading: isLoading,
            title: 'Includes:'
          }
        ]}
      />
      {!error && !isLoading && IS_VR_ENABLED && (
        <Alert
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            opacity: isDisabled ? 0.5 : 1,
            pointerEvents: isDisabled ? 'none' : 'auto'
          }}
          severity="info"
          content="Request detection of minors <b>(below 18 years old)</b> for this evaluation."
          action={
            <Checkbox
              checked={hasVRForMinorsSelected}
              disabled={isDisabled}
              color="info"
              onChange={(event) =>
                onChange(
                  'requestedServices',
                  event.target.checked ? ['minors'] : []
                )
              }
            />
          }
        />
      )}
      {error && !isLoading && (
        <Alert
          severity="error"
          content="Sorry! We could not preview your evaluation because of an unknown error"
        />
      )}
    </Stack>
  );
};

export default Step4;
