import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import {
  useEvaluationTitleExists,
  usePreviewEvaluation,
  useUpdateEvaluation
} from '@/hooks/queries/evaluations';
import useAlert from '@/hooks/useAlert';
import {
  convertParametersToForm,
  convertFormToParameters
} from '@/utils/evaluations/evaluation';
import { validateString } from '@/utils/text';

import Step1 from '../create/_partials/Step1';
import Step2 from '../create/_partials/Step2';
import Step3 from '../create/_partials/Step3';
import Step4 from '../create/_partials/Step4';

const useStyles = makeStyles()(() => ({
  stepper: {
    height: '72px'
  }
}));

function EditEvaluation({ isOpen, onClose }) {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const alert = useAlert();
  const evaluationId = Number(useParams().evaluationId);

  const { parameters, title, requestedServices } = queryClient.getQueryData([
    'evaluation',
    evaluationId
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const [errorPreview, setErrorPreview] = useState(false);
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [previewResults, setPreviewResults] = useState({
    items: 0,
    creators: 0,
    keywords: 0,
    keywordGroups: 0
  });

  const [form, setForm] = useState({
    ...convertParametersToForm(parameters),
    title
  });
  const [errors, setErrors] = useState({
    followersRange: '',
    platforms: '',
    title: ''
  });

  const { mutate: updateEvaluation, isLoading: isSubmitting } =
    useUpdateEvaluation(
      evaluationId,
      () => {
        queryClient.invalidateQueries({
          queryKey: ['evaluation', evaluationId]
        });
        queryClient.invalidateQueries({
          queryKey: ['evaluations'],
          refetchType: 'inactive'
        });
        queryClient.invalidateQueries({
          queryKey: ['posts']
        });
        queryClient.invalidateQueries({
          queryKey: ['post'],
          refetchType: 'inactive'
        });
        alert.success(`The evaluation criteria has been processed.`);
        onClose();
      },
      () => {
        alert.error(
          <>
            <Typography>Oops, something went wrong.</Typography>
            <Typography>
              The evaluation criteria could not be edited.
            </Typography>
          </>
        );
      }
    );

  const { mutate: previewEvaluation, isLoading: isLoadingPreviewResults } =
    usePreviewEvaluation(
      ({ data }) =>
        setPreviewResults({
          creators: data.totalCreators,
          items: data.totalPosts,
          keywordGroups: data.totalKeywordGroups,
          keywords: data.totalKeywords
        }),
      () => {
        setErrorPreview(true);
      }
    );

  const { mutate: evaluationTitleExists } = useEvaluationTitleExists(
    ({ data }) => {
      const titleAlreadyExists = Object.keys(data).length > 0;
      if (titleAlreadyExists)
        setErrors({ ...errors, title: 'This title already exits' });
      else setActiveStep(1);
    }
  );

  const handleClickPrevious = () => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
        setActiveStep(activeStep - 1);
        break;
      case 3:
        setActiveStep(activeStep - 1);
        setPreviewResults({
          creators: 0,
          items: 0,
          keywordGroups: 0,
          keywords: 0
        });
        break;
      default:
        break;
    }
  };

  const handleClickContinue = () => {
    switch (activeStep) {
      case 0:
        if (!validateString(title, undefined, 3)) {
          setErrors({
            ...errors,
            title: 'Please create a title of 3 characters or more'
          });
          return;
        }

        if (title !== form.title) evaluationTitleExists(form.title);
        else setActiveStep(activeStep + 1);
        break;
      case 1:
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setActiveStep(activeStep + 1);
        previewEvaluation(convertFormToParameters(form));
        break;
      case 3:
        {
          const data = {
            title: form.title,
            parameters: convertFormToParameters(form)
          };

          updateEvaluation(data);
        }
        break;
      default:
        break;
    }
  };

  const steps = [
    {
      title: 'Add Title',
      content: (
        <Step1
          errors={{ title: errors.title }}
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            setForm(newForm);
            if (errors.title) setErrors({ ...errors, title: '' });
          }}
          values={{ title: form.title }}
          onClickContinue={handleClickContinue}
          isEditDialog
        />
      )
    },
    {
      title: 'Set type of content',
      content: (
        <Step2
          errors={{
            followersRange: errors.followersRange,
            platforms: errors.platforms
          }}
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            const newErrors = { ...errors };

            const {
              platforms: {
                instagram: { posts, reels, stories },
                tiktok,
                youtube: { shorts, videos }
              },
              followersRange: { min, max }
            } = newForm;

            if (max !== '' && Number(min) > Number(max))
              newErrors.followersRange =
                'Minimum value cannot be lower than maximum';
            else if (errors.followersRange) newErrors.followersRange = '';
            if (!shorts && !videos && !tiktok && !reels && !posts && !stories)
              newErrors.platforms = 'At least one platform should be selected';
            else if (newErrors.platforms) newErrors.platforms = '';

            setForm(newForm);
            setErrors(newErrors);
          }}
          showSubtitle={false}
          title="Type of content"
          values={{
            choosenCreators: form.choosenCreators,
            dates: form.dates,
            followersRange: form.followersRange,
            platforms: form.platforms,
            platformDisclosure: form.platformDisclosure
          }}
        />
      )
    },
    {
      title: 'Set Keywords',
      content: (
        <Step3
          onChange={(field, value) => {
            const newForm = { ...form, [field]: value };
            setForm(newForm);
          }}
          showSubtitle={false}
          title="Keywords"
          values={{
            mightKeywordsAndKeywordsGroup: form.mightKeywordsAndKeywordsGroup,
            mustKeywordsAndKeywordsGroup: form.mustKeywordsAndKeywordsGroup,
            noneKeywordsAndKeywordsGroup: form.noneKeywordsAndKeywordsGroup,
            someKeywordsAndKeywordsGroup: form.someKeywordsAndKeywordsGroup
          }}
        />
      )
    },
    {
      title: 'Review Evaluation',
      content: (
        <Step4
          isDisabled={true} // VR shouldn't be edited
          results={previewResults}
          requestedServices={requestedServices}
          isLoading={isLoadingPreviewResults}
          error={errorPreview}
        />
      )
    }
  ];

  const isContinueDisabled = useMemo(() => {
    if (activeStep === 0) return !!errors.title;
    if (activeStep === 1) return !!errors.followersRange || !!errors.platforms;
    if (activeStep === 3) return isLoadingPreviewResults;
    return false;
  }, [activeStep, errors, isLoadingPreviewResults]);

  return (
    <>
      <Dialog
        title={'Edit Evaluation'}
        showHeaderDivider
        open={isOpen}
        onClose={() => setExitDialogOpen(true)}
        fullWidth
        maxWidth="lg"
        dialogContent={
          <Stack justifyContent="center">
            <Stepper className={classes.stepper} activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.title}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {steps[activeStep].content}
          </Stack>
        }
        actions={
          <>
            {activeStep > 0 && (
              <Button
                variant="text"
                text="previous"
                onClick={handleClickPrevious}
                disabled={isSubmitting}
              />
            )}
            <Button
              disabled={isContinueDisabled}
              onClick={handleClickContinue}
              text={activeStep === 3 ? 'save changes' : 'continue'}
              variant={activeStep === 3 ? 'contained' : 'text'}
              loading={isSubmitting}
            />
          </>
        }
      />
      <Dialog
        open={isExitDialogOpen}
        title="Leaving this page"
        dialogContent={
          <Typography>
            Are you sure you want to leave this page? Your changes will not be
            saved.
          </Typography>
        }
        actions={
          <>
            <Button
              onClick={() => setExitDialogOpen(false)}
              variant="text"
              text="cancel"
            />
            <Button onClick={() => onClose()} text="leave" />
          </>
        }
      />
    </>
  );
}

EditEvaluation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EditEvaluation;
