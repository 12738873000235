import { useRoutes } from 'react-router';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '@/hooks/redux';
import Layout from '@/layout';
import Login from '@/views/auth/login/Login';
import LoginForm from '@/views/auth/login/LoginForm';
import RecoverForm from '@/views/auth/login/RecoverForm';
import Logout from '@/views/auth/logout/Logout';
import CreatorGroupsDetail from '@/views/creatorGroups/detail';
import CreatorGroupsList from '@/views/creatorGroups/list';
import CreatorItemsList from '@/views/creators/detail';
import CreatorsList from '@/views/creators/list';
import EvaluationDetails from '@/views/evaluations/detail';
import EvaluationsList from '@/views/evaluations/list';
import Keywords from '@/views/keywordGroups/detail';
import KeywordGroups from '@/views/keywordGroups/list';
import NotFound from '@/views/misc/notFound/index';
import ReleaseNotes from '@/views/misc/releaseNotes';
import PostDetails from '@/views/posts/details';
import Sandbox from '@/views/sandbox';

import EvaluationDashboard from './views/evaluations/dashboard/EvaluationDashboard';

const routes = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <Layout /> : <Navigate to="/auth/login" />
  },
  {
    path: '/workspace/:workspaceId',
    element: <Layout />,
    children: [
      { index: true, element: <Navigate to="evaluations" /> },
      {
        path: 'evaluations',
        children: [
          { index: true, element: <EvaluationsList /> },
          {
            path: ':evaluationId',
            children: [
              { index: true, element: <EvaluationDetails /> },
              {
                path: 'post/:postId',
                element: <PostDetails />
              },
              {
                path: 'dashboard',
                element: <EvaluationDashboard />
              }
            ]
          }
        ]
      },
      {
        path: 'creators',
        children: [
          { index: true, element: <CreatorsList /> },
          { path: ':id', element: <CreatorItemsList /> }
        ]
      },
      {
        path: 'creator-groups',
        children: [
          { index: true, element: <CreatorGroupsList /> },
          { path: ':id', element: <CreatorGroupsDetail /> }
        ]
      },
      {
        path: 'keyword-groups',
        children: [
          { index: true, element: <KeywordGroups /> },
          { path: ':id', element: <Keywords /> }
        ]
      },
      {
        path: 'release-notes',
        element: <ReleaseNotes />
      },
      {
        path: 'sandbox',
        element: <Sandbox />
      },
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: '/auth',
    element: <Login />,
    children: [
      { index: true, element: <Navigate to="/auth/login" /> },
      {
        path: 'login',
        element: isLoggedIn ? <Navigate to="/" /> : <LoginForm />
      },
      {
        path: 'recover',
        element: isLoggedIn ? <Navigate to="/" /> : <RecoverForm />
      },
      {
        path: 'logout',
        element: !isLoggedIn ? <Navigate to="/" /> : <Logout />
      }
    ]
  }
];

const Router = () =>
  useRoutes(routes(useAppSelector((state) => !!state.user.user?.id)));

export default Router;
