import {
  RadioGroup as MUIRadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioProps,
  FormControlLabelProps,
  RadioGroupProps,
  Typography
} from '@mui/material';

interface Props {
  label?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  row?: RadioGroupProps['row'];
  onChange?: RadioGroupProps['onChange'];
  options: Array<{
    value: string;
    label: string;
    color?: RadioProps['color'];
    size?: RadioProps['size'];
    disabled?: RadioProps['disabled'];
  }>;
  defaultValue?: RadioGroupProps['defaultValue'];
  value?: RadioGroupProps['value'];
}

const RadioGroup = ({
  label,
  labelPlacement = 'bottom',
  onChange,
  options = [],
  row = true,
  value,
  defaultValue
}: Props) => (
  <FormControl>
    {label && (
      <FormLabel data-testid="radio-group-label">
        <Typography
          color="textPrimary"
          flexWrap={'wrap'}
          variant="body2"
          mb={1}
        >
          {label}
        </Typography>
      </FormLabel>
    )}
    <MUIRadioGroup
      row={row}
      onChange={onChange}
      value={value}
      sx={{ justifyContent: 'space-between' }}
      defaultValue={defaultValue}
    >
      {options.map((radio) => (
        <FormControlLabel
          key={radio.value}
          value={radio.value}
          control={<Radio color={radio.color} size={radio.size || 'small'} />}
          label={radio.label}
          disabled={radio.disabled}
          labelPlacement={labelPlacement}
          sx={{ margin: 0 }}
          data-testid="radio-group-radio"
        />
      ))}
    </MUIRadioGroup>
  </FormControl>
);

export default RadioGroup;
