import { Fragment } from 'react';

import { Box, Chip, Tooltip, Stack, ChipProps } from '@mui/material';

interface props {
  // tooltip: boolean
  data: { tooltip: string; keyword: string }[];
  chipColor: ChipProps['color'];
  cellWidth: number;
}

const GAP_BETWEEN_CHIPS = 4;
const CHIPS_PADDING = 12;
const COLUMN_PADDING = 10;

const getStringsWidth = (string: string, isChip = false) => {
  // Create an off-screen canvas
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context === null || string === '') {
    return 0;
  }

  // Set the font for the context
  context.font = '13px Metropolis';

  // Measure the width of the string
  const { width } = context.measureText(string);

  // Return the width in pixels
  return isChip
    ? Math.round((width + CHIPS_PADDING * 2) * 100) / 100 // include chips padding
    : Math.round(width * 100) / 100; // if its only text do not include chips padding
};

const ChipsColumn = ({ data, chipColor, cellWidth }: props) => {
  if (data.length === 0) return <>—</>;
  const chipsWidth = data.map((item) => getStringsWidth(item.keyword, true));
  const fullCellWidth = cellWidth - COLUMN_PADDING * 2; // removing left and right padding 10 + 10

  let currentIndex = 0;
  let maxVisibleChips = 1;
  let totalWidth = chipsWidth[0];
  let numberToShow = data.length;

  // starting at the first chip, while it fits the cell width we increment to the next chip and validate if it fits again and so on
  while (
    fullCellWidth -
      totalWidth -
      GAP_BETWEEN_CHIPS * (chipsWidth.length - 1) -
      getStringsWidth(numberToShow > 0 ? `+${numberToShow}` : '') >=
      0 &&
    currentIndex < chipsWidth.length
  ) {
    maxVisibleChips = currentIndex + 1;
    if (numberToShow > 0) {
      numberToShow -= 1;
    }
    currentIndex += 1;
    totalWidth += chipsWidth[currentIndex];
  }

  // if only one chip is shown, numbers to show should be always length - 1
  if (maxVisibleChips === 1) {
    numberToShow = data.length - 1;
  }

  const showPlusBox = data.length > 1 && numberToShow !== 0;

  let tooltipTitle: JSX.Element[] = [];
  if (data.length)
    tooltipTitle = data.slice(maxVisibleChips, data.length).map((item) => (
      <p style={{ margin: '2px 0' }} key={`tag__${item.keyword}`}>
        {item.keyword}
      </p>
    ));

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={(e) => e.stopPropagation()}
    >
      <Stack direction="row" alignItems="center" gap="4px">
        {data.slice(0, maxVisibleChips).map((item, i) => (
          <Fragment key={`${item.keyword}-${i}`}>
            <Tooltip title={item.tooltip} arrow>
              <Chip
                color={chipColor}
                key={item.keyword}
                label={item.keyword}
                sx={
                  maxVisibleChips === 1
                    ? {
                        maxWidth:
                          data.length < maxVisibleChips
                            ? fullCellWidth -
                              (COLUMN_PADDING * 2 +
                                getStringsWidth(
                                  `+${data.length - maxVisibleChips}`
                                ))
                            : fullCellWidth - COLUMN_PADDING * 2
                      }
                    : null
                }
              />
            </Tooltip>
          </Fragment>
        ))}
        {showPlusBox && (
          <Tooltip title={tooltipTitle}>
            <Stack direction="row" alignItems="center" gap="4px">
              <Box>+{numberToShow}</Box>
            </Stack>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default ChipsColumn;
