import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getKeywordGroups, getKeywordsAutocomplete } from '@/api/keywords';
import { Keywords } from '@/models/keywords';

export const useFetchKeywordGroups = () =>
  useQuery({
    queryKey: ['keywordGroups'],
    queryFn: async () => {
      const result = await getKeywordGroups();

      return result.data.map((element: any) => ({
        ...element,
        type: 'Keyword group'
      }));
    }
  });

export const useFetchKeywordGroup = (id: number) => {
  const queryClient = useQueryClient();
  useFetchKeywordGroups();
  const keywordGroup = queryClient.getQueryData([
    'keywordGroups'
  ]) as Keywords.Group[];
  return {
    data: !keywordGroup ? [] : keywordGroup.find((item) => item.id === id)
  };
};

export const useFetchKeywordsAutocomplete = (val: string) =>
  useQuery({
    queryKey: ['keywordsAutocomplete', val],
    queryFn: async () => {
      const result = await getKeywordsAutocomplete(val);

      const keywords = result.data.map(
        ({ value }: Keywords.KeywordSuggestion) => value
      );
      const constructedKeywords = keywords.map(
        (keyword: Keywords.KeywordSuggestion) => ({
          title: keyword,
          type: 'Keyword'
        })
      );

      return constructedKeywords;
    }
  });
