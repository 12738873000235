import { useEffect, useState } from 'react';
import MUICarousel from 'react-material-ui-carousel';

import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { IconButton, Stack, SxProps, Typography } from '@mui/material';

type CarouselProps = {
  id: number;
  items: JSX.Element[];
  interval?: number;
  sx?: SxProps;
  duration?: number;
  cycleNavigation?: boolean;
  next?: (now?: number, previous?: number) => void;
  prev?: (now?: number, previous?: number) => void;
  onChange?: (now?: number, previous?: number) => void;
  autoPlay?: boolean;
  activeIndex: number;
  onChangeVisibleItem: (index: number) => void;
  resetMarkers: () => void;
};

const Carousel = ({
  id,
  items,
  interval,
  duration,
  activeIndex,
  onChangeVisibleItem,
  autoPlay,
  next,
  prev,
  onChange,
  cycleNavigation,
  resetMarkers,
  sx
}: CarouselProps) => {
  const [isCarouselMounted, setCarouselMounted] = useState(false);

  // Remount the component to resize the carousel
  useEffect(() => {
    const handleResize = () => {
      setCarouselMounted(false);
      setTimeout(() => {
        setCarouselMounted(true);
      }, 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Remount the component to resize the carousel
  useEffect(() => {
    setCarouselMounted(false);
    setTimeout(() => {
      setCarouselMounted(true);
    }, 50);
  }, [id]);

  const onChangeItem = (direction: 'previous' | 'next') => {
    resetMarkers();
    if (direction === 'previous' && activeIndex > 0)
      onChangeVisibleItem(activeIndex - 1);
    else if (direction === 'next' && activeIndex + 1 < items.length)
      onChangeVisibleItem(activeIndex + 1);
  };
  const label = `${activeIndex + 1} / ${items.length}`;

  return (
    <>
      <MUICarousel
        navButtonsAlwaysVisible
        navButtonsWrapperProps={{
          style: {
            display: 'none'
          }
        }}
        swipe={false}
        cycleNavigation={cycleNavigation}
        index={activeIndex || 0}
        interval={interval}
        duration={duration}
        indicators={false}
        autoPlay={autoPlay}
        sx={sx}
        next={next}
        prev={prev}
        onChange={onChange}
      >
        {isCarouselMounted && items}
      </MUICarousel>
      {items.length > 1 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton onClick={() => onChangeItem('previous')}>
            <NavigateBefore />
          </IconButton>
          <Typography>{label}</Typography>
          <IconButton onClick={() => onChangeItem('next')}>
            <NavigateNext />
          </IconButton>
        </Stack>
      )}
    </>
  );
};

export default Carousel;
