import { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';

import FilterDateRange from '../customFilters/FilterDateRange';

const dateRangeOperator: GridFilterOperator[] = [
  {
    label: 'between',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) =>
        value !== null &&
        filterItem.value[0] <= value &&
        value <= filterItem.value[1];
    },
    InputComponent: FilterDateRange
  }
];

export default dateRangeOperator;
